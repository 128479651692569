import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AxiosConfig from '../../../Services/AxiosConfig'
import * as Yup from 'yup'
import {
  getUserProfile,
  updateUserProfile
} from 'src/Services/PersonalProfile/getProfileData'
import BioFormUI from './BioFormUI'
import { Formik } from 'formik'

function Bio ({ navigateTo }) {
  const [ProfileData, setProfileData] = useState({})
  const [uploadImage, setuploadImage] = useState(null)
  const [apiData, setApiData] = useState()
  const [BioAlreadyExists, setBioExists] = useState(false)
  const userId = useSelector((state) => state.auth.userId)
  const navigate = useNavigate()
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSucces] = useState(false)

  useEffect(() => {
    (async function () {
      try {
        const profileData = await getUserProfile(userId)
        setProfileData(profileData.data)
        // console.log(profileData.data);
      } catch (error) {
        console.log(error)
      }
    })()
  }, [userId])
  const onSubmit = async (values, submitImage, setSubmitting) => {
    try {
      setSubmitting(true)
      setIsError(false)
      setIsSucces(false)
      // console.log(values.about);
      let imageLink
      if (!submitImage) {
        imageLink = values.profile_photo
      } else {
        const { data } = await AxiosConfig.post(
          process.env.REACT_APP_FILE_UPLOAD,
          submitImage
        )
        imageLink = data.data.media_file
      }
      const res = await updateUserProfile({
        ...ProfileData,
        user_number: values.phone,
        physical_address: values.address,
        gender: 'male',
        cover_photo: '',
        profile_photo: imageLink
      })
      if (res instanceof Error) throw new Error(res.message)
      if (BioAlreadyExists) {
        const response = await AxiosConfig.put('user/bio/create/', {
          about: values.about,
          phone: values.phone,
          address: values.address,
          profile_photo: imageLink,
          id: userId,
          type: 'personal',
          userbio_id: apiData.data.userbio_id
        })
      } else {
        const response = await AxiosConfig.post('user/bio/create/', {
          about: values.about,
          phone: values.phone,
          address: values.address,
          profile_photo: imageLink,
          id: userId,
          type: 'personal'
        })
        // console.log(response);
      }

      setSubmitting(false)
      setIsSucces(true)
      setIsError(false)
      // resetForm();
      if (navigateTo) {
        navigate(navigateTo)
      }
    } catch (error) {
      // console.log(error);
      setIsError(true)
      setIsSucces(false)
      setSubmitting(false)
    }
  }
  const bioSchema = Yup.object().shape({
    address: Yup.object().required('This Field is Required'),
    phone: Yup.string().required('This Field is Required')
  })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        about: '',
        phone: '',
        address: '',
        profile_photo: ProfileData?.profile_photo
          ? ProfileData.profile_photo
          : ''
      }}
      validationSchema={bioSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (!uploadImage) {
          onSubmit(values, uploadImage, setSubmitting)
        } else {
          const submitImage = new FormData()
          submitImage.append('media_file', uploadImage)
          onSubmit(values, submitImage, setSubmitting)
        }
      }}
    >
      {({
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        setTouched,
        setFieldValue
      }) => (
        <BioFormUI
          status={'onboarding'}
          ProfileData={ProfileData}
          onSubmit={onSubmit}
          isSuccess={isSuccess}
          isError={isError}
          navigateTo={navigateTo}
          values={values}
          isSubmitting={isSubmitting}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setTouched={setTouched}
          setFieldValue={setFieldValue}
          setuploadImage={setuploadImage}
        />
      )}
    </Formik>
  )
}

export default Bio
