import React, { useState } from "react";
import "../../global/AnswerForm/AnswerForm.css";
import Pagination from "react-bootstrap/Pagination";
import {
  faClock,
  faMagnifyingGlass,
  faReply,
  faBookmark,
  faLocationDot,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import profile from "src/Assets/images/profileimg.png";
import data from "./data.json";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  faCircleUser,
  faCalendarDays,
  faHeart,
  faMessage,
  faClockFour,
} from "@fortawesome/free-regular-svg-icons";
import TextEditor from "../TextEditor/TextEditor";

function PreviousAnswers() {
  const [show, setShow] = useState([false]);
  const [value, setValue] = useState("");
  return (
    <>
      {data.map((item, index) => (
        <div className="mt-3">
          <div className="body" style={{ paddingTop: "20px" }}>
            <div className="reply-answers">
              <div className="img q-img">
                <img src={profile} height={60} />
              </div>
            </div>

            <div className="pre-ans-title" style={{ color: "#3F464E" }}>
              <h6 className="ans-title-resp">{item.title}</h6>

              <div className="sub-title" style={{ color: "#3F464E" }}>
                <p className="ans-p-resp">Expert Technician</p>
              </div>
            </div>
          </div>
          <div className="answer-detail">
            <p className="p-ans">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              ullamcorper, enim a suscipit maximus, lacus nisi dignissim diam,
              sed aliquam tortor sapien ut elit. Duis tempus mauris risus, a
              fringilla est lobortis luctus. Curabitur nec efficitur arcu.
            </p>
          </div>
          <div className="job-detail detail-icons">
            <div className="location" style={{ marginRight: "8px" }}>
              <span>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  style={{ color: "#37d1aa", marginRight: "5px" }}
                />{" "}
                13-01-2022
              </span>
              <span>{item.user}</span>
            </div>
            <div className="job-type" style={{ marginRight: "8px" }}>
              <span>
                <FontAwesomeIcon
                  icon={faClockFour}
                  style={{ color: "#37d1aa", marginRight: "5px" }}
                />{" "}
                09:47 AM
              </span>
              <span>{item.calender}</span>
            </div>

            <div className="job-type" style={{ marginRight: "8px" }}>
              <span>
                <FontAwesomeIcon
                  icon={faReply}
                  style={{ color: "#37d1aa", marginRight: "5px" }}
                />{" "}
                <a onClick={() => setShow([...show, true])}>Reply</a>
              </span>
            </div>
          </div>
          <div>
            <p className="fw-bold mt-3">Replying to {item.title}</p>
          </div>
          <div>
            <ReactQuill
              style={{
                marginBottom: "40px",
                height: "150px",
              }}
              theme="snow"
              value={value}
              onChange={setValue}
            />
          </div>
        </div>
      ))}
    </>
  );
}

export default PreviousAnswers;
