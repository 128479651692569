import SavedJobsCard from "../../Components/global/SavedJobsCard/SavedJobsCard";
import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../Components/global/SavedJobsCard/SavedJobsCard.css";
import JobsSmallSideCard from "src/Components/global/JobsSmallSideCard/JobsSmallSideCard";
import axios from "../../Services/AxiosConfig";
import Pagination from "../../Components/global/Pagination/Pagination";
import { toast } from "react-toastify";
import Loader from "src/Components/UI/Loader/Loader";

const SavedJobs = ({ dataLimit = 10 }) => {
  const [dataAPI, setData] = useState([]);
  const [totalData, setTotalData] = useState();
  const pages = Math.ceil(totalData / dataLimit);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);

  let endValue;
  if (totalData <= dataLimit) {
    endValue = totalData;
  } else {
    endValue = dataLimit - 1;
  }
  const [endIndex, setEndIndex] = useState(endValue);

  const remainingIndex = totalData % dataLimit;
  const savedJobsAPI = useCallback(async () => {
    try {
      const { data } = await axios.get(
        "job/save/?index=" + startIndex + "&offset=" + dataLimit
      );
      // console.log("save job api", data);
      await setData(data.data);
      await setTotalData(data.total_job_data);
    } catch (error) {
      // console.log(error);
    }
  }, [startIndex, dataLimit]);
  useEffect(() => {
    let check = true;
    if (check) {
      (async () => await savedJobsAPI())();
      check = false;
    }
  }, [startIndex, savedJobsAPI]);

  const saveAPI = async (JobId) => {
    try {
      const payload = {
        post_id: JobId,
      };
      const { data } = await axios.post("job/save/", payload);
      // console.log("save job api", data);
      await savedJobsAPI();
      showToast(data.success, "save", data.message);
    } catch (error) {
      // console.log(error);
      showToast(false, "save", "Network Error");
    }
  };

  const showToast = (success, type, message) => {
    toast.dismiss();

    if (success && type === "save") {
      toast.success(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (!success && type === "save") {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  async function goToNextPage() {
    // console.log("currentPage number", currentPage);

    if (currentPage + 1 > pages) {
    } else if (currentPage + 1 === pages) {
      setStartIndex(totalData - remainingIndex);
      setEndIndex(totalData - 1);
    } else if (startIndex <= totalData) {
      setEndIndex(totalData);
    } else {
      setCurrentPage((page) => page + 1);

      setStartIndex(startIndex + dataLimit);
      setEndIndex(endIndex + dataLimit);
    }
  }

  async function goToPreviousPage() {
    // console.log("current page on previous", currentPage);
    if (currentPage === 0) {
    } else if (currentPage + 1 === pages) {
      setCurrentPage((page) => page - 1);
      setStartIndex(startIndex - dataLimit);
      setEndIndex(endIndex - remainingIndex);
    }
    // else if(endIndex == totalData){
    //   setEndIndex(totalData);
    // }
    else if (startIndex !== 0) {
      setCurrentPage((page) => page - 1);

      setStartIndex(startIndex - dataLimit);
      setEndIndex(endIndex - dataLimit);
    }
  }

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={3} md={3}>
            <JobsSmallSideCard />
          </Col>
          <Col lg={9} md={9}>
            {/* <div className="Filter">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className="label">
                    <span>Filter by:</span>
                  </div>
                  <div className="fields">
                    <Row>
                      <Col lg={3} md={6} className="mb-md-3">
                        <Form.Control type="text" placeholder="Location" />
                      </Col>
                      <Col lg={3} md={6}>
                        <Select
                          styles={customStyles}
                          components={{ IndicatorSeparator: () => null }}
                          options={jobType}
                          placeholder="Job Type"
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <Select
                          styles={customStyles}
                          components={{ IndicatorSeparator: () => null }}
                          options={experience}
                          placeholder="Experience"
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <Select
                          styles={customStyles}
                          components={{ IndicatorSeparator: () => null }}
                          options={datePosted}
                          placeholder="Dated Posted"
                        />
                      </Col>
                    </Row>
                  </div>
                </Form.Group>
              </Form>
            </div> */}
            <div className="JobListHeader">
              <span>My Saved Jobs</span>
            </div>
            <div className="mapping-area">
              {dataAPI ? (
                dataAPI.map((item, index) => (
                  <SavedJobsCard
                    item={item}
                    savedJobsAPI={savedJobsAPI}
                    dataAPI={dataAPI}
                    totalData={totalData}
                    dataLimit={dataLimit}
                    remainingIndex={remainingIndex}
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    saveAPI={saveAPI}
                  />
                ))
              ) : (
                <Loader />
              )}
            </div>
            <div style={{ paddingTop: "10px" }}>
              <Pagination
                goToNextPage={goToNextPage}
                goToPreviousPage={goToPreviousPage}
                totalData={totalData}
                startIndex={startIndex}
                endIndex={endIndex}
                getAPIData={savedJobsAPI}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SavedJobs;
