import React, { useState } from "react";
import "./BannerSection.css";
import { Col, Container, Row, Button, ListGroup } from "react-bootstrap";
import Typewriter from "typewriter-effect";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircle } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import data from "./data.json";
import VerticalTextScroll from "./VerticalTextScroll";

const BannerSection = () => {
  const navigate = useNavigate();
  const [words, setWords] = useState([
    "Buy Products",
    "Sell Products",
    "Find Vendor",
    "Find Jobs",
    "Build Community",
  ]);

  return (
    <>
      <section className="BannerSection" id="BannerSection">
        <div className="staticDiv1"></div>
        <Container>
          <Row className="align-items-center">
            <Col md={7}>
              <div className="typewritrerDiv">
                <h1
                  style={{ fontWeight: "500" }}
                  className={" fs-100 recoleta-font text-white "}
                >
                  We provide
                  <div className="d-flex align-items-end">
                    <Typewriter
                      options={{
                        strings: ["You a Platform", "World"],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                    <div className="blinkingDiv"></div>
                  </div>
                </h1>
              </div>
            </Col>
            <Col md={5}>
              <VerticalTextScroll data={data.slides} />
              {/* <div className="scrollerBody">
                <div className="scroller fs-59">
                  <span>
                    {words.map((item) => (
                      <>
                        {item}
                        <br />
                      </>
                    ))}
                  </span>
                </div>
              </div> */}
              {/* <div className="list-outer">
                <ListGroup>
                  <ListGroup.Item
                    className={
                      "bg-transparent border-0 text-white text-center fs-59 recoleta-font"
                    }
                  >
                    Buy Products
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={
                      "bg-transparent border-0 text-white text-center fs-59 recoleta-font"
                    }
                  >
                    Sell Products
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={
                      "bg-transparent border-0 text-white text-center fs-59 recoleta-font"
                    }
                  >
                    Find Vendor
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={
                      "bg-transparent border-0 text-white text-center fs-59 recoleta-font"
                    }
                  >
                    Find Jobs
                  </ListGroup.Item>
                  <ListGroup.Item
                    className={
                      "bg-transparent border-0 text-white text-center fs-59 recoleta-font"
                    }
                  >
                    Build Community
                  </ListGroup.Item>
                </ListGroup>
              </div> */}
            </Col>
          </Row>
        </Container>
        <div className="h-100 d-flex flex-column-reverse pb-5 mb-5">
          <div className="staticDiv2"></div>
        </div>
      </section>

      {/* <section className="services-banner" id="service_banner_id">
        <Container>
          <Row className="bannerRow align-items-center justify-content-around">
            <div className="w-75">
              <Col md={12}>
                <div>
                  <h1 className={"bannerFont fs-90 recoleta-font text-white"}>
                    We Provide You A Platform
                  </h1>
                </div>
              </Col>
              <Formik
                initialValues={{
                  searchKeyword: "",
                  searchLocation: "",
                }}
                onSubmit={async (values) => {
                  try {
                    // console.log(values);

                    setTimeout(() => {
                      navigate(
                        `/search-services-list/search=${values.searchKeyword}`
                      );
                    }, 500);
                  } catch (error) {
                    console.log("ERROR: ", error.message);
                  } finally {
                  }
                }}
              >
                {({ handleChange, handleBlur, values, setFieldValue }) => (
                  <Col md={12}>
                    <div className="search-bar">
                      <Form className="d-flex justify-content-between">
                        <div className="flex-grow-1">
                          <input
                            size="lg"
                            type="text"
                            className="fs-16 form-control"
                            style={{ border: "none" }}
                            placeholder="Search service,keyword,vendor"
                            name="searchKeyword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.searchKeyword}
                          />
                        </div>
                        <div>
                          <Button className="gen-btn btn-lg" type="submit">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                )}
              </Formik>

              <Col md={10} className="mt-4">
                <p className="text-white">Popular Searches</p>
                <div className="buttons-group d-flex flex-wrap gap-2">
                  <Button
                    className="gen-btn btn-lg fs-14"
                    type="submit"
                    onClick={() =>
                      navigate(`/search-services-list/search=roofing`)
                    }
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Roofing
                  </Button>
                  <Button
                    className="gen-btn btn-lg fs-14"
                    type="submit"
                    onClick={() =>
                      navigate(`/search-services-list/search=painting`)
                    }
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Painting
                  </Button>
                  <Button
                    className="gen-btn btn-lg fs-14"
                    type="submit"
                    onClick={() =>
                      navigate(`/search-services-list/search=carpentry`)
                    }
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Carpentry
                  </Button>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default BannerSection;
