import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { signup } from "../../Redux/AuthSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FormGroup,
  InputGroup,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Recaptcha from "react-recaptcha";

const SignupForm = ({ Email, Name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const auth = useSelector((state) => state.auth);
  const [human, setHuman] = useState(false);

  const splitName = (name = "") => {
    const [firstName, ...lastName] = name.split(" ").filter(Boolean);
    return {
      firstName,
      lastName: lastName.join(" "),
    };
  };
  const firstName = splitName(Name).firstName;
  const lastName = splitName(Name).lastName;

  useEffect(() => {
    toast.dismiss();
    if (auth.isSignUpProcessing) {
      toast.loading("Please wait...");
    }
    if (auth.isSignUpError) {
      toast.error(auth.signUpErrorMessage);
    }
    if (auth.signupSuccess) {
      toast({
        render: "Success",
        type: "success",
      });
      navigate("/on-boarding/bio");
    }
  }, [auth, navigate]);

  const handleSignUpSubmit = (values) => {
    try {
      dispatch(signup(values));
    } catch (error) {
      console.log(error);
    }
  };

  let recaptchaInstance;

  useEffect(() => {
    recaptchaInstance.reset();
  }, [recaptchaInstance]);

  const onloadCallback = () => {
    // console.log("captcha is loading...");
  };

  const verifyCallback = (Response) => {
    if (Response) {
      setHuman(true);
      // console.log("its human");
    }
  };

  // Validation
  const SignupSchema = Yup.object().shape({
    accountType: Yup.string().required("This Field is Required"),
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("This Field is Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("This Field is Required"),
    email: Yup.string()
      .email()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("This Field is Required"),
    // physicalAddress: Yup.object().required("Required"),
    // gender: Yup.string().required('Required'),
    agree: Yup.boolean().oneOf([true], "Required"),
    password: Yup.string()
      .required("This Field is Required")
      .min(6, "Password is too short - should be 6 chars minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/,
        "Password must contains at least one Special character, one Uppercase letter, one Lowercase letter and one Number."
      ),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  return (
    <>
      {/* <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Formik
        initialValues={{
          accountType: "personal",
          firstName: firstName || "",
          lastName: lastName || "",
          email: Email || "",
          password: "",
          confirmPassword: "",
          agree: false,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (human) {
            handleSignUpSubmit(values, setSubmitting);
          } else {
            toast.error("Please verify that you are a human");
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup className="flex-fill ">
                  <label htmlFor="firstName">First Name</label>
                  <InputGroup className="inputField4">
                    <input
                      id="fistName"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      className="inputTransparent flex p-2"
                    />
                  </InputGroup>
                  <p className="text-danger fs-12 mb-0">
                    <ErrorMessage name="firstName" />
                  </p>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="flex-fill">
                  <label htmlFor="lastName">Last Name</label>
                  <InputGroup className="inputField4">
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      className="inputTransparent flex p-2"
                    />
                  </InputGroup>
                  <p className="text-danger fs-12">
                    <ErrorMessage name="lastName" />
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <div></div>
            <div>
              <FormGroup>
                <label htmlFor="email">Email Address</label>
                <InputGroup className="inputField4">
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className="inputTransparent flex p-2"
                  />
                </InputGroup>
                <p className="text-danger fs-12">
                  <ErrorMessage name="email" />
                </p>
              </FormGroup>
            </div>
            <Row>
              <Col>
                <FormGroup className="flex-fill">
                  <label htmlFor="password">Password</label>
                  <InputGroup className="inputField4 align-items-center">
                    <input
                      id="password"
                      type={passwordType}
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className="inputTransparent flex p-2"
                    />
                    {passwordType === "password" ? (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        className="eyeIcon"
                        onClick={() => setPasswordType("text")}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEye}
                        className="eyeIcon"
                        onClick={() => setPasswordType("password")}
                      />
                    )}
                  </InputGroup>
                  <p className="text-danger fs-12 mb-0">
                    <ErrorMessage name="password" />
                  </p>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="flex-fill">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <InputGroup className="inputField4 align-items-center">
                    <input
                      id="confirmPassword"
                      type={confirmPasswordType}
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      className="inputTransparent flex p-2"
                    />
                    {confirmPasswordType === "password" ? (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        className="eyeIcon"
                        onClick={() => setConfirmPasswordType("text")}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faEye}
                        className="eyeIcon"
                        onClick={() => setConfirmPasswordType("password")}
                      />
                    )}
                  </InputGroup>
                  <p className="text-danger fs-12">
                    <ErrorMessage name="confirmPassword" />
                  </p>
                </FormGroup>
              </Col>
            </Row>
            <div className="agreeStatementDiv">
              <label className="checkmark-container my-2 d-inline-flex align-items-center">
                <span className="secondary-font">
                  I agree to all
                  <span className="primary-color">
                    {" Terms & Conditions "}
                  </span>
                  and
                  <span className="primary-color">{" Privacy Policy. "}</span>
                </span>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    values.agree ? (
                      <></>
                    ) : (
                      <Tooltip>
                        Please check this box if you want to proceed.
                      </Tooltip>
                    )
                  }
                >
                  <input
                    name="agree"
                    type="checkbox"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.agree}
                    checked={values.agree}
                  />
                </OverlayTrigger>
                <span className="checkmark"></span>
              </label>
              <p className="text-danger fs-12">
                <ErrorMessage name="agree" />
              </p>
            </div>
            <div className="mb-2">
              <Recaptcha
                sitekey="6Ld1ABAhAAAAAASuoyYMyjK-gdTXh7ZX_-SQf25t"
                render="explicit"
                ref={(e) => (recaptchaInstance = e)}
                onloadCallback={onloadCallback}
                verifyCallback={verifyCallback}
              />
            </div>
            <div>
              <button
                style={{ width: "208px" }}
                className="btn-green2 text-white  mb-3 mt-1 secondary-font "
                type="submit"
                // disabled={isSubmitting}
              >
                Create Your Account
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SignupForm;
