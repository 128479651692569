import React from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import './Register.css'
import { Link, useNavigate } from 'react-router-dom'

import CarouselComponent from './Components/CarouselComponent'
import Logo from 'src/Assets/images/register/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

import { Formik, Form, ErrorMessage } from 'formik'
import TextField from './Components/TextField'

import AxiosConfig from '../../Services/AxiosConfig'
import * as Yup from 'yup'
import FileUpload from 'src/Components/global/Dropzone/Dropzone'

const RegisterEducation = () => {
  const navigate = useNavigate()

  const educationSchema = Yup.object().shape({
    enrolled_in: Yup.string().required('This Field is Required'),
    title: Yup.string().required('This Field is Required'),
    institute: Yup.string().required('This Field is Required'),
    status: Yup.string()
  })

  const onSubmit = async (values, setSubmitting) => {
    try {
      setSubmitting(true)
      const res = await AxiosConfig.post('user/educational/background/view/', [
        { ...values, is_education: true, is_certificate: false }
      ])
      // console.log(res);
      setSubmitting(false)
      if (res.data.success === true) {
        navigate('/register/portfolio')
      }
    } catch (error) {
      console.log(error)
      setSubmitting(false)
    }
  }

  return (
    <>
      <section className="register" id="register_id">
        <Container fluid>
          <Row>
            <div className="grid--col-02 px-0">
              <div className="align-items-center p-4 d-lg-block d-none">
                <div>
                  <img src={Logo} alt="logo" className="img-fluid logo-width" />
                </div>
                <div>
                  <CarouselComponent />
                </div>
              </div>

              <div className="bg-form-style ">
                <div className="d-flex min-vh-100 flex-column align-items-center justify-content-center">
                  <div className="w-75 mb-3">
                    <h2 className="text-start primary-font primary-color fs-59 fw-bold">
                      Education
                    </h2>
                  </div>
                  <Formik
                    initialValues={{
                      graduated_in: '',
                      enrolled_in: '',
                      title: '',
                      institute: '',
                      status: 'completed',
                      file_urls: []
                    }}
                    validationSchema={educationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      onSubmit(values, setSubmitting)
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      setFieldValue,
                      isSubmitting
                    }) => (
                      <Form className="w-75">
                        <Row>
                          <Col md={12}>
                            <TextField
                              label="Degree"
                              name="title"
                              placeholder="Degree"
                              type="text"
                            />
                          </Col>
                          <Col md={12}>
                            <TextField
                              label="Institute"
                              name="institute"
                              placeholder="Institute"
                              type="text"
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              type="date"
                              label="Start Date"
                              id={'enrolled_in'}
                              name={'enrolled_in'}
                            />
                          </Col>

                          {values.status === 'in_progress' ? (
                            <></>
                          ) : (
                            <Col md={6}>
                              <TextField
                                type="date"
                                label="End Date"
                                id={'graduated_in'}
                                name={'graduated_in'}
                                disabled={values.is_expired}
                              />
                              {({ field, form: { touched, errors } }) => (
                                <input
                                  className="inputTransparent flex p-2"
                                  {...field}
                                  type="date"
                                  disabled={values.status === 'in_progress'}
                                />
                              )}
                              {/* </TextField> */}
                            </Col>
                          )}
                          <div>
                            <label className="checkmark-container me-2 d-inline-flex align-items-center">
                              <span className="secondary-font-medium fs-16">
                                Currently Enrolled Here?
                              </span>
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFieldValue('status', 'in_progress')
                                  } else {
                                    setFieldValue('status', 'completed')
                                    setFieldValue('graduated_in', '')
                                  }
                                }}
                                // checked={values.status === "in_progress"}
                                name="status"
                                value="in_progress"
                              />
                              <span className="checkmark"></span>
                            </label>
                            {/* </div> */}
                            <p className="text-danger fs-12">
                              <ErrorMessage name="status" />
                            </p>
                          </div>

                          <label className="mb-1">Upload Media</label>
                          <div>
                            <FileUpload
                              value={values}
                              setvalue={setFieldValue}
                              name="file_urls"
                              onUpload={(data) => {
                                setFieldValue('file_urls', [
                                  ...values.file_urls,
                                  data
                                ])
                              }}
                              onRemove={(file) => {
                                setFieldValue(
                                  'file_urls',
                                  values.file_urls.filter(
                                    (item) => item.name !== file.name
                                  )
                                )
                              }}
                            />
                          </div>

                          <Col md={12} className="text-center mt-2 mb-4">
                            <Button
                              className="accent-btn btn-green2 border-0 py-2 px-4"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <span className="me-2">
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                </span>
                              )}
                              Save & Continue
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <Link
                    to={'/register/portfolio'}
                    style={{
                      float: 'right',
                      padding: '10px',
                      cursor: 'pointer'
                    }}
                  >
                    <p className="primary-color ">
                      <span className="me-2">Skip For Now</span>
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default RegisterEducation
