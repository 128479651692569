import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, createContext, useEffect, useState } from "react";
import "./App.css";
import Signup from "./Pages/Signup/Signup";
import Signin from "./Pages/Signin/Signin";
import { useSelector, useDispatch } from "react-redux";
import Home from "./Pages/Home/Home";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route, Navigate } from "react-router-dom";
import OnBoarding from "./Components/PersonalOnboarding/OnBoarding";
import PersonalProfile from "./Pages/PersonalProfile/PersonalProfileRouter";
import { authorize } from "./Redux/AuthSlice";
import Feed from "./Pages/Feed/Feed";
import Page404 from "./Pages/Page404/Page404";
import BusinessProfile from "./Pages/BusinessProfile/BusinessProfileRouter";
import Loader from "./Components/UI/Loader/Loader";
import { ToastContainer } from "react-toastify";
import AppliedJobsEdit from "./Pages/AppliedJobsEdit/AppliedJobsEdit";
import JobPossitionPage from "./Pages/JobPossitionPage/JobPossitionPage";
import JobArchitecturePage from "./Pages/JobArchitecturePage/JobArchitecturePage";
import JobArchitectureDetailPage from "./Pages/JobArchitectureDetailPage/JobArchitectureDetailPage";
import Jobs from "./Pages/Jobs/JobsRouter";
import TrendingBusinessesPage from "./Pages/TrendingBusinessesPage/TrendingBusinessesPage";
import FindFriendsPage from "./Pages/FindFriendsPage/FindFriendsPage";
import AddMembersPage from "./Pages/AddMembersPage/AddMembersPage";
import ContactFormModel from "./Pages/ContactFormModel/ContactFormModel";
import CategoriesPage from "./Pages/CategoriesPage/CategoriesPage";
import ChatPage from "./Pages/ChatPage/ChatPage";
import CreateJobPage from "./Pages/CreateJobPage/CreateJobPage";
import EditJobPage from "./Pages/EditJobPage/EditJobPage";
import RatingPage from "./Pages/RatingPage/RatingPage";
import SearchServices from "./Pages/SearchServices/SearchServices";
import PopularJobs from "./Pages/PopularJobs/PopularJobs";
import SearchServicesList from "./Pages/SearchServicesList/SearchServicesList";
import RegisterBio from "./Pages/Register/Register_Bio";
import RegisterWork from "./Pages/Register/Register_Work";
import RegisterEducation from "./Pages/Register/Register_Education";
import RegisterPortfolio from "./Pages/Register/Register_Portfolio";
import RegisterSkills from "./Pages/Register/Register_Skills";
import Register from "./Pages/Register/Register";
import ForgetPassword from "./Pages/Register/ForgetPassword";
import Chat from "./Pages/Chat/Chat";
import Login from "./Pages/Register/Login";
import GetNotificationList from "./Components/Notifications/PusherNotifications";

import {
  fetchAllNotifications,
  setNotification,
} from "./Redux/NotificationSlice";

import { subscribeToPush, listenToPush } from "./Services/push-notifications";
import SinglePost from "./Pages/Feed/SinglePost";
import ContactForm from "./Components/global/ContactForm/ContactForm";
import AboutUs from "./Pages/About Us/AboutUs";
import CommunityRouter from "./Pages/Community/CommunityRouter";
import EventsRouter from "./Pages/Events/EventsRouter";
const CreateBusiness = React.lazy(() =>
  import("./Pages/CreateBusiness/CreateBusiness")
);

const Services = React.lazy(() => import("./Pages/Services/ServicesRouter"));

export const BuildContext = createContext();
function App() {
  const { isAuthorized, userDetail } = useSelector((state) => state.auth);
  const [build] = React.useState(process.env.NODE_ENV);
  const dispatch = useDispatch();
  // const [notification, setNotification] = useState([]);
  // const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    if (isAuthorized === true) {
      GetNotificationList(userDetail.core_id, setNotification, dispatch);
      (async function () {
        const response = await dispatch(fetchAllNotifications()).unwrap();
        // console.log("response", response);
      })();
    }
  }, [isAuthorized]);

  useEffect(() => {
    (async function () {
      await dispatch(authorize());
    })();
  }, []);
  useEffect(() => {
    if (isAuthorized) {
      (async function () {
        await subscribeToPush();
      })();
      listenToPush();
    }
  }, [isAuthorized]);

  return (
    <BuildContext.Provider value={build}>
      <div className="App">
        {/* {isAuthorized && (
          <PusherNotifications
            setNotification={setNotification}
            userId={userDetail.core_id}
          />
        )} */}

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          limit={1}
        />
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/register/bio" element={<RegisterBio />} />
          {isAuthorized === true && userDetail ? (
            <Route
              exact
              path="/chat"
              element={<Chat userDetail={userDetail} />}
            />
          ) : (
            <Route exact path="/chat" element={<Loader />} />
          )}

          <Route exact path="/register/work" element={<RegisterWork />} />
          <Route
            exact
            path="/register/education"
            element={<RegisterEducation />}
          />
          <Route
            exact
            path="/register/portfolio"
            element={<RegisterPortfolio />}
          />
          <Route exact path="/post/:postId" element={<SinglePost />} />
          <Route exact path="/register/skills" element={<RegisterSkills />} />
          <Route exact path="/forgetpassword" element={<ForgetPassword />} />

          <Route exact path="/login-old" element={<Signin />} />
          <Route exact path="/signup-old" element={<Signup />} />

          <Route exact path="/search-services" element={<SearchServices />} />
          <Route
            exact
            path={`/search-services-list/search=:searchKeyword`}
            element={<SearchServicesList />}
          />
          <Route exact path="/popular-jobs" element={<PopularJobs />} />

          <Route path="/on-boarding/*" element={<OnBoarding />} />
          <Route path="/feed" element={<Feed />} />
          <Route path="/business/*" element={<BusinessProfile />} />
          <Route path="/profile/*" element={<PersonalProfileRouter />} />
          <Route path="/jobs/*" element={<Jobs />} />
          <Route path="/applied-jobs-edit" element={<AppliedJobsEdit />} />
          <Route path="/jobs-position" element={<JobPossitionPage />} />
          <Route path="/jobs-applicants" element={<JobArchitecturePage />} />
          <Route path="/trending" element={<TrendingBusinessesPage />} />
          <Route path="/find_friends" element={<FindFriendsPage />} />
          <Route path="/add_members" element={<AddMembersPage />} />
          <Route path="/contact" element={<ContactForm />} />
          {/* <Route path="/contact" element={<ContactFormModel />} /> */}
          <Route path="/categories" element={<CategoriesPage />} />

          <Route path="/create-job" element={<CreateJobPage />} />
          <Route path="/edit-job" element={<EditJobPage />} />
          <Route path="/rating" element={<RatingPage />} />
          <Route path="/community/*" element={<CommunityRouter />} />
          <Route path="/event/*" element={<EventsRouter />} />
          <Route
            path="/job-applicants-detail"
            element={<JobArchitectureDetailPage />}
          />
          <Route
            path="/create-business/*"
            element={
              <Suspense fallback={<Loader />}>
                <CreateBusiness />
              </Suspense>
            }
          />
          <Route
            path="/services/*"
            element={
              <Suspense fallback={<Loader />}>
                <Services />
              </Suspense>
            }
          />
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </div>
    </BuildContext.Provider>
  );
}
const PersonalProfileRouter = () => {
  const { authorizing, userId } = useSelector((state) => state.auth);
  if (authorizing) {
    return <Loader />;
  }
  return (
    <Routes>
      <Route
        path=""
        element={<Navigate to={userId ? `/profile/${userId}` : "/login"} />}
      />
      <Route path=":id/*" element={<PersonalProfile />} />
    </Routes>
  );
};
export default App;
