import React, { useState, useEffect } from "react";
import "./CreatePost.scss";
import { Button, Modal, Placeholder } from "react-bootstrap";
import PostUserImg from "src/Assets/images/postUser.png";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import { add, edit } from "../../../../Services/AxiosConfig";
const CreatePost = ({ show, close, reloadPosting, userDetail, postDetail }) => {
  const userDeatil = {
    name: userDetail?.first_name
      ? `${userDetail?.first_name} ${userDetail?.last_name}`
      : "Your Name",
    img: PostUserImg,
    job: userDetail?.work_history[0]
      ? `${userDetail?.work_history[0]?.company_name} | ${userDetail?.work_history[0]?.title}`
      : " Robel LLC | Painting Service Provider",
  };
  const [uploadImage, setuploadImage] = useState(null);
  const [uploadImageUrl, setuploadImageUrl] = useState(
    postDetail?.post_media ? postDetail?.post_media : null
  );

  const CreatePostHandler = async (values, setSubmitting) => {
    const submitData = new FormData();
    submitData.append("media_file", uploadImage);
    const apiRsp = await add(process.env.REACT_APP_FILE_UPLOAD, submitData)
      .then((rsp) => {
        const media_link = rsp.data.data.media_file;
        const formikData = {
          ...values,
          post_media: media_link,
          post_tags: "6e94ecb0-7cc8-4575-8f83-0d6e7912fa88",
        };
        const createPostApi = add("user/add/post/", formikData)
          .then((rsp) => {
            setuploadImageUrl(null);
            reloadPosting();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EditPostHandler = async (values, setSubmitting) => {
    if (postDetail.post_media === uploadImageUrl) {
      const formikData = {
        ...values,
        post_media: uploadImageUrl,
        post_tags: "6e94ecb0-7cc8-4575-8f83-0d6e7912fa88",
        post_id: postDetail.post_id,
      };
      const createPostApi = edit("user/add/post/", formikData)
        .then((rsp) => {
          setuploadImageUrl(null);
          reloadPosting();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const submitData = new FormData();
      submitData.append("media_file", uploadImage);
      const apiRsp = await add(process.env.REACT_APP_FILE_UPLOAD, submitData)
        .then((rsp) => {
          const media_link = rsp.data.data.media_file;
          const formikData = {
            ...values,
            post_media: media_link,
            post_tags: "6e94ecb0-7cc8-4575-8f83-0d6e7912fa88",
            post_id: postDetail.post_id,
          };
          const createPostApi = edit("user/add/post/", formikData)
            .then((rsp) => {
              setuploadImageUrl(null);
              reloadPosting();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  }

  return (
    <>
      {/* {console.log(uploadImage)} */}
      <section>
        <Modal
          show={show}
          onHide={close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Formik
            initialValues={{
              post_description: postDetail?.post_description
                ? postDetail?.post_description
                : "",
              post_media: postDetail?.post_media
                ? postDetail?.post_media
                : null,
              shared_post_id: null,
              post_tags: "coupons",
              is_event: false,
            }}
            validationSchema={""}
            onSubmit={(values, { setSubmitting }) => {
              if (postDetail?.post_id) {
                EditPostHandler(values, setSubmitting);
              } else {
                CreatePostHandler(values, setSubmitting);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Create Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex align-items-center m-2">
                    <div className="postImageDiv">
                      <img
                        className="rounded-circle CreatePostImage"
                        src={
                          validateUrl(userDetail?.profile_photo)
                            ? userDetail?.profile_photo
                            : userDeatil?.img
                        }
                      />
                    </div>
                    <div className="p-2">
                      <div className=" fs-20  dt-c fw-bold">
                        {userDeatil?.name}
                      </div>
                      <div className="fs-14 dst-c ">{userDeatil?.job}</div>
                    </div>
                  </div>
                  <div>
                    <textarea
                      name="post_description"
                      className="form-control border-0"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.post_description}
                      placeholder="Describe yourself here..."
                      rows={5}
                    ></textarea>
                    {errors.post_description &&
                      touched.post_description &&
                      errors.post_description}
                  </div>
                  <div className="ImagePreview">
                    {uploadImageUrl && uploadImageUrl != "" && (
                      <img src={uploadImageUrl} className="img-fluid" />
                    )}
                  </div>

                  <hr />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center flex-fill px-3">
                      <div
                        className="pe-2 position-relative cursorPointer"
                        title="Add Image"
                      >
                        <input
                          className="feed-file"
                          type="file"
                          id="formFile"
                          accept="image/*"
                          onChange={(e) => {
                            setuploadImage(e.target.files[0]);
                            setuploadImageUrl(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                        />
                        <FontAwesomeIcon icon={faImage} className="fs-30" />
                      </div>
                      <div className="pe-2" title="Add Discounts">
                        Upload Image
                      </div>
                      {/* <div className="pe-2 position-relative" title="Add Video">
                        <input
                          className=""
                          type="file"
                          id="formFile"
                          accept="video/*"
                        />
                        <FontAwesomeIcon
                          icon={faVideo}
                          className="fs-30"
                          // style={{
                          //   color: "#7fc15e",
                          // }}
                        />
                      </div> */}
                      {/* <div className="pe-2" title="Add Discounts">
                        Discounts
                      </div>
                      <div className="pe-2" title="Add Coupons">
                        Coupons
                      </div> */}
                    </div>
                    <div>
                      <Button
                        variant="secondary"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={close}
                        style={{ margin: "10px" }}
                      >
                        Post
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </form>
            )}
          </Formik>
        </Modal>
      </section>
    </>
  );
};

export default CreatePost;
