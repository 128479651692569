import React, { useState, useEffect, useCallback } from "react";
import Header from "src/Components/global/Header/Header";
import Footer from "src/Components/global/Footer/Footer.jsx";

import { Col, Container, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "src/Services/AxiosConfig";
import { Formik, Form } from "formik";
import ServiceCard from "src/Components/UI/Cards/ServiceCard";

const SearchServicesList = () => {
  const { searchKeyword } = useParams();
  const navigate = useNavigate();
  const search = searchKeyword;
  const [searchData, setSearchData] = useState();
  const [keyword, setKeyword] = useState(search);

  const searchServices = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `search/public/service?keyword=${keyword}`
      );
      setSearchData(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [keyword, searchKeyword]);

  useEffect(() => {
    (async function () {
      await searchServices();
    })();
  }, [keyword, searchServices]);
  return (
    <>
      <Header />
      <section className="services-banner" id="service_banner_id">
        <Container>
          <Row className="bannerRow align-items-center justify-content-around">
            <div className="w-75">
              <Col md={12}>
                <div>
                  <h1 className={"fs-80 recoleta-font text-white fw-normal"}>
                    Find the best fit "{keyword || search}"
                  </h1>
                </div>
              </Col>
              <Formik
                initialValues={{
                  searchKeyword: "",
                  searchLocation: "",
                }}
                onSubmit={async (values) => {
                  try {
                    // console.log(values);
                    setKeyword(values.searchKeyword);
                    navigate(
                      `/search-services-list/search=${values.searchKeyword}`
                    );
                  } catch (error) {
                    console.log("ERROR: ", error.message);
                  }
                }}
              >
                {({ handleChange, handleBlur, values, setFieldValue }) => (
                  <Col md={8}>
                    <div className="search-bar">
                      <Form className="d-flex justify-content-between">
                        <div className="flex-grow-1">
                          <input
                            size="lg"
                            type="text"
                            className="fs-16 form-control"
                            style={{ border: "none" }}
                            placeholder="Search service,keyword,vendor"
                            name="searchKeyword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.searchKeyword}
                          />
                        </div>
                        <div>
                          <Button className="gen-btn btn-lg" type="submit">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                )}
              </Formik>

              <Col md={10} className="mt-4">
                <p className="text-white">Popular Searches</p>
                <div className="buttons-group d-flex flex-wrap gap-2">
                  <Button
                    className="gen-btn btn-lg fs-14"
                    type="submit"
                    onClick={() => setKeyword(`roofing`)}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Roofing
                  </Button>
                  <Button
                    className="gen-btn btn-lg fs-14"
                    type="submit"
                    onClick={() => setKeyword(`painting`)}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Painting
                  </Button>
                  <Button
                    className="gen-btn btn-lg fs-14"
                    type="submit"
                    onClick={() => setKeyword(`carpentry`)}
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Carpentry
                  </Button>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>

      <section className="all-services s-p">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="text-dark recoleta-font">
                <span className="fs-72">All </span>{" "}
                <span className="fs-72">Services</span>{" "}
                <span className="fs-32">"{keyword || search}"</span>
              </h2>
            </Col>
          </Row>
          <Row>
            {searchData ? (
              searchData?.map((item, index) => (
                <ServiceCard
                  image={item.service_image[0].media_file}
                  title={item.service_title}
                  description={item.service_description}
                  id={item.service_id}
                  price={item.service_price}
                />
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #E1E1E1",
                  borderRadius: "10px",
                  height: "100px",
                  marginTop: "10px",
                }}
              >
                <h2>No Services Found</h2>
              </div>
            )}

            <Col md={12} className="text-center mt-5">
              <Link to="" className="secondary-color fs-24 ">
                Load More
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="home-footer">
        <Footer />
      </div>
    </>
  );
};

export default SearchServicesList;
