import React from "react";
import { Card } from "react-bootstrap";
import "./CommunityCategoryCard.css";
import profile from "src/Assets/images/BusinessDummyImages/Logo.jpg";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CommunityCategoryCard = (props) => {
  return (
    <div className="container-card">
      {props.getCategoryData.map((item, index) => (
        <div className="cat-card" style={{ margin: "5px" }}>
          <div className="cat-icon">
            <img
              src={item.icon}
              style={{
                color: "#37d1aa",
                padding: "20px",
                backgroundColor: "#dcdddc",
                height: "100px",
                width: "100px",
                borderRadius: "50px",
              }}
            />
          </div>
          <div>
            <p className="cat-txt">{item.name}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommunityCategoryCard;
