import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateCommunityContainer from "./add/CreateCommunityContainer";
import CommunityAnswers from "./answers/CommunityAnswers";
import CommunityDetailsContainer from "./detail/CommunityDetailsContainer";
import JoinCommunityContainer from "./joinCommunity/JoinCommunityContainer";
import CommunityListContainer from "./list/CommunityListContainer";

const CommunityRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/detail/:id" element={<CommunityDetailsContainer />} />
        <Route path="/add" element={<CreateCommunityContainer />} />
        <Route path="/answer" element={<CommunityAnswers />} />
        <Route path="/list" element={<CommunityListContainer />} />
        <Route path="/invited/:id" element={<JoinCommunityContainer />} />
      </Routes>
    </>
  );
};

export default CommunityRouter;
