import React, { useEffect } from "react";
import "./CommunityCard.css";
import { Button, Container } from "react-bootstrap";
import wall from "src/Assets/images/services/img-01.png";
import profile from "src/Assets/images/BusinessDummyImages/Logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faUsers,
  faStar,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import { add } from "src/Services/AxiosConfig";

const CommunityCard = (props) => {
  const navigate = useNavigate();
  const joinCommunity = async (id) => {
    const { data } = await add(
      `/community/members/add/request/`,
      `community=${id}`
    );
  };

  const pageIndex = () => {
    // console.log(props.pages);dd
    const a = [];
    for (let i = 0; i < props.pages; i++) {
      a.push(
        <Pagination.Item
          key={i + 1}
          active={i + 1 == props.currentPage}
          onClick={(event) => props.changePage(event)}
          // className={props.active ? 'paginator-background-color-active' : 'paginator-background-color'}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    // console.log(a);
    return a;
  };

  return (
    <>
      {/* {console.log(props.getData)} */}
      <div className="container-card">
        {props.getData &&
          props.getData.map((item, index) => (
            <div
              className="c-main t-main"
              style={{ margin: "9px" }}
              key={index}
            >
              <div className="t-img">
                <img
                  src={wall}
                  onClick={() =>
                    navigate(`/community/detail/${item.community_id}`)
                  }
                />
              </div>
              <div className="t-body c-body">
                <div className="c-profile-img">
                  <img src={profile} />
                </div>
                <div className="c-desc">
                  <div className="t-title">
                    <span>{item.community_name}</span>
                  </div>
                  <div className="t-subTitle c-sub">
                    <div className="c-sub1">
                      <span style={{ marginRight: "10px" }}>
                        <FontAwesomeIcon
                          icon={faUsers}
                          style={{ color: "#37d1aa" }}
                        />
                      </span>

                      <span>260 Members</span>
                    </div>
                    <div className="c-sub2">
                      <span style={{ marginRight: "10px" }}>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ color: "#FDAD11" }}
                        />
                      </span>

                      <span>4.78/5.0</span>
                    </div>
                  </div>

                  <div className="t-button1 d-flex justify-content-end">
                    <button
                      onClick={() => joinCommunity(item.community_id)}
                      className="t-btn1"
                    >
                      <span>Join</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <Pagination></Pagination>
      </div>
    </>
  );
};

export default CommunityCard;
