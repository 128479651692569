import React, { useState, useEffect } from "react";

import { Container, Navbar, Nav } from "react-bootstrap";
import "./Header.css";
import Flushlogo from "src/Assets/images/home/flush-logo.png";
import { Link } from "react-router-dom";
const Header = () => {
  const [stickyClass, setStickyClass] = useState("relative");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      const windowHeight = window.scrollY;
      windowHeight > 50 ? setStickyClass("bg-color") : setStickyClass("");
    }
  };
  return (
    <>
      <header
        id="header"
        className={`headerBgColor header fixed-top ${stickyClass}`}
      >
        <Navbar
          collapseOnSelect
          expand="lg"
          className="nav-bar-bg"
          sticky="top "
        >
          <Container className="d-flex justify-content-between align-items-center">
            <Link to="/">
              <Navbar.Brand>
                <img alt="logo" src={Flushlogo} />{" "}
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              className="justify-content-end collapseNavBar"
              id="responsive-navbar-nav"
            >
              {/* <Nav className="me-auto">
                <Nav.Link className="navLink" href="#features">
                  <span className="text-white">Features</span>
                </Nav.Link>
                <Nav.Link className="navLink" href="#pricing">
                  <span className="text-white">Pricing</span>
                </Nav.Link>
              </Nav> */}
              <Nav>
                <Link to="/search-services">
                  <button className="btn px-2 me-2 text-white text-uppercase">
                    Find the best fit
                  </button>
                </Link>
                <Link to="/login">
                  <button className="btn px-2 me-2 text-white text-uppercase">
                    Login
                    {/* Login */}
                  </button>
                </Link>
                <Link to="/register">
                  <button className=" btn px-2 text-white text-uppercase">
                    Get Started
                    {/* Register */}
                  </button>
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
