import React from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import './Register.css'
import { Link, useNavigate } from 'react-router-dom'

import CarouselComponent from './Components/CarouselComponent'
import Logo from 'src/Assets/images/register/logo.png'

import { Formik, Form } from 'formik'
import TextField from './Components/TextField'

import { toast } from 'react-toastify'
import firebase from 'src/Services/firebaseClient'

import * as Yup from 'yup'
const ForgetPassword = () => {
  const navigate = useNavigate()

  const handleSubmit = async (values) => {
    // console.log("forgetpasswordvalues", values);
    try {
      const response = await firebase.auth.forgetPassword(values.email)
      if (response === 'email sent') {
        toast.success('email sent', {
          position: toast.POSITION.BOTTOM_RIGHT
        })

        setTimeout(function () {
          navigate('/login')
        }, 2500)
      }
    } catch (error) {
      console.log(error)
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }
  const ForgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .min(2, 'Too Short!')
      .max(70, 'Too Long!')
      .required('Required')
  })

  return (
    <>
      <section className="register" id="register_id">
        <Container fluid>
          <Row>
            <div className="grid--col-02 px-0">
              <div className="align-items-center p-4 d-lg-block d-none">
                <div>
                  <img src={Logo} alt="logo" className="img-fluid logo-width" />
                </div>
                <div>
                  <CarouselComponent />
                </div>
              </div>

              <div className="bg-form-style ">
                <div className="d-flex min-vh-100 flex-column align-items-center justify-content-center">
                  <div className="w-75 mb-3">
                    <h2 className="text-start primary-font primary-color fs-59 fw-bold">
                      Forget Password!
                    </h2>
                    <p className="fs-24">
                      Enter Your Email to continue to Forget Password
                    </p>
                  </div>
                  <Formik
                    initialValues={{
                      email: ''
                    }}
                    validationSchema={ForgetPasswordSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, setSubmitting)
                      resetForm()
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <Form className="w-75">
                        <Row>
                          <Col md={12}>
                            <TextField
                              label="Email"
                              name="email"
                              placeholder="Enter Email"
                              type="email"
                            />
                          </Col>
                          <Col md={12} className="text-center mt-2 ">
                            <Button
                              className="accent-btn btn-green2 border-0 py-2 px-4"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <span className="me-2">
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                </span>
                              )}
                              Continue
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <div className="formFooter pt-4">
                    <p className="secondary-font fs-16 text-center mb-4">
                      Don’t have an account?
                      <Link
                        className="primary-color secondary-font-medium ps-1"
                        to="/register"
                      >
                        Register
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ForgetPassword
