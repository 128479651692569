import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Form, Field, ErrorMessage } from 'formik'
import { InputGroup, Spinner, Alert, FormGroup } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

import DummyImage from '../../../Assets/images/personal_profile/default_image.png'
import styled from 'styled-components'
import Autocomplete from 'react-google-autocomplete'

const BioFormUI = ({
  status,
  ProfileData,
  isSuccess,
  isError,
  navigateTo,
  values,
  isSubmitting,
  handleChange,
  handleBlur,
  setFieldValue,
  setuploadImage
}) => {
  const location = useLocation()
  const [tempLink, setTempLink] = useState(ProfileData.profile_photo)

  useEffect(() => {
    setTempLink(ProfileData.profile_photo)
  }, [ProfileData])

  const onFileChange = async (e, setFieldValue) => {
    setuploadImage(e.target.files[0])
    const img_src = await URL.createObjectURL(e.target.files[0])
    // console.log(img_src);
    setTempLink(img_src)
    setFieldValue('profile_photo', img_src)
  }
  return (
    <>
      <Form>
        <h1 className="title-head primary-font primary-color mt-3 mt-xl-0">
          Bio
        </h1>
        <div>
          {/* Image Upload field input */}
          <ProfileImage>
            <img
              style={{ objectFit: 'cover' }}
              src={tempLink || DummyImage}
              width={100}
              height={100}
              alt="ll"
            />
          </ProfileImage>

          {/* <input alt="af" type="image" /> */}

          <ImageUploadBtn>
            Add Image
            <input
              style={{ objectFit: 'cover' }}
              className=""
              type="file"
              accept="image/*"
              onChange={(e) => onFileChange(e, setFieldValue)}
            />
          </ImageUploadBtn>
        </div>
        <div className="d-flex flex-column flex-xl-row gap-3 mt-3">
          <FormGroup className="flex-fill">
            <label htmlFor="phone">Phone *</label>
            <InputGroup className="inputField4">
              <input
                id="phone"
                type="text"
                name="phone"
                placeholder="Phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                className="inputTransparent flex p-2"
              />
            </InputGroup>
            <p className="text-danger fs-12 mb-0">
              <ErrorMessage name="phone" />
            </p>
          </FormGroup>
          <FormGroup className="flex-fill">
            <label htmlFor="address">Address *</label>
            <InputGroup className="inputField4">
              <Autocomplete
                className="inputTransparent flex p-2"
                inputAutocompleteValue={values.address}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={(place) => {
                  setFieldValue('address', place)
                }}
                options={{
                  types: ['geocode', 'establishment']
                }}
              />
            </InputGroup>
            <p className="text-danger fs-12">
              <ErrorMessage name="address" />
            </p>
          </FormGroup>
        </div>
        <div className="mt-3">
          <label htmlFor="about">About</label>
          <InputGroup className="inputField4">
            <Field
              id="about"
              as="textarea"
              type="text"
              name="about"
              placeholder="Write Something about yourself"
              className="inputTransparent flex p-2"
            />
          </InputGroup>
          <p className="text-danger fs-12">
            <ErrorMessage name="about" />
          </p>
        </div>

        <div className="d-flex justify-content-center mt-2 align-items-center">
          <button
            className="btn-green2 w-auto text-white px-4 mb-2"
            type="submit"
            disabled={isSubmitting}
            // onClick={(e) => {
            //   setTouched(["phone", "address", "about"], true);
            // }}
          >
            {isSubmitting && (
              <span className="me-2">
                <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              </span>
            )}
            <span>Save</span>
          </button>
        </div>
        {isSuccess && <Alert variant="success">Success</Alert>}
        {isError && <Alert variant="danger">Something went wrong!</Alert>}
        <Link to={navigateTo || location.pathname}>
          <p
            className="primary-color "
            style={{
              float: 'right',
              cursor: 'pointer',
              display: 'inline-block'
            }}
          >
            {status !== 'onboarding'
              ? (
              <></>
                )
              : (
              <>
                <span className="me-2">Skip For Now</span>
                <FontAwesomeIcon icon={faArrowRightLong} />
              </>
                )}
          </p>
        </Link>
      </Form>
    </>
  )
}

const ProfileImage = styled.div`
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 20px;
  overflow: hidden;
`
const ImageUploadBtn = styled.button`
  position: relative;
  background-color: #f4f1f0;
  color: #3f464e;
  border: none;
  padding: 10px 10px;
  border-radius: 10px;
  margin-left: 10px;
  top: -10px;
  cursor: pointer;
`

export default BioFormUI
