import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import Select from "react-select";
import "./Community.css";
import { Input } from "reactstrap";
import { add } from "src/Services/AxiosConfig";
import { async } from "@firebase/util";
const CreateCommunity = ({
  handleSubmit,
  values,
  errors,
  handleChange,
  touched,
  formik,
  services,
  imageUpload,
  setImageUpload,
  handleImage,
}) => {
  return (
    <>
      <Layout type="leftsidebar">
        <form
          id="communityForm"
          className="tooltip-end-bottom"
          onSubmit={handleSubmit}
        >
          <Container className="py-3">
            <div className="header-title">
              <span>Create Community</span>
            </div>
            <div className="create-event-main shadow mt-2">
              <Row>
                <Col lg={9} md={9}>
                  <Row>
                    <Col md={6}>
                      <label className="label">Title</label>
                      <Form.Control
                        name="community_name"
                        className="fields-contact"
                        type="text"
                        placeholder="Ecommerce Conference"
                        onChange={handleChange}
                        value={values.community_name}
                      />
                      {errors.community_name && touched.community_name ? (
                        <div className="text-danger">
                          {errors.community_name}
                        </div>
                      ) : null}
                    </Col>
                    <Col md={6}>
                      <label className="label">Location</label>
                      <Form.Control
                        // name="location"
                        className="fields-contact"
                        // type="location"
                        placeholder="Location"
                        // onChange={handleChange}
                        // value={values.location}
                      />
                      {/* {errors.location && touched.location ? (
                        <div className="text-danger">{errors.location}</div>
                      ) : null} */}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}></Col>
                    <Col md={6}></Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="d-flex flex-column mt-3">
                        <label className="label">Category</label>
                        <Select
                          classNamePrefix="react-select"
                          placeholder="Category"
                          options={services}
                          // isMulti
                          name="service"
                          value={values.services}
                          onChange={(e) => {
                            formik.setFieldValue("service", e.value);
                          }}
                        />
                        {errors.service && touched.service ? (
                          <div className="text-danger">{errors.service}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <label className="label mt-3">Description</label>
                      <Form.Control
                        // name="description"
                        as="textarea"
                        rows={6}
                        placeholder="Description"
                        // value={values.description}
                        // onChange={handleChange}
                      />
                      {/* {errors.description && touched.description ? (
                        <div className="text-danger">{errors.description}</div>
                      ) : null} */}
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} md={3}>
                  <div className="img-upload-cre-coomunity mt-5">
                    <div className="img-upl">
                      <div className="img-icon">
                        <Input
                          type="file"
                          name="community_image"
                          id="community_image"
                          accept="image/*"
                          onChange={(e) => handleImage(e)}
                          className="input-community form-control"
                        />
                      </div>
                    </div>
                    <div>
                      <button className="img-upl-btn" type="button">
                        Upload image
                      </button>
                    </div>
                    {errors.community_image && touched.community_image ? (
                      <div className="text-danger">
                        {errors.community_image}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Button className="mt-5 community-btn ms-3" type="submit">
                  Create Community
                </Button>
              </Row>
            </div>
          </Container>
        </form>
      </Layout>
    </>
  );
};

export default CreateCommunity;
