import React, { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import '../../Components/global/JobListingCard/JobListingCard.css'
import AsyncSelect from 'react-select/async'
import JobsSmallSideCard from 'src/Components/global/JobsSmallSideCard/JobsSmallSideCard'
import JobListingCard from '../../Components/global/JobListingCard/JobListingCard'
import axios from '../../Services/AxiosConfig'
import Pagination from '../../Components/global/Pagination/Pagination'
import { toast } from 'react-toastify'
import Loader from 'src/Components/UI/Loader/Loader'

const customStyles = {
  dropdownIndicator: (base) => ({
    ...base,
    color: '#06C864' // Custom colour
  })
}

const JobListing = ({ dataLimit = 10 }) => {
  const [dataAPI, setData] = useState()
  const [totalData, setTotalData] = useState()
  const pages = Math.ceil(totalData / dataLimit)
  const [currentPage, setCurrentPage] = useState(1)
  const [startIndex, setStartIndex] = useState(0)

  // search states
  const [keyword, setKeyword] = useState('')
  const [collabs, setCollabs] = useState('')
  const [searchedData, setSearchedData] = useState('')

  let endValue
  if (totalData <= dataLimit) {
    endValue = totalData
  } else {
    endValue = dataLimit - 1
  }
  const [endIndex, setEndIndex] = useState(endValue)

  const remainingIndex = totalData % dataLimit
  const RecommendedJobAPI = useCallback(async () => {
    try {
      const { data } = await axios.get(
        'job/recommended/?index=' + startIndex + '&offset=' + dataLimit
      )
      // console.log("RecommendedJobAPI", data);
      await setData(data.data)
      await setTotalData(data.total_jobs)
    } catch (error) {
      console.log(error)
    }
  }, [startIndex, dataLimit])
  useEffect(() => {
    RecommendedJobAPI()
  }, [startIndex, RecommendedJobAPI])

  const saveAPI = async (JobId) => {
    try {
      const payload = {
        post_id: JobId
      }
      const { data } = await axios.post('job/save/', payload)
      // console.log("save job api", data);
      await RecommendedJobAPI()
      showToast(data.success, 'save', data.message)
    } catch (error) {
      // console.log(error);
      showToast(false, 'save', 'Network Error')
    }
  }

  const showToast = (success, type, message) => {
    toast.dismiss()

    if (success && type === 'save') {
      toast.success(message, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else if (!success && type === 'save') {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }

  async function goToNextPage () {
    // console.log("currentPage number", currentPage);

    if (currentPage + 1 > pages) {
    } else if (currentPage + 1 === pages) {
      setStartIndex(totalData - remainingIndex)
      setEndIndex(totalData - 1)
    } else if (startIndex <= totalData) {
      setEndIndex(totalData)
    } else {
      setCurrentPage((page) => page + 1)

      setStartIndex(startIndex + dataLimit)
      setEndIndex(endIndex + dataLimit)
    }
  }

  async function goToPreviousPage () {
    // console.log("current page on previous", currentPage);
    if (currentPage === 0) {
    } else if (currentPage + 1 === pages) {
      setCurrentPage((page) => page - 1)
      setStartIndex(startIndex - dataLimit)
      setEndIndex(endIndex - remainingIndex)
    }
    // else if(endIndex == totalData){
    //   setEndIndex(totalData);
    // }
    else if (startIndex !== 0) {
      setCurrentPage((page) => page - 1)

      setStartIndex(startIndex - dataLimit)
      setEndIndex(endIndex - dataLimit)
    }
  }

  const loadOptions = async () => {
    try {
      const response = await axios.get(`/search/jobpost?keyword=${keyword}`)
      // console.log(response);
      if (response.data.success === true) {
        setSearchedData(response.data.job_post_list)
        return response.data.job_post_list.map((item) => ({
          value: item.job_post_id,
          label: item.job_title
        }))
      }
      return []
    } catch (error) {
      console.log(error)
      return []
    }
  }

  const handleChange = (value) => {
    setKeyword(value)
  }

  const handleOnChange = async (value) => {
    await setCollabs(value)
  }

  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={3} md={3}>
            <JobsSmallSideCard />
          </Col>
          <Col lg={9} md={9}>
            <div className="Filter">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className="label">
                    <span>Search:</span>
                  </div>
                  <div className="fields">
                    <Row>
                      <Col lg={6} md={6} className="mb-md-3">
                        <AsyncSelect
                          placeholder="Search..."
                          styles={customStyles}
                          components={{
                            IndicatorSeparator: () => null,
                            IndicatorsContainer: () => null,
                            Menu: () => null,
                            MenuContainer: () => null
                          }}
                          loadOptions={loadOptions}
                          value={collabs}
                          onInputChange={(value) => handleChange(value)}
                          onChange={(value) => handleOnChange(value)}
                          noOptionsMessage={() => null}
                          // isOptionDisabled={true}
                        />
                        {/* <input
                          placeholder="Search..."
                          styles={customStyles}
                          components={{ IndicatorSeparator: () => null }}
                          loadOptions={loadOptions}
                          value={collabs}
                          onInputChange={(value) => handleChange(value)}
                          onChange={(value) => handleOnChange(value)}
                        /> */}
                      </Col>
                      {/* <Col lg={3} md={6}>
                        <Select
                          styles={customStyles}
                          components={{ IndicatorSeparator: () => null }}
                          options={jobType}
                          placeholder="Job Type"
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <Select
                          styles={customStyles}
                          components={{ IndicatorSeparator: () => null }}
                          options={experience}
                          placeholder="Experience"
                        />
                      </Col> */}
                      {/* <Col lg={3} md={6}>
                        <Select
                          styles={customStyles}
                          components={{ IndicatorSeparator: () => null }}
                          options={datePosted}
                          placeholder="Dated Posted"
                        />
                      </Col> */}
                    </Row>
                  </div>
                </Form.Group>
              </Form>
            </div>
            {/* <div className="JobListHeader mapJobList"></div> */}
            {/* <div className="mapJobList"> */}
            {searchedData
              ? (
              <>
                <div className="JobListHeader mapJobList">
                  <span>Searched Jobs</span>
                </div>
                {searchedData.map((item, index) => (
                  <JobListingCard item={item} saveAPI={saveAPI} />
                ))}
              </>
                )
              : dataAPI
                ? (
              <>
                <div className="JobListHeader mapJobList">
                  <span>Recommended Jobs</span>
                </div>
                {dataAPI.map((item, index) => (
                  <JobListingCard item={item} saveAPI={saveAPI} />
                ))}
              </>
                  )
                : (
              <Loader />
                  )}

            {/* <div className="JobListFooter">
                <span>
                  <a href="#">See All Recommended</a>
                </span>
              </div> */}
            {/* </div> */}

            <div style={{ paddingTop: '10px' }}>
              <Pagination
                goToNextPage={goToNextPage}
                goToPreviousPage={goToPreviousPage}
                totalData={totalData}
                startIndex={startIndex}
                endIndex={endIndex}
              />
            </div>

            {/* <div className="similar-job">
              <div className="JobListHeader">
                <span>Similar to a job you applied in last week</span>
              </div>
              <div>
                {data.map((item, index) => (
                  <JobListingCard item={item} />
                ))}

                <div className="JobListFooter">
                  <span>
                    <a href="#">See All Recommended</a>
                  </span>
                </div>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default JobListing
