import React from "react";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import { Col, Container, Form, Row, InputGroup } from "react-bootstrap";
import arrow from "src/Assets/images/arrow.png";
import "./BookmarkEvent.css";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpcomingEventCard from "src/Components/global/UpcomingEventCard/UpcomingEventCard";

const BookmarkEvent = ({ data }) => {
  const alert = () => {
    window.alert("hello");
  };
  return (
    <>
      <Layout type="leftsidebar">
        <Container className="mt-3">
          <Row className="g-4">
            <Col md={12}>
              <div className="main-d">
                <div className="d-flex justify-content-between">
                  <div>
                    <button onClick={alert} className="back-btn">
                      <span>
                        <img src={arrow} alt="" />
                      </span>
                    </button>
                  </div>
                  <div>
                    <button className="btn-bookmark">
                      {" "}
                      <span>
                        {" "}
                        <FontAwesomeIcon icon={faPlus} />
                      </span>{" "}
                      Create Event
                    </button>
                  </div>
                </div>
                <div className="header-title marBot">
                  <span>Search Event</span>
                </div>
                <div>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{ color: "#06C864" }}
                      />
                    </InputGroup.Text>
                    <Form.Control
                      className="inp-bck"
                      placeholder="Search Event"
                      size="lg"
                    />
                  </InputGroup>
                </div>
                <div>
                  <UpcomingEventCard getData={data} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default BookmarkEvent;
