import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Graphic from "src/Assets/images/register/graphic-01.png";
function CarouselComponent() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  const registerSlider = [
    {
      img: Graphic,
      title: "Where Community Meet Service",
      // description:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et ultricies neque, sit amet porttitor dui.'
    },
    {
      img: Graphic,
      title: "Where Community Meet Service",
      // description:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et ultricies neque, sit amet porttitor dui.'
    },
    {
      img: Graphic,
      title: "Where Community Meet Service",
      // description:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et ultricies neque, sit amet porttitor dui.'
    },
  ];

  return (
    <>
      <Carousel
        arrows={false}
        loop={true}
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={3000}
        dotListClass="custom-dot-list-style"
        // itemClass="carousel-item-padding-40-px"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        infinite={true}
        showDots={true}
      >
        {registerSlider.map((item, index) => {
          return (
            <div key={index} className="p-0">
              <div className="graphic-image text-center">
                <img
                  alt="register slider"
                  src={item.img}
                  className="img-fluid graphic-style"
                />
              </div>
              <h2 className="text-white recoleta-font text-center fs-72 mt-2 svg-style">
                {item.title}
              </h2>
              <p className="text-white fw-light text-center fs-24 mt-2 svg-style mb-5">
                {item.description}
              </p>
            </div>
          );
        })}
      </Carousel>
      ;
    </>
  );
}

export default CarouselComponent;
