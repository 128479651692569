import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateCommunity from "./components/CreateCommunity";
import { view, add } from "src/Services/AxiosConfig";

const CreateCommunityContainer = () => {
  const [imageid, setImageId] = useState();
  const initialValues = {
    community_name: "",
    service: "",
    community_image: imageid,
  };
  const handleImage = async (e) => {
    const formData = new FormData();
    console.log(e.target.files[0], "dhhdd");
    formData.append("image_file", e.target.files[0]);
    const { data } = await add(`/core/user/upload/media/storage/`, formData);
    console.log(data.profile_url, "image");
    setImageId(data.profile_url);
  };
  const [imageUpload, setImageUpload] = useState(null);
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("community_image", imageid.image_id);
    formData.append("community_name", values.community_name);
    formData.append("service", values.service);
    console.log(imageid, "body");

    add("community/details/", formData)
      .then((res) => {
        console.log(res);
      })
      .then((err) => {
        console.log(err);
      });
  };
  const validationSchema = Yup.object().shape({
    community_name: Yup.string().required("Name is required"),
    service: Yup.string().required("Category is required"),
    // community_image: Yup.string().required("Image is required"),
  });

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const [services, setServices] = useState([]);
  const getCategories = async () => {
    const { data } = await view(`pre/services/`);

    if (data.success) {
      let arr = data?.data?.services.map((item) => ({
        label: item.Service_name,
        value: item.PreService_id,
      }));
      setServices(arr);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);
  const [baseImage, setBaseImage] = useState("");

  return (
    <>
      {/* {console.log(values.community_image, "container")} */}
      <CreateCommunity
        formik={formik}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        touched={touched}
        errors={errors}
        values={values}
        getCategories={getCategories}
        services={services}
        setServices={setServices}
        imageUpload={imageUpload}
        setImageUpload={setImageUpload}
        handleImage={handleImage}
      />
    </>
  );
};

export default CreateCommunityContainer;
