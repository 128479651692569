import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Form, Row, Button, Card } from "react-bootstrap";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import { NavLink } from "react-router-dom";
import {
  faEarth,
  faUserTag,
  faLocationPin,
  faCalendarDays,
  faTicket,
  faStar as FillStar,
  faUserGroup,
  faClock,
  faShare,
  faPaperPlane,
  faImage,
  faFilm,
} from "@fortawesome/free-solid-svg-icons";
import mainImg from "src/Assets/images/event/banner-cover.png";
import EventsImg from "src/Assets/images/event/organizer.png";
import subImg from "src/Assets/images/community/detail-sub.png";
import profileImg from "src/Assets/images/personal_profile/ProfileImage.jpg";
import styled from "styled-components";
import {
  faStar as EmptyStar,
  faHeart,
  faMessage,
  faBookmark,
  faFaceSmile,
} from "@fortawesome/free-regular-svg-icons";
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faLinkedin,
  faYoutube,
  faFacebookMessenger,
} from "@fortawesome/free-brands-svg-icons";
import "../../detail/components/event.css";
import UpcomingEventCard from "src/Components/global/UpcomingEventCard/UpcomingEventCard";

const Event = ({ data }) => {
  const FillStarContainer = styled.span`
    color: #febf00;
  `;
  const EmptyStarContainer = styled.span`
    color: gray;
  `;
  return (
    <>
      <Layout type="leftsidebar">
        <Container className="mt-3">
          <Row className="g-4">
            <Col xs="12" lg="9">
              {/* For organizer */}
              <Card className="bg-white mb-4">
                <Card.Body>
                  <Row className="g-4">
                    <Col md="2">
                      <img
                        src={EventsImg}
                        alt="Organizer Image"
                        className="img-fluid rounded-circle"
                      />
                    </Col>
                    <Col md="10">
                      <textarea
                        className="form-control border-0 pt-4"
                        placeholder="Create post"
                        rows={3}
                      ></textarea>
                    </Col>
                  </Row>

                  <Row className="align-items-center g-4 mt-2">
                    <Col md="6">
                      <FontAwesomeIcon
                        icon={faImage}
                        size="xl"
                        className="cursor-pointer"
                      />
                      <FontAwesomeIcon
                        icon={faFilm}
                        size="xl"
                        className="cursor-pointer mx-2"
                      />
                      <FontAwesomeIcon
                        icon={faFaceSmile}
                        size="xl"
                        className="cursor-pointer"
                      />
                    </Col>
                    <Col md="6" className="text-end">
                      <Button
                        variant="success"
                        className="bg-06C864 text-white"
                      >
                        Send Post
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {/* For organizer */}

              {/* Card  start*/}
              <div className="t-main">
                <div className="t-img">
                  <img src={mainImg} className="img-fluid" />
                </div>
                <div className="t-body pad">
                  <Row className="align-items-center g-3 border-bottom pb-3">
                    <Col md="12" lg="7">
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        size="sm"
                        style={{ color: "#37d1aa" }}
                        className="me-2"
                      />
                      Monday, October 02, 2022 At 02pm - 07 pm
                    </Col>
                    <Col md="12" lg="5" className="text-end">
                      <span className="bg-06C864 rounded-circle p-2 cursor-pointer">
                        <FontAwesomeIcon
                          icon={faBookmark}
                          size="lg"
                          style={{ color: "#fff" }}
                        />
                      </span>
                      <Button
                        variant="success"
                        className="bg-06C864 text-white ms-2"
                      >
                        <FontAwesomeIcon
                          icon={faTicket}
                          size="sm"
                          style={{ color: "#fff" }}
                          className="me-1"
                        />
                        Starts at $5.00
                      </Button>
                    </Col>
                  </Row>

                  <div className="t-title u-title h5 pb-0 fw-bold my-3">
                    Robel LLC is providing plumbing and electrician services
                  </div>
                  <div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nam vestibulum consectetur quam, eget eleifend lectus
                      posuere at. Nulla porttitor quam nec libero convallis.
                    </p>
                    <p>
                      Curabitur tempor quam eu justo eleifend, ut commodo lacus
                      facilisis. Quisque mollis pellentesque justo ac rutrum.
                      Donec convallis sit amet tellus eu fringilla. Nulla
                      porttitor quam nec libero convallis, ut venenatis risus
                      dapibus. Curabitur consequat rutrum dui eget lobortis.
                    </p>
                  </div>

                  {/* About section start*/}
                  <div className="my-5">
                    <div className="h6 fw-bolder mb-2">About This Event</div>
                    <div className="pb-1">
                      <FontAwesomeIcon
                        icon={faEarth}
                        size="sm"
                        style={{ color: "#37d1aa" }}
                      />
                      <span className="ms-2 fs-14">Public</span>
                    </div>
                    <div className="pb-1">
                      <FontAwesomeIcon
                        icon={faUserTag}
                        size="sm"
                        style={{ color: "#37d1aa" }}
                      />
                      <span className="ms-2 fs-14">Jason Stewart</span>
                    </div>
                    <div className="pb-1">
                      <FontAwesomeIcon
                        icon={faLocationPin}
                        size="sm"
                        style={{ color: "#37d1aa" }}
                      />
                      <span className="ms-2 fs-14">
                        Amazon Fashion Studio - London
                        <small className="cl-06C864 ms-1 cursor-pointer">
                          (view map)
                        </small>
                      </span>
                    </div>
                    <div className="pb-1">
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        size="sm"
                        style={{ color: "#37d1aa" }}
                      />
                      <span className="ms-2 fs-14">04-06 October, 2022</span>
                    </div>
                    <div className="pb-1">
                      <FontAwesomeIcon
                        icon={faClock}
                        size="sm"
                        style={{ color: "#37d1aa" }}
                      />
                      <span className="ms-2 fs-14">02:00 pm to 08:00pm</span>
                    </div>
                    <div className="pb-1">
                      <FontAwesomeIcon
                        icon={faUserGroup}
                        size="sm"
                        style={{ color: "#37d1aa" }}
                      />
                      <span className="ms-2 fs-14">150 seats</span>
                    </div>
                  </div>
                  {/* About section end*/}

                  {/* Share section start */}
                  <div>
                    <div className="h6 fw-bolder mb-3">Share with friends</div>
                    <Row className="align-items-center g-3">
                      <Col md="9">
                        <FontAwesomeIcon
                          icon={faFacebook}
                          size="lg"
                          style={{ color: "#37d1aa" }}
                          className="pe-2"
                        />
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          size="lg"
                          style={{ color: "#37d1aa" }}
                          className="pe-2"
                        />
                        <FontAwesomeIcon
                          icon={faFacebookMessenger}
                          size="lg"
                          style={{ color: "#37d1aa" }}
                          className="pe-2"
                        />
                        <FontAwesomeIcon
                          icon={faTwitter}
                          size="lg"
                          style={{ color: "#37d1aa" }}
                          className="pe-2"
                        />
                        <FontAwesomeIcon
                          icon={faInstagram}
                          size="lg"
                          style={{ color: "#37d1aa" }}
                          className="pe-2"
                        />
                      </Col>
                      <Col md="3" className="text-end">
                        <Button
                          variant="success"
                          className="btn bg-06C864 rounded w-100"
                        >
                          Register
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  {/* Share section end */}
                </div>

                {/* Comments section start*/}
                <div style={{ margin: "30px" }}>
                  <Row className="border-bottom pb-3 my-3">
                    <Col md="9" sm="12">
                      <img
                        src={EventsImg}
                        className="rounded-circle"
                        width={30}
                        height={30}
                      />
                      <img
                        src={EventsImg}
                        className="rounded-circle"
                        width={30}
                        height={30}
                      />
                      <img
                        src={EventsImg}
                        className="rounded-circle"
                        width={30}
                        height={30}
                      />

                      <span className="ms-2 fs-14 text-muted">
                        Antonin Guillot and 2,741 others like
                      </span>
                    </Col>
                    <Col md="3" sm="12" className="text-end">
                      <span className="fs-14">279 comments</span>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md="4">
                      <FontAwesomeIcon
                        icon={faHeart}
                        size="lg"
                        className="pe-2"
                      />
                      <span className="me-2">Like</span>
                    </Col>
                    <Col md="4" className="text-center">
                      <FontAwesomeIcon
                        icon={faMessage}
                        size="lg"
                        className="pe-2"
                      />
                      <span className="me-2">Comment</span>
                    </Col>
                    <Col md="4" className="text-end">
                      <FontAwesomeIcon
                        icon={faShare}
                        size="lg"
                        className="pe-2"
                      />
                      <span className="me-2">Share</span>
                    </Col>

                    <Col md="12">
                      <div className="py-3">
                        <form>
                          <div className="d-flex align-items-center position-relative">
                            <img
                              src={profileImg}
                              className="img-fluid rounded-circle"
                              alt="profile-img"
                              width="60"
                            />
                            <input
                              type="text"
                              className="form-control ms-3 commentInput"
                              placeholder="Add a comment"
                            />
                            <FontAwesomeIcon
                              icon={faFaceSmile}
                              size="lg"
                              className="comment-emoji cursor-pointer"
                            />
                            <div className="bg-06C864 rounded-circle p-2 cursor-pointer">
                              <FontAwesomeIcon
                                icon={faPaperPlane}
                                size="lg"
                                style={{ color: "#fff" }}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Comments section end*/}
              </div>
              {/* Card  end*/}
            </Col>
            <Col xs="12" lg="3">
              {/* Event Organizer Details Start */}
              <div className="db_color mt-0">
                <div className="fs-16 primary-font p-3 bg-card-06C864">
                  Organizer Details
                </div>
                <div className="px-3 py-2 fs-14">
                  <Row className="justify-content-center my-3">
                    <Col lg="5">
                      <img
                        src={EventsImg}
                        alt="Organizer Image"
                        className="img-fluid rounded-circle"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col lg="4" className="text-muted ">
                      Name:
                    </Col>
                    <Col lg="8">Jason Stewart</Col>
                  </Row>
                  <Row className="mb-2">
                    <Col lg="4" className="text-muted ">
                      About:
                    </Col>
                    <Col lg="8" className="text-justify">
                      Lorem ipsum dolor sit amet, cons ectetur adipiscing.
                      posuere at.
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col lg="4" className="text-muted ">
                      Follower:
                    </Col>
                    <Col lg="8" className="text-justify">
                      N/A
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col lg="4" className="text-muted">
                      Rating:
                    </Col>
                    <Col lg="8" className="text-justify">
                      <FillStarContainer>
                        <FontAwesomeIcon icon={FillStar} size="sm" />
                        <FontAwesomeIcon icon={FillStar} size="sm" />
                        <FontAwesomeIcon icon={FillStar} size="sm" />
                        <FontAwesomeIcon icon={FillStar} size="sm" />
                        <FontAwesomeIcon icon={EmptyStar} size="sm" />
                      </FillStarContainer>
                      <span className="ms-2 text-small text-muted">4/5</span>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col lg="6">
                      <Button
                        variant="success"
                        className="btn bg-06C864 rounded w-100"
                      >
                        Follow
                      </Button>
                    </Col>
                    <Col lg="6">
                      <Button variant="outline-success" className="w-100">
                        Contact
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* Event Organizer Details End */}

              <div className="db_color mt-4">
                <div className="text-uppercase fs-16 primary-font p-3 bg-card-06C864">
                  More events from organizer
                </div>
                <div className="px-3 py-2">
                  <div className="d-flex justify-content-start align-items-center py-2">
                    <div className=" ">
                      <img
                        src={EventsImg}
                        // className="img-fluid"
                        width="50"
                        height="50"
                        className="rounded-4"
                      />
                    </div>
                    <div className="px-2">
                      <div className="fs-14 text-muted dst-c">
                        Thu, Oct 07, 2021
                      </div>
                      <div className="fs-14 secondary-font dt-c">
                        E-commerce Growth Meetup
                      </div>
                      <div className="fs-14 text-muted dst-c">
                        Amazon Fashion Studio - London
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center py-2">
                    <div className=" ">
                      <img
                        src={EventsImg}
                        // className="img-fluid"
                        width="50"
                        height="50"
                        className="rounded-4"
                      />
                    </div>
                    <div className="px-2">
                      <div className="fs-14 text-muted dst-c">
                        Thu, Oct 07, 2021
                      </div>
                      <div className="fs-14 secondary-font dt-c">
                        E-commerce Growth Meetup
                      </div>
                      <div className="fs-14 text-muted dst-c">
                        Amazon Fashion Studio - London
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center py-2">
                    <div className=" ">
                      <img
                        src={EventsImg}
                        // className="img-fluid"
                        width="50"
                        height="50"
                        className="rounded-4"
                      />
                    </div>
                    <div className="px-2">
                      <div className="fs-14 text-muted dst-c">
                        Thu, Oct 07, 2021
                      </div>
                      <div className="fs-14 secondary-font dt-c">
                        E-commerce Growth Meetup
                      </div>
                      <div className="fs-14 text-muted dst-c">
                        Amazon Fashion Studio - London
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-3 more_community_card_footer text-center">
                  <NavLink to="/event" className=" custom-light-green">
                    See More Events
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>

          <div>
            <div className="header-title marBot t-space mt-5 ps-0">
              <span>Other Events You May Like</span>
            </div>
            <UpcomingEventCard
              getData={data}
              gridClass="col-12 col-md-6 col-lg-4"
            />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default Event;
