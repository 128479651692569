import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SimilarJobs from '../JobListingGrid/JobListingGrid'
import Layout from 'src/Components/global/Dashboard/Layouts/Layout'
import JobDetail from '../JobListingDetail/JobListingDetail'
import SavedJobs from '../SavedJobs/SavedJobs'
import AppliedJobs from '../AppliedJobs/AppliedJobs'
import JobListing from '../JobListing/JobListing'
import JobArchivePage from '../JobArchivePage/JobArchivePage'
// import AllJobs from "src/Components/global/AllJobs/AllJobs";

function JobsRouter () {
  return (
    <Layout type="plain">
      <Routes>
        <Route path="/" element={<JobListing />} />
        {/* <Route path="/all" element={<AllJobs />} />
        <Route path="/recommended" element={<AllJobs />} /> */}
        <Route path="/applied" element={<AppliedJobs />} />
        <Route path="/archive" element={<JobArchivePage />} />
        <Route path="/saved" element={<SavedJobs />} />
        <Route path="/:jobId" element={<JobDetail />} />
        <Route path="/similar" element={<SimilarJobs />} />
      </Routes>
    </Layout>
  )
}

export default JobsRouter
