export default function transformNotification(notification) {
  //   console.log("transformNotification", notification);
  const {
    details: { body, image_link, liked_by, post_id },
    create_ts,
    seen,
    id,
    notification_type,
  } = notification;

  if (notification.details.body.includes("liked")) {
    return {
      type: "like",
      body,
      detailPage: `/post/${post_id}`,
      seen,
      id,
      create_ts,
      image_link,
      liked_by,
    };
  } else if (notification.details.body.includes("commented")) {
    return {
      type: "comment",
      body,
      detailPage: `/post/${post_id}`,
      seen,
      id,
      create_ts,
      image_link,
      liked_by,
    };
  } else {
    return {
      type: "general",
      body,
      detailPage: `/post/${post_id}`,
      seen,
      id,
      create_ts,
      image_link,
      liked_by,
    };
  }
}
