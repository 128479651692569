import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../Services/AxiosConfig";
import transformNotification from "src/Utils/transformNotification";

// Get All Notifications Thunk
export const fetchAllNotifications = createAsyncThunk(
  "notification/fetchAllNotifications",
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.get("get/user/notifications");

      if (data.success === true) {
        console.log("notifications list", data.notification_list);
        const notificationList = data.notification_list.map((notification) =>
          transformNotification(notification)
        );
        console.log("notificationList", notificationList);
        return notificationList;
      } else {
        return [];
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  loadingNotifications: false,
  data: [],
  N: 0,
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  reducers: {
    setNotification: (state, { payload }) => {
      state.data = [...state.data, payload];
      state.N = state.N + 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllNotifications.fulfilled, (state, { payload }) => {
      state.loadingNotifications = false;
      state.data = payload;
      state.N = payload.length;
    });
    builder.addCase(fetchAllNotifications.pending, (state) => {
      state.loadingNotifications = true;
    });
    builder.addCase(fetchAllNotifications.rejected, (state) => {
      state.loadingNotifications = false;
    });
  },
});

export const { setNotification } = NotificationSlice.actions;

export default NotificationSlice.reducer;
