import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { view } from "src/Services/AxiosConfig";
import JoinCommunity from "./components/JoinCommunity";

const JoinCommunityContainer = () => {
  const { id } = useParams();
  const getInvitation = async () => {
    try {
      const { data } = await view(`community/invited/?${id}`);
      console.log(data, "data");
      if (data.success) {
        toast.success("Joined community successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getInvitation();
  }, []);

  return (
    <>
      <JoinCommunity />
    </>
  );
};

export default JoinCommunityContainer;
