import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import { Container, Row, Col } from "react-bootstrap";
import "./RegisterEventFree.css";
import closeup from "src/Assets/images/close-up-worker.png";
import Form from "react-bootstrap/Form";

const RegisterEventFree = ({ setStep }) => {
  return (
    <>
      <Layout>
        <div className="p-5">
          <Container className="main-card-reg shadow">
            <Row>
              <Col lg={9} md={9}>
                <div className="mt-3">
                  <span
                    style={{
                      fontSize: "26px",
                      lineHeight: "40px",
                      fontFamily: "Circular Std",
                      padding: "15px",
                    }}
                  >
                    Register Summary
                  </span>
                  <hr />
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    style={{
                      fontSize: "22px",
                      lineHeight: "40px",
                      fontFamily: "Circular Std",
                      padding: "15px",
                    }}
                  >
                    Ecommerce Growth meetup
                  </span>
                  <Form.Select
                    size="sm"
                    aria-label="Default select example"
                    className="select-ev"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </Form.Select>
                </div>
                <div>
                  <span style={{ color: "#06c864", padding: "15px" }}>
                    Free
                  </span>
                </div>
                <div className="mt-5">
                  <span style={{ padding: "15px" }}>24 seats left</span>
                </div>
                <hr />
                <div>
                  <button
                    onClick={() => setStep("step2")}
                    className="reg-free-btn"
                  >
                    Register
                  </button>
                </div>
              </Col>
              <Col lg={3} md={3} className="reg-ev-col">
                <div className="img-sec-reg">
                  <img
                    src={closeup}
                    alt="dd"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
                <div>
                  <div>
                    <span
                      style={{
                        fontSize: "22px",
                        lineHeight: "40px",
                        fontFamily: "Circular Std",
                        padding: "15px",
                      }}
                      className="mt-4"
                    >
                      Order Summary
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <span style={{ fontSize: "12px", padding: "15px" }}>
                      1x Annual Business Conference
                    </span>
                    <span style={{ fontSize: "12px", padding: "15px" }}>
                      $0
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-4">
                    <span
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        padding: "15px",
                      }}
                    >
                      Total:
                    </span>
                    <span
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        padding: "15px",
                      }}
                    >
                      $0
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default RegisterEventFree;
