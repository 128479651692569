import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  FormGroup
} from 'react-bootstrap'
import './Register.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import CarouselComponent from './Components/CarouselComponent'
import Logo from 'src/Assets/images/register/logo.png'

import { Formik, Form } from 'formik'
import MultiSelectComponent from 'src/Components/global/MultiSelect/MultiSelect'

import AxiosConfig from '../../Services/AxiosConfig'
import * as Yup from 'yup'

const RegisterSkills = () => {
  const [skillsList, setSkillsList] = useState([])

  const getSkills = async () => {
    try {
      const res = await AxiosConfig.get('pre/services/')
      // console.log(res.data);
      if (!res.data.data) throw new Error('Invalid Response')
      const tempData = res.data.data.services.map((item) => ({
        service_id: item.PreService_id,
        service_title: item.Service_name,
        skill_level: 'expert'
      }))
      setSkillsList(tempData)
    } catch (error) {
      // console.log(error);
    }
  }
  useEffect(() => {
    getSkills()
  }, [])

  const portfolioSchema = Yup.object().shape({
    portfolio: Yup.array().of(
      Yup.object().shape({
        portfolio_label: Yup.string().required('This Field is Required'),
        description: Yup.string().required('This Field is Required'),
        portfolio_media_url: Yup.array().length(1, 'This Field is Required')
      })
    )
  })

  const onSubmit = async (values, setSubmitting) => {
    try {
      setSubmitting(true)
      const res = await AxiosConfig.post(
        'user/skillset/service/view/',
        values.skills
      )
      // console.log(res);
      setSubmitting(false)
      if (res.data.success === true) {
        window.location.href = '/feed'
      }
    } catch (error) {
      console.log(error)
      setSubmitting(false)
    }
  }

  return (
    <>
      <section className="register registerMultiInput" id="register_id">
        <Container fluid>
          <Row>
            <div className="grid--col-02 px-0">
              <div className="align-items-center p-4 d-lg-block d-none">
                <div>
                  <img src={Logo} alt="logo" className="img-fluid logo-width" />
                </div>
                <div>
                  <CarouselComponent />
                </div>
              </div>

              <div className="bg-form-style ">
                <div className="d-flex min-vh-100 flex-column align-items-center justify-content-center">
                  <div className="w-75 mb-3">
                    <h2 className="text-start primary-font primary-color fs-59 fw-bold">
                      Skills
                    </h2>
                  </div>
                  <Formik
                    initialValues={{ skills: [] }}
                    enableReinitialize
                    validationSchema={portfolioSchema}
                    onSubmit={async (values, { setSubmitting }) =>
                      onSubmit(values, setSubmitting)
                    }
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form className="w-75">
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <label htmlFor="skills" className="mb-1">
                                Select Relevant Skills
                              </label>

                              <MultiSelectComponent
                                displayName="service_title"
                                setFieldValue={setFieldValue}
                                name="skills"
                                options={skillsList}
                                selectedValues={values.skills}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={12} className="text-center mt-2 mb-4">
                            <Button
                              className="accent-btn btn-green2 border-0 py-2 px-4"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <span className="me-2">
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                </span>
                              )}
                              Save & Continue
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <Link
                    to={'/feed'}
                    style={{
                      float: 'right',
                      padding: '10px',
                      cursor: 'pointer'
                    }}
                  >
                    <p className="primary-color ">
                      <span className="me-2">Skip For Now</span>
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default RegisterSkills
