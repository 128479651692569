import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import "./Register.css";
import { Link } from "react-router-dom";

import CarouselComponent from "./Components/CarouselComponent";
import Logo from "src/Assets/images/register/logo-green.png";

import { Formik, Form, ErrorMessage } from "formik";
import TextField from "./Components/TextField";

import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
import * as Yup from "yup";

const Login = () => {
  const [passwordType, setPasswordType] = useState("password");
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    toast.dismiss();
    if (auth.isSignInProcessing) {
      toast.loading("Please wait...");
    }
    if (auth.isSignInError) {
      if (auth.signInErrorMessage.includes("wrong-password")) {
        toast.error("Wrong password. Please try again");
      } else if (auth.signInErrorMessage.includes("user-not-found")) {
        toast.error("User not found. Please try again");
      } else if (auth.signInErrorMessage.includes("network-request-failed")) {
        toast.error("Network connection failed. Please try again");
      } else {
        toast.error("Error: " + auth.signInErrorMessage);
      }
    }
    if (auth.signinSuccess) {
      toast({
        render: "Success",
        type: "success",
      });
    }
  }, [auth]);

  const SigninSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("Required"),
    password: Yup.string().required("Required."),
  });

  const handleSignInSubmit = (values) => {
    try {
      dispatch(signin(values));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <section className="register" id="register_id">
        <Container fluid>
          <Row>
            <div className="grid--col-02 px-0">
              <div className="align-items-center p-4 d-lg-block d-none">
                <div className="signup-left-padding">
                  <CarouselComponent />
                </div>
              </div>

              <div className="bg-form-style ">
                <div className="form-width-1 mx-auto d-flex min-vh-100 flex-column align-items-center justify-content-center">
                  <div>
                    <img
                      src={Logo}
                      alt="logo"
                      className="img-fluid logo-width mb-3"
                    />
                  </div>
                  <div className=" mb-3">
                    <h2 className="text-start primary-font primary-color fs-59 fw-bold">
                      Welcome Back!
                    </h2>
                    <p className="fs-24">Login to continue to Flush App</p>
                  </div>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={SigninSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSignInSubmit(values, setSubmitting);
                      resetForm();
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <Form className="w-75">
                        <Row>
                          <Col md={12}>
                            <TextField
                              label="Email"
                              name="email"
                              placeholder="Enter Email"
                              type="email"
                            />
                          </Col>
                          <Col md={12}>
                            <TextField
                              label="Password"
                              name="password"
                              placeholder="Password"
                              type={passwordType}
                              setPasswordType={setPasswordType}
                            />
                          </Col>
                          <Col md={12}>
                            <div className="forgetPassword d-flex justify-content-between">
                              <div className="agreeStatementDiv">
                                <input
                                  name="agree"
                                  type="checkbox"
                                  id="agreeStatement"
                                  value={values.agree}
                                  checked={values.agree}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="me-2"
                                />
                                <label htmlFor="agreeStatement">
                                  Keep me log in
                                </label>
                                <p className="text-danger fs-12">
                                  <ErrorMessage name="agree" />
                                </p>
                              </div>
                              <div className="forgetLink">
                                <Link
                                  className="primary-color secondary-font fs-16"
                                  to="/forgetpassword"
                                >
                                  Forgot Password?
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col md={12} className="text-center mt-2 ">
                            <Button
                              className="accent-btn btn-green2 border-0 py-2 px-4"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <span className="me-2">
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                </span>
                              )}
                              Log In
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <div className="formFooter pt-4">
                    <p className="secondary-font fs-16 text-center mb-4">
                      Don’t have an account?
                      <Link
                        className="primary-color secondary-font-medium ps-1"
                        to="/register"
                      >
                        Register
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;
