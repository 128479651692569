import styles from "./Notification.module.css";
import TimeAgo from "react-timeago";
import { useNavigate } from "react-router-dom";

export function NotificationView({ data }) {
  //   console.log("data", data);
  switch (data.type) {
    case "like":
      return <Like data={data} />;
    case "comment":
      return <Comment data={data} />;
    default:
      return <div>NotificationView</div>;
  }
  // return (
  //     <div className={styles.notification_container}>
  //         <div className={styles.notification_image}>
  //             <img
  //                 className="rounded-circle"
  //                 src="https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png"
  //                 width={50}
  //                 height={50}
  //             />
  //         </div>
  //         <div>
  //             <div className={`mb-1 ${styles.notification_text}`}>

  //                 {data.body}
  //             </div>

  //             <div className={styles.notification_time}>
  //                 <TimeAgo date={data.create_ts} />
  //             </div>
  //         </div>
  //     </div>
  // )
}
export function Like({ data: { body, create_ts, detailPage } }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(detailPage);
  };
  return (
    <div className={styles.notification_container} onClick={handleClick}>
      <div className={styles.notification_image}>
        <img
          className="rounded-circle"
          src="https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png"
          width={50}
          height={50}
        />
      </div>
      <div>
        <div className={`mb-1 ${styles.notification_text}`}>{body}</div>
        <div className={styles.notification_time}>
          <TimeAgo date={create_ts} />
        </div>
      </div>
    </div>
  );
}

export function Comment({ data: { body, create_ts, detailPage } }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(detailPage);
  };
  return (
    <div className={styles.notification_container} onClick={handleClick}>
      <div className={styles.notification_image}>
        <img
          className="rounded-circle"
          src="https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png"
          width={50}
          height={50}
        />
      </div>
      <div>
        <div className={`mb-1 ${styles.notification_text}`}>{body}</div>
        <div className={styles.notification_time}>
          <TimeAgo date={create_ts} />
        </div>
      </div>
    </div>
  );
}
