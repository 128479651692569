import JobPossitionCard from "../../Components/global/JobPossitionCard/JobPossitionCard";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../../Components/global/JobPossitionCard/JobPossitionCard.css";
import axios from "../../Services/AxiosConfig";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import Pagination from "../../Components/global/Pagination/Pagination";
import Loader from "src/Components/UI/Loader/Loader";

function JobPossitionPage({ dataLimit = 10 }) {
  const [dataAPI, setData] = useState();
  const [totalData, setTotalData] = useState();
  const pages = Math.ceil(totalData / dataLimit);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);

  let endValue;
  if (totalData <= dataLimit) {
    endValue = totalData;
  } else {
    endValue = dataLimit - 1;
  }
  const [endIndex, setEndIndex] = useState(endValue);

  const remainingIndex = totalData % dataLimit;
  const business_id = useSelector(
    (state) => state.auth?.userDetail?.business_list?.[0]?.business_profile_id
  );
  const getAPIData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `user/job/posts/?b_id=${business_id}&index=${startIndex}&offset=${endIndex}`
      );
      // console.log(data);
      setTotalData(data.total_data);
      setData(data.data);
      // console.log("data from axios", dataAPI);
    } catch (error) {
      // console.log(error);
    }
  }, [business_id, startIndex, endIndex]);
  useEffect(() => {
    if (business_id !== undefined) {
      getAPIData();
    }
  }, [business_id, startIndex, getAPIData]);

  useEffect(() => {
    getAPIData();
  }, [startIndex, getAPIData]);

  async function goToNextPage() {
    // console.log("currentPage number", currentPage);

    if (currentPage + 1 > pages) {
    } else if (currentPage + 1 === pages) {
      setStartIndex(totalData - remainingIndex);
      setEndIndex(totalData - 1);
    } else if (startIndex <= totalData) {
      setEndIndex(totalData);
    } else {
      setCurrentPage((page) => page + 1);

      setStartIndex(startIndex + dataLimit);
      setEndIndex(endIndex + dataLimit);
    }
  }

  async function goToPreviousPage() {
    // console.log("current page on previous", currentPage);
    if (currentPage === 0) {
    } else if (currentPage + 1 === pages) {
      setCurrentPage((page) => page - 1);
      setStartIndex(startIndex - dataLimit);
      setEndIndex(endIndex - remainingIndex);
    } else if (startIndex !== 0) {
      setCurrentPage((page) => page - 1);

      setStartIndex(startIndex - dataLimit);
      setEndIndex(endIndex - dataLimit);
    }
  }

  const showToast = (success, type) => {
    toast.dismiss();
    if (type === "archive") {
      if (success) {
        toast.success("Job Archived Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error("Error! Job Archive Failed", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else if (type === "open") {
      toast.success("Job Opened Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (type === "close") {
      toast.success("Job Closed Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (type === "status") {
      toast.error("Error! Job Status Failed", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <Layout>
        <Container className="py-5">
          {totalData > 0 && dataAPI ? (
            <>
              <JobPossitionCard
                pages={pages}
                currentPage={currentPage}
                getData={dataAPI}
                getAPIData={getAPIData}
                showToast={showToast}
              />
              <div style={{ paddingTop: "10px" }}>
                <Pagination
                  goToNextPage={goToNextPage}
                  goToPreviousPage={goToPreviousPage}
                  totalData={totalData}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </div>
            </>
          ) : (
            <>
              {/* <Loader /> */}
              <div className="header-title">
                <span>Job Positions</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #E1E1E1",
                  borderRadius: "10px",
                  height: "100px",
                  marginTop: "10px",
                }}
              >
                <h2>No Job Found</h2>
              </div>
            </>
          )}
        </Container>
      </Layout>
    </>
  );
}

export default JobPossitionPage;
