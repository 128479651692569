import React, { useEffect, useState } from "react";
import EventList from "./components/EventList";
import { view } from "src/Services/AxiosConfig";

const EventListContainer = () => {
  const [eventlist, setEventList] = useState();
  const getEventList = async () => {
    try {
      const { data } = await view(`/fetch/event/list?my_events=true`);
      if (data.success) {
        console.log(data);
        setEventList(data.event_list);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEventList();
  }, []);
  return (
    <>
      <EventList eventlist={eventlist} />
    </>
  );
};

export default EventListContainer;
