import React, { useEffect, useRef, useState } from 'react';
import { auth, app } from '../../Services/firebaseClient';
import {
  getDatabase,
  query,
  ref,
  onValue,
  set,
  push,
  limitToLast,
  orderByValue,
} from 'firebase/database';
import ChatUI from './Chat.ui';
const database = getDatabase(app);

function Chat({ userDetail }) {
  const [chatId, setChatId] = useState('123');
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const uid = userDetail.core_id;
  const messagesRef1 = ref(database, `messages/${uid}/${chatId}`);
  const messagesRef12 = ref(database, `messages/${chatId}/${uid}`);
  const newMessageRef1 = push(messagesRef1);
  const newMessageRef2 = push(messagesRef12);

  // Get Chat Messages
  useEffect(() => {
    const messagesRef1 = ref(database, `messages/${uid}/${chatId}`);
    const getMessagesQuery = query(
      messagesRef1,
      orderByValue('createdAt'),
      limitToLast(25) || null
    );
    onValue(getMessagesQuery, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      const temp = Object.entries(data).map(([key, value]) => ({
        ...value,
        outgoing: value.uid === uid,
        id: key,
      }));

      setMessages(temp);
    });
  }, [uid, chatId]);

  // Send Message
  const sendMessage = async (text, setFormValue, dummy) => {
    set(newMessageRef1, {
      text: text,
      createdAt: Date.now(),
      uid,
    });
    set(newMessageRef2, {
      text: text,
      createdAt: Date.now(),
      uid,
    });

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  };

  // Get Previous Chats
  useEffect(() => {
    const previousChatsRef = ref(database, `messages/${uid}`);
    const getPreviousChatsQuery = query(
      previousChatsRef,
      orderByValue('createdAt')
    );
    onValue(getPreviousChatsQuery, (snapshot) => {
      const data = snapshot.val();
      const temp = Object.entries(data).map(([key, value]) => {
        const temp2 = Object.entries(value).map(([key2, value2]) => ({
          ...value2,
          messageId: key2,
        }));
        return {
          chatId: key,
          messages: temp2,
        };
      });

      setChats(temp);
    });
  }, []);

  return (
    <ChatUI
      chats={chats}
      chatId={chatId}
      messages={messages}
      setChatId={setChatId}
      sendMessage={sendMessage}
    />
  );
}
export default Chat;
