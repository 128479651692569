import React from "react";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";

const JoinCommunity = () => {
  return (
    <>
      <Layout />
    </>
  );
};

export default JoinCommunity;
