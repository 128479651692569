import React from "react";
import "./UpcomingEventCard.css";
import { Button, Container } from "react-bootstrap";
import wall from "src/Assets/images/BusinessDummyImages/image4.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faPauseCircle,
  faLocation,
  faCalendar,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import Pagination from "react-bootstrap/Pagination";
import bookmark from "src/Assets/images/icons/bookmark.png";

const UpcomingEventCard = (props) => {
  return (
    <>
      <Container className="p-0">
        <div className="grid-call grid-2">
          {props.getData &&
            props.getData.map((item, index) => (
              <div className="t-main">
                <div className="t-img">
                  <img src={wall} />
                </div>
                <div className="t-body pad">
                  <div className="t-subTitle u-subTitle">
                    <div className="u-st-1">
                      <span style={{ marginRight: "10px" }}>
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{ color: "#06c864" }}
                        />
                      </span>
                      <span>Jason Swart</span>
                    </div>
                    <div>
                      <span style={{ marginRight: "10px" }}>
                        <FontAwesomeIcon
                          icon={faPauseCircle}
                          style={{ color: "#06c864" }}
                        />
                      </span>

                      <span>Starts at $5</span>
                    </div>
                    <div className="bookmark-span ms-5">
                      <img src={bookmark} alt="" style={{ padding: "10px" }} />
                    </div>
                  </div>

                  <div className="t-title u-title">
                    <span>{item.title}</span>
                  </div>
                  <div>
                    <h4 style={{ fontFamily: "Circular Std" }}>
                      Ecommerce Growth meetup
                    </h4>
                  </div>

                  <div className="u-desc">
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna.
                    </p>
                  </div>
                  <div className="mb-2">
                    <span style={{ marginRight: "10px" }}>
                      <FontAwesomeIcon
                        icon={faLocation}
                        style={{ color: "#06c864" }}
                      />
                    </span>
                    <span style={{ color: "grey" }}>
                      Amazon Fashion Studio-London
                    </span>
                  </div>
                  <div className="mb-2">
                    <span style={{ marginRight: "10px" }}>
                      <FontAwesomeIcon
                        icon={faCalendar}
                        style={{ color: "#06c864" }}
                      />
                    </span>
                    <span style={{ color: "grey" }}>04-06 October,2022</span>
                  </div>
                  <div className="mb-2">
                    <span style={{ marginRight: "10px" }}>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        style={{ color: "#06c864" }}
                      />
                    </span>
                    <span style={{ color: "grey" }}>10:00am to 7:00pm</span>
                  </div>

                  <div className="u-button">
                    <button className="u-btn">
                      <span>View Details</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </Container>
    </>
  );
};

export default UpcomingEventCard;
