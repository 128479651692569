import React from 'react'
import './JobListingGridCard.css'
import fb from 'src/Assets/images/fb.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import dateFormat from 'dateformat'
import axios from 'src/Services/AxiosConfig'
import { ToastContainer, toast } from 'react-toastify'
import {
  faBookmark as RegularBookmark,
  faMoneyBill1,
  faClock
} from '@fortawesome/free-regular-svg-icons'
import { Link, useNavigate } from 'react-router-dom'

const JobListingGridCard = ({ item, getAPIData }) => {
  const navigate = useNavigate()

  const saveAPI = async (JobId) => {
    try {
      const payload = {
        post_id: JobId
      }
      const { data } = await axios.post('job/save/', payload)
      // console.log("save job api", data);
      await getAPIData()
      showToast(data.success, 'save', data.message)
    } catch (error) {
      // console.log(error);
      showToast(false, 'save', 'Network Error')
    }
  }

  const showToast = (success, type, message) => {
    toast.dismiss()

    if (success && type === 'save') {
      toast.success(message, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else if (!success && type === 'save') {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }

  return (
    <div className="grid-main">
      <div className="grid-body">
        <div className="grid-head">
          <div className="logo-details">
            <div className="grid-logo">
              <img src={fb} height={70} />
            </div>
            <div className="grid-heading">
              <h4>{item.job_title}</h4>
              <span>
                {' '}
                {item.business_name} <br /> Posted:{' '}
                {dateFormat(item.create_ts, 'dd/mm/yyyy')}
              </span>
            </div>
          </div>
          <div className="grid-buttons">
            {item.is_saved
              ? (
              <button
                className="button2 saved-job-btn"
                onClick={async () => {
                  await saveAPI(item.job_post_id)
                }}
              >
                <FontAwesomeIcon icon={RegularBookmark} />
              </button>
                )
              : (
              <button
                className="icon-button"
                onClick={async () => {
                  await saveAPI(item.job_post_id)
                }}
              >
                <FontAwesomeIcon
                  icon={RegularBookmark}
                  style={{ color: '#3F464E', fontSize: 'small' }}
                />
              </button>
                )}
            <div className="button-2">
              <button className="button-2">{item.job_employment_type}</button>
            </div>
          </div>
        </div>

        <div className="job-detail">
          <div className="grid-title">
            <h4>{item.title}</h4>

            <div className="job-detail">
              <div className="location" style={{ marginRight: '15px' }}>
                <span>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ color: '#37d1aa' }}
                  />{' '}
                </span>
                <span>{item.work_location}</span>
              </div>
              <div className="job-type" style={{ marginRight: '15px' }}>
                <span>
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ color: '#37d1aa' }}
                  />{' '}
                </span>
                <span>{item.job_employment_type}</span>
              </div>
              <div className="salary" style={{ marginRight: '15px' }}>
                <span>
                  <FontAwesomeIcon
                    icon={faMoneyBill1}
                    style={{ color: '#37d1aa' }}
                  />{' '}
                </span>
                <span>{item.salary_offer}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="job-desc">
          <p>{item.job_description}</p>
        </div>

        <div className="apply-button">
          <button
            className="button-3"
            onClick={() =>
              navigate(`/jobs/${item.job_post_id}`, {
                state: {
                  JobId: item.job_post_id
                }
              })
            }
          >
            Apply Now
          </button>
        </div>
      </div>
    </div>
  )
}

export default JobListingGridCard
