import React, { useState } from "react";
import RegisterEventPaid from "./components/RegisterEventPaid";
import RegisterEventPaidThree from "./components/RegisterEventPaidThree";
import RegisterEventPaidTwo from "./components/RegisterEventPaidTwo";
import { useFormik } from "formik";
import * as Yup from "yup";

const RegisterEventPaidContainer = () => {
  const [paidstep, setPaidstep] = useState("firstpaidstep");
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
  };
  const onSubmit = (values) => console.log("submit form", values);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Name is required"),
    lastName: Yup.string().required("Location is required"),
    email: Yup.string().required("Date is required"),
    confirmEmail: Yup.string().required("License no is required"),
  });

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  return (
    <>
      {paidstep === "firstpaidstep" && (
        <RegisterEventPaid setPaidstep={setPaidstep} />
      )}
      {paidstep === "secondpaidstep" && (
        <RegisterEventPaidTwo
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={errors}
          setPaidstep={setPaidstep}
        />
      )}
      {paidstep === "thirdpaidstep" && (
        <RegisterEventPaidThree
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={errors}
          setPaidstep={setPaidstep}
        />
      )}
    </>
  );
};

export default RegisterEventPaidContainer;
