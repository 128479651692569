import React, { useState } from "react";
import Notifications from "react-notifications-menu";
import { useSelector } from "react-redux";
import styles from "./Notification.module.css";
import "./Notification.css";
import TimeAgo from "react-timeago";
import ellipsiis from "text-ellipsis";
import { NotificationView, Like } from "./NotificationHelper";

function NotificationBell() {
  const { N, data } = useSelector((state) => state.notification);
  return (
    <Notifications
      data={data}
      // header={{
      //   title: "Notifications",
      //   option: {
      //     text: "View All",
      //     // onClick: (data) => {
      //     //   console.log("clicked on sigle notification");
      //     // },
      //   },
      // }}
      // cardOption={(data) => console.log(data)}
      notificationCard={NotificationView}
    />
  );
}

export default NotificationBell;
