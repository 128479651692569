import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import AxiosConfig from '../../../Services/AxiosConfig'
import BioUi from '../../global/PersonalProfile/Bio/Bio'
import {
  updateUserProfile,
  getUserProfile,
  getUserBio
} from '../../../Services/PersonalProfile/getProfileData'

function EditBio ({ navigateTo }) {
  const [ProfileData, setProfileData] = useState({})
  const [uploadImage, setuploadImage] = useState(null)
  const [BioData, setBioData] = useState()
  const [BioAlreadyExists, setBioExists] = useState(false)
  const userId = useSelector((state) => state.auth.userId)
  const navigate = useNavigate()
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSucces] = useState(false)

  useEffect(() => {
    (async function () {
      try {
        const profileData = await getUserProfile(userId)
        setProfileData(profileData.data)
        // console.log("profileData.user_bio", profileData.user_bio[0]);
        if (ProfileData?.user_bio) {
          setBioExists(true)
          setBioData(ProfileData?.user_bio)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [userId])

  useEffect(() => {
    (async function () {
      try {
        const bioData = await getUserBio(userId)
        // console.log("bioData", bioData.data);
        if (bioData?.data?.userbio_id) {
          setBioExists(true)
          setBioData(bioData.data)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [userId])

  const onSubmit = async (values, submitImage, setSubmitting) => {
    try {
      console.log('values', values)
      setSubmitting(true)
      setIsError(false)
      setIsSucces(false)
      // console.log(values.about);
      let imageLink
      if (!submitImage) {
        imageLink = values.profile_photo
      } else {
        const { data } = await AxiosConfig.post(
          process.env.REACT_APP_FILE_UPLOAD,
          submitImage
        )
        imageLink = data.data.media_file
      }
      const res = await updateUserProfile({
        ...ProfileData,
        user_number: values.phone,
        physical_address: values.address,
        gender: 'male',
        cover_photo: '',
        profile_photo: imageLink,
        first_name: values.first_name,
        last_name: values.last_name
      })
      if (res instanceof Error) throw new Error(res.message)
      if (BioAlreadyExists) {
        const response = await AxiosConfig.put('user/bio/create/', {
          about: values.about,
          phone: values.phone,
          address: values.address,
          first_name: values.first_name,
          last_name: values.last_name,
          profile_photo: imageLink,
          id: userId,
          type: 'personal',
          userbio_id: BioData.userbio_id
        })
      } else {
        const response = await AxiosConfig.post('user/bio/create/', {
          about: values.about,
          phone: values.phone,
          address: values.address,
          profile_photo: imageLink,
          id: userId,
          type: 'personal'
        })
        // console.log(response);
      }

      setSubmitting(false)
      setIsSucces(true)
      setIsError(false)
      // resetForm();
      if (navigateTo) {
        navigate(navigateTo)
      }
    } catch (error) {
      // console.log(error);
      setIsError(true)
      setIsSucces(false)
      setSubmitting(false)
    }
  }
  const bioSchema = Yup.object().shape({
    first_name: Yup.string().required('This Field is Required'),
    last_name: Yup.string().required('This Field is Required'),
    address: Yup.object().required('This Field is Required'),
    phone: Yup.string().required('This Field is Required')
  })
  return (
    <Formik
      enableReinitialize
      initialValues={{
        first_name: ProfileData?.first_name ? ProfileData.first_name : '',
        last_name: ProfileData?.last_name ? ProfileData.last_name : '',
        about: BioData?.about ? BioData.about : '',
        phone: BioData?.phone ? BioData.phone : '',
        address: BioData?.address ? BioData.address : '',
        profile_photo: ProfileData?.profile_photo
          ? ProfileData.profile_photo
          : ''
      }}
      validationSchema={bioSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (!uploadImage) {
          onSubmit(values, uploadImage, setSubmitting)
        } else {
          const submitImage = new FormData()
          submitImage.append('media_file', uploadImage)
          onSubmit(values, submitImage, setSubmitting)
        }
      }}
    >
      {({
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        setTouched,
        setFieldValue
      }) => (
        <BioUi
          ProfileData={ProfileData}
          onSubmit={onSubmit}
          isSuccess={isSuccess}
          isError={isError}
          navigateTo={`/profile/${userId}`}
          values={values}
          isSubmitting={isSubmitting}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setTouched={setTouched}
          setFieldValue={setFieldValue}
          setuploadImage={setuploadImage}
        />
      )}
    </Formik>
  )
}

export default EditBio
