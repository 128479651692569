import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import "./EventList.css";
import {
  faSearch,
  faCalendarDays,
  faLocation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpcomingEventCard from "src/Components/global/UpcomingEventCard/UpcomingEventCard";
import data from "./demo.json";
import bookmark from "src/Assets/images/icons/bookmark.png";

const EventList = () => {
  return (
    <>
      <Layout type="leftsidebar">
        <Container className="py-3">
          <div className="d-flex justify-content-between">
            <div className="header-title marBot">
              <span>My Event List</span>
            </div>
            <div>
              <button className="btn-event-sv">
                <div className="d-flex">
                  <span className="ms-1">
                    <img src={bookmark} alt="" />{" "}
                  </span>
                  <span className="sav-ev-txt">Saved Events</span>
                </div>
              </button>
            </div>
          </div>
          <Row>
            <Col md={12}>
              <div className="img-sc-event">
                <div className="main-div-img-sc-ev">
                  <div className="img-sect-txt margin">
                    <span style={{ marginRight: "10px" }}>
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        style={{ color: "#06c864" }}
                      />
                    </span>
                    <span className="txt-event">
                      Thursday, 13 January, 2022
                    </span>
                    <span style={{ marginRight: "10px", marginLeft: "10px" }}>
                      <FontAwesomeIcon
                        icon={faLocation}
                        style={{ color: "#06c864" }}
                      />
                    </span>
                    <span className="txt-event">
                      Amazon Fashion Studio-London
                    </span>
                  </div>
                  <div className="img-sect-txt2 margin">
                    <span style={{ marginRight: "10px" }}>
                      <FontAwesomeIcon
                        icon={faLocation}
                        style={{ color: "#06c864" }}
                      />
                    </span>
                    <span className="txt-event">
                      Amazon Fashion Studio-London
                    </span>
                  </div>
                  <div>
                    <h3
                      className="text-white margin"
                      style={{ fontFamily: "Circular Std", fontSize: "24px" }}
                    >
                      Ecommerce Growth meetup
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between para-btn">
                    <p
                      className="text-white margin"
                      style={{ fontSize: "14px" }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing
                      adipisicing adipisicing{" "}
                      <span style={{ display: "block" }}>
                        {" "}
                        elit. Fuga culpa recusandae quae libero error ducimus?
                      </span>
                    </p>
                    <Button className="detail-btn-ev me-3">View Details</Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="header-title marBot mt-5">
                <span>Join Upcoming Events</span>
              </div>
              <UpcomingEventCard getData={data} />
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export default EventList;
