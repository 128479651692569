import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import Select from "react-select";
import "./CreateEvent.css";
import galleryicon from "../../../../Assets/images/icons/gallery.png";

const CreateEvent = ({
  handleSubmit,
  values,
  errors,
  handleChange,
  touched,
  formik,
  services,
}) => {
  return (
    <>
      {console.log(values, "hhh")}
      <Layout type="leftsidebar">
        <Container className="py-5">
          <div className="header-title">
            <span>Create Event</span>
          </div>

          <div className="create-event-main shadow mt-2">
            <form
              id="eventForm"
              className="tooltip-end-bottom"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col lg={12} md={12}>
                  <Row>
                    <Col md={6}>
                      <label className="label">Title</label>

                      <Form.Control
                        name="title"
                        className="fields-contact"
                        type="text"
                        placeholder="Ecommerce Conference"
                        onChange={handleChange}
                        value={values.title}
                      />

                      {errors.title && touched.title ? (
                        <div className="text-danger">{errors.title}</div>
                      ) : null}
                    </Col>

                    <Col md={6}>
                      <label className="label">Location</label>

                      <Form.Control
                        name="location"
                        className="fields-contact"
                        type="location"
                        placeholder="Location"
                        // onChange={handleChange}
                        // value={values.location}
                      />

                      {errors.location && touched.location ? (
                        <div className="text-danger">{errors.location}</div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <label className="label mt-3">Date</label>

                      <Form.Control
                        name="datetime"
                        // className="fields-contact"

                        type="date"
                        placeholder="Community Name"
                        // onChange={handleChange}
                        // value={values.datetime}
                      />

                      {errors.datetime && touched.datetime ? (
                        <div className="text-danger">{errors.datetime}</div>
                      ) : null}
                    </Col>

                    <Col md={6}></Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className="d-flex flex-column mt-3">
                        <label className="label">Category</label>

                        <Select
                          classNamePrefix="react-select"
                          placeholder="Category"
                          options={services}
                          // isMulti
                          name="category"
                          value={values.services}
                          onChange={(e) => {
                            formik.setFieldValue("category", e.value);
                          }}
                        />
                        {errors.category && touched.category ? (
                          <div className="text-danger">{errors.category}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <label className="label mt-3">Description</label>

                      <Form.Control
                        name="description"
                        as="textarea"
                        rows={6}
                        placeholder="Description"
                        value={values.description}
                        onChange={handleChange}
                      />

                      {errors.description && touched.description ? (
                        <div className="text-danger">{errors.description}</div>
                      ) : null}
                    </Col>
                  </Row>
                </Col>

                <Col>
                  {/* <div className="img-upload-cre-coomunity mt-5">
                    <div className="img-upl">
                      <img
                        src={galleryicon}
                        alt="gallery"
                        style={{ padding: "18px" }}
                      />
                    </div>

                    <div>
                      <button className="img-upl-btn">Upload image</button>
                    </div>
                  </div> */}
                </Col>
              </Row>
              <button className="mt-5 submit-event-btn" type="submit">
                Create Event
              </button>
            </form>
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default CreateEvent;
