import React from 'react'
import Header from 'src/Components/global/Header/Header'
import Footer from 'src/Components/global/Footer/Footer.jsx'

import './PopularJobs.css'
import { Col, Container, Row, Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faCircle } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import Cleaning from 'src/Assets/images/home/image-01.jpg'
import Electrican from 'src/Assets/images/home/image-02.jpg'
import Package from 'src/Assets/images/home/image-03.jpg'
import Painter from 'src/Assets/images/home/image-04.jpg'

import ProviderImg from 'src/Assets/images/jobs/img-01.png'
const PopularJobs = () => {
  const largeScreen = window.innerWidth > 767
  // console.log("mobile", mobilescreen);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  }
  const jobs = [
    { img: Painter, title: 'Painter' },
    { img: Electrican, title: 'Electrican' },
    { img: Package, title: 'Logistics ' },
    { img: Cleaning, title: 'Building Cleaning' },
    { img: Painter, title: 'Painter' },
    { img: Electrican, title: 'Electrican' },
    { img: Package, title: 'Logistics ' },
    { img: Cleaning, title: 'Building Cleaning' }
  ]

  const alljobs = [
    { title: 'Academic Writing' },
    { title: 'Advanced English Lessons' },
    { title: 'Affordable Attorneys ' },
    { title: 'Alcohol Compliance jobs' },
    { title: 'App Developers' },
    { title: 'Apple Repair' },
    { title: 'Architects ' },
    { title: 'Accountant' },
    { title: 'Ada Attorneys' },
    { title: 'Advertising Consultants' },
    { title: 'Android Developers' },
    { title: 'Animators' },
    { title: 'Art jobs' },
    { title: 'Book Editors' },
    { title: 'Bail Bonds' },
    { title: 'Book Publishers' },
    { title: 'Branding Consultants' },
    { title: 'Brand Management' }
  ]
  return (
    <>
      <Header />
      <section className="jobs-banner" id="service_banner_id">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={10}>
              <div>
                <h1 className={'fs-140 recoleta-font text-white fw-normal'}>
                  Find the best “Job”
                </h1>
              </div>
            </Col>
            <Col md={10}>
              <div className="search-bar">
                <Form>
                  <Form.Group controlId="keyword_id">
                    <Form.Control
                      size="lg"
                      className="fs-16"
                      type="text"
                      placeholder="Search service,keyword,vendor "
                    />
                  </Form.Group>
                  <Form.Group controlId="location_id">
                    <Form.Control
                      size="lg"
                      className="fs-16"
                      type="text"
                      placeholder="Enter Location"
                    />
                  </Form.Group>
                  <div>
                    <Button className="gen-btn btn-lg" type="submit">
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>

            <Col md={10} className="mt-4">
              <p className="text-white">Popular Searches</p>
              <div className="buttons-group d-flex gap-2">
                <Button className="gen-btn btn-lg fs-14" type="submit">
                  <FontAwesomeIcon icon={faMagnifyingGlass} /> Electrician
                </Button>
                <Button className="gen-btn btn-lg fs-14" type="submit">
                  <FontAwesomeIcon icon={faMagnifyingGlass} /> Programmer
                </Button>
                <Button className="gen-btn btn-lg fs-14" type="submit">
                  <FontAwesomeIcon icon={faMagnifyingGlass} /> Legal Assistant
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="popular-searches-carousel s-p">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="text-dark recoleta-font fs-140 mb-minus">
                <span>Popular </span> <span>jobs</span>
              </h2>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="align-items-center">
            <Col md={12}>
              <Carousel
                arrows={false}
                loop={true}
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={3000}
                dotListClass="custom-dot-list-style"
                // itemClass="carousel-item-padding-40-px"
                removeArrowOnDeviceType={['tablet', 'mobile']}
                infinite={true}
                showDots={false}
                centerMode={largeScreen}
              >
                {jobs.map((item, index) => {
                  return (
                    <div key={index} className="p-md-3 p-0">
                      <img
                        alt="company "
                        src={item.img}
                        className="img-fluid"
                      />
                      <p className="text-dark secondary-font fst-normal fs-24 mt-2 svg-style">
                        <FontAwesomeIcon icon={faCircle} /> {item.title}
                      </p>
                    </div>
                  )
                })}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="flush-provider beige-color s-p">
        <Container>
          <Row>
            <Col md={6}>
              <img alt="provider" src={ProviderImg} className="img-fluid" />
            </Col>
            <Col md={6}>
              <h2 className="text-dark recoleta-font fs-140">
                <span>Flush </span> <span>Provides</span>
              </h2>
              <div className="svg-style">
                <p className="fs-24 border p-3 width-x radius-05 border-color">
                  <FontAwesomeIcon icon={faCircle} /> All in one place.
                </p>
                <p className="fs-24 border p-3 width-x radius-05 border-color">
                  <FontAwesomeIcon icon={faCircle} /> Largest Busniess
                  Community.
                </p>
                <p className="fs-24 border p-3 width-x radius-05 border-color">
                  <FontAwesomeIcon icon={faCircle} /> Biggest Professional
                  Market.
                </p>
                <p className="fs-24 border p-3 width-x radius-05 border-color">
                  <FontAwesomeIcon icon={faCircle} /> Local & Global jobs.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-jobs s-p">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="text-dark recoleta-font fs-140">
                <span>All </span> <span>jobs</span>
              </h2>
            </Col>
          </Row>
          <Row>
            {alljobs.map((item, index) => {
              return (
                <Col md={4} className="mb-3">
                  <div key={index} className="svg-style">
                    <Link
                      to="#"
                      className="text-dark secondary-font fst-normal fs-24 mt-2"
                    >
                      <FontAwesomeIcon icon={faCircle} /> {item.title}
                    </Link>
                  </div>
                </Col>
              )
            })}

            <Col md={12} className="text-center mt-5">
              <Link to="#" className="secondary-color fs-24 ">
                Load More
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default PopularJobs
