import React from "react";

const RegisterEventPaidFour = () => {
  return (
    <>
      <span>
        {" "}
        <span style={{ color: "#06c864" }}>Sign in</span> to your PayPal account
        to complete your purchase.
      </span>
    </>
  );
};

export default RegisterEventPaidFour;
