import React, { useState, useEffect } from "react";
import "./SharePost.scss";
import { Button, Modal } from "react-bootstrap";
import PostUserImg from "src/Assets/images/postUser.png";
import { Formik } from "formik";
import { add } from "../../../../Services/AxiosConfig";
import { ViewPost } from "../view/ViewPost";
import * as Yup from "yup";

const SharePost = ({ show, close, postDetail, reloadPosting, userDetail }) => {
  // console.log("userDetail", userDetail);
  function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  }

  const userDeatil = {
    name: userDetail?.first_name
      ? `${userDetail?.first_name} ${userDetail?.last_name}`
      : "Name",
    img: validateUrl(userDetail?.profile_photo)
      ? userDetail?.profile_photo
      : PostUserImg,
    job: userDetail?.work_history[0]
      ? `${userDetail?.work_history[0]?.company_name} | ${userDetail?.work_history[0]?.title}`
      : " Robel LLC | Painting Service Provider",
  };

  const CreatePostHandler = async (values, setSubmitting) => {
    const createPostApi = add("user/add/post/", values)
      .then((rsp) => {
        reloadPosting();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const CreatePostSchema = Yup.object().shape({
    post_description: Yup.string()
      .min(2, "Too Short!")
      .max(700, "Too Long!")
      .required("Required"),
  });
  return (
    <>
      <section>
        <Modal
          show={show}
          onHide={close}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Formik
            initialValues={{
              post_description: "",
              post_media: null,
              shared_post_id: postDetail.post_id,
              post_tags: "6e94ecb0-7cc8-4575-8f83-0d6e7912fa88",
              is_event: false,
            }}
            validationSchema={CreatePostSchema}
            onSubmit={(values, { setSubmitting }) => {
              CreatePostHandler(values, setSubmitting);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Share Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex align-items-center">
                    <div className="p-2">
                      <img
                        className="rounded-circle"
                        src={userDeatil.img}
                        width="50"
                        height="50"
                      />
                    </div>
                    <div className="p-2">
                      <div className=" fs-20  dt-c fw-bold">
                        {userDeatil.name}
                      </div>
                      <div className="fs-14 dst-c ">{userDeatil.job}</div>
                    </div>
                  </div>
                  <div>
                    <textarea
                      name="post_description"
                      className="form-control border-0"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.post_description}
                      placeholder="Describe yourself here..."
                      rows={5}
                    ></textarea>
                    {errors.post_description &&
                      touched.post_description &&
                      errors.post_description}
                  </div>
                  <ViewPost postDetail={postDetail} />

                  <hr />
                  <div className="d-flex justify-content-end align-items-center">
                    <div>
                      <Button
                        variant="secondary"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={close}
                      >
                        Share Post
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </form>
            )}
          </Formik>
        </Modal>
      </section>
    </>
  );
};

export default SharePost;
