import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./VerticalTextScrollStyles.css";

const VerticalTextScroll = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const halfwayIndex = Math.ceil(data.length / 2);
  const itemHeight = 70;
  const shuffleThreshold = halfwayIndex * itemHeight;
  const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex) => {
    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight;
      } else {
        return -(data.length + activeIndex - itemIndex) * itemHeight;
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data.length - (activeIndex - itemIndex)) * itemHeight;
      }
      return -(activeIndex - itemIndex) * itemHeight;
    }
  };

  const handleClick = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === "next") {
        if (prevIndex + 1 > data.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return data.length - 1;
      }

      return prevIndex - 1;
    });
  };

  useEffect(() => {
    const Interval = setInterval(function () {
      handleClick("next");
    }, 3000);

    return () => {
      clearInterval(Interval);
    };
  }, [activeIndex]);

  return (
    <div className="container">
      <section className="outer-container">
        <div className="carousel-wrapper">
          <div className="carousel">
            <div className="slides">
              <div className="carousel-inner">
                {data.map((item, i) => (
                  <button
                    type="button"
                    onClick={() => setActiveIndex(i)}
                    className={cn(
                      `carousel-item ${
                        activeIndex === i
                          ? "activedDiv"
                          : determinePlacement(i) === 140 ||
                            determinePlacement(i) === -140
                          ? "nearestDeactivedDiv"
                          : "deactivedDiv"
                      }`,
                      {
                        active: activeIndex === i,
                        visible:
                          Math.abs(determinePlacement(i)) <=
                          visibleStyleThreshold,
                      }
                    )}
                    key={item.id}
                    style={{
                      transform: `translateY(${determinePlacement(i)}px)`,
                    }}
                  >
                    <span
                      className={
                        activeIndex === i &&
                        item.introline.includes("Build") &&
                        "buildQuery"
                      }
                    >
                      {item.introline}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

VerticalTextScroll.propTypes = {
  data: PropTypes.array.isRequired,
};

export default VerticalTextScroll;
