import imgIcon from "src/Assets/svg/arch.svg";
import imgPlum from "src/Assets/svg/plum.svg";
import imgconstr from "src/Assets/svg/constr.svg";
import imgelect from "src/Assets/svg/elect.svg";

export const categorydata = [
  {
    id: "1",
    name: "Plumber",
    icon: imgIcon,
  },
  {
    id: "2",
    name: "Painting",
    icon: imgPlum,
  },
  {
    id: "3",
    name: "Roffing",
    icon: imgconstr,
  },
  {
    id: "4",
    name: "Electricians",
    icon: imgelect,
  },
  {
    id: "5",
    name: "Architectures",
    icon: imgIcon,
  },
];
