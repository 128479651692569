import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import { Container, Row, Col } from "react-bootstrap";
import "./RegisterEventFree.css";
import closeup from "src/Assets/images/close-up-worker.png";
import Form from "react-bootstrap/Form";

const RegisterEventFreeTwo = ({
  handleSubmit,
  handleChange,
  values,
  touched,
  errors,
}) => {
  return (
    <>
      <Layout>
        <div className="p-5">
          <Container className="main-card-reg shadow">
            <Row>
              <Col lg={9} md={9}>
                <div className="mt-3">
                  <span
                    style={{
                      fontSize: "26px",
                      lineHeight: "40px",
                      fontFamily: "Circular Std",
                      padding: "15px",
                    }}
                  >
                    Register Summary
                  </span>
                  <hr />
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    style={{
                      fontSize: "22px",
                      lineHeight: "40px",
                      fontFamily: "Circular Std",
                      padding: "15px",
                    }}
                  >
                    Contact Information
                  </span>
                </div>
                <div>
                  <span style={{ color: "#06c864", padding: "15px" }}>
                    Login{" "}
                    <span style={{ color: "#666666" }}>
                      a for fast experience
                    </span>
                  </span>
                </div>
                <div className="mt-5" style={{ padding: "15px" }}>
                  <Row>
                    <Col md={6}>
                      <label className="label">First Name</label>

                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Ecommerce Conference"
                        onChange={handleChange}
                        value={values.title}
                        size="lg"
                      />
                    </Col>
                    <Col md={6}>
                      <label className="label">Last Name</label>

                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Ecommerce Conference"
                        onChange={handleChange}
                        value={values.title}
                        size="lg"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label className="label">Email</label>
                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Email"
                        onChange={handleChange}
                        value={values.title}
                        size="lg"
                      />
                    </Col>
                    <Col md={6}>
                      <label className="label">Confirm Email</label>

                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Ecommerce Conference"
                        onChange={handleChange}
                        value={values.title}
                        size="lg"
                      />
                    </Col>
                  </Row>
                </div>
                <hr />
                <div>
                  <div
                    className="d-flex flex-column"
                    style={{ padding: "15px" }}
                  >
                    <div className="d-flex">
                      <Form.Check type="checkbox" />
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#3F464E",
                          fontFamily: "Roboto",
                          margin: "4px",
                        }}
                      >
                        Keep me updated on more events and news from this event
                        organizer.
                      </span>
                    </div>
                    <div className="d-flex mt-1">
                      <Form.Check type="checkbox" />
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#3F464E",
                          fontFamily: "Roboto",
                          margin: "4px",
                        }}
                      >
                        I <span style={{ color: "#06c864" }}>agree</span> to the
                        terms & conditions
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="reg-free-btnn">Register</button>
                </div>
              </Col>
              <Col lg={3} md={3} className="reg-ev-col">
                <div className="img-sec-reg">
                  <img
                    src={closeup}
                    alt="dd"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
                <div>
                  <div>
                    <span
                      style={{
                        fontSize: "22px",
                        lineHeight: "40px",
                        fontFamily: "Circular Std",
                        padding: "15px",
                      }}
                      className="mt-4"
                    >
                      Order Summary
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <span style={{ fontSize: "12px", padding: "15px" }}>
                      1x Annual Business Conference
                    </span>
                    <span style={{ fontSize: "12px", padding: "15px" }}>
                      $0
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-4">
                    <span
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        padding: "15px",
                      }}
                    >
                      Total:
                    </span>
                    <span
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        padding: "15px",
                      }}
                    >
                      $0
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default RegisterEventFreeTwo;
