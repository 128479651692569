import React, { useEffect, useState } from "react";
import CommunityDetail from "./components/CommunityDetail";

const CommunityDetailsContainer = () => {
  return (
    <>
      <CommunityDetail />
    </>
  );
};

export default CommunityDetailsContainer;
