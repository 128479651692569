import React from "react";
import BookmarkEvent from "./components/BookmarkEvent";

const BookmarkEventContainer = () => {
  const data = [
    {
      id: "1",
      title: "How to sell your services",
      location: "Lahore, Pakistan",
    },
    {
      id: "2",
      title: "Sales & Customer Care Management",
      location: "Lahore, Pakistan",
    },
    {
      id: "3",
      title: "How to sell your services",
      location: "Lahore, Pakistan",
    },
    {
      id: "4",
      title: "Introducing Advance Electrical Tools",
      location: "Lahore, Pakistan",
    },
    {
      id: "5",
      title: "Introducing Advance Electrical Tools",
      location: "Lahore, Pakistan",
    },
    {
      id: "6",
      title: "Introducing Advance Electrical Tools",
      location: "Lahore, Pakistan",
    },
  ];
  return (
    <>
      <BookmarkEvent data={data} />
    </>
  );
};

export default BookmarkEventContainer;
