import React, { useEffect, useRef } from "react";
import Header from "src/Components/global/Header/Header";
import Footer from "src/Components/global/Footer/Footer.jsx";

import "./SearchServices.css";
import { Col, Container, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faCircle } from "@fortawesome/free-solid-svg-icons";

import { Link, useNavigate } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Cleaning from "src/Assets/images/home/image-01.jpg";
import Electrican from "src/Assets/images/home/image-02.jpg";
import Package from "src/Assets/images/home/image-03.jpg";
import Painter from "src/Assets/images/home/image-04.jpg";

import ProviderImg from "src/Assets/images/popular-searches/img-01.png";

import { Formik, Form } from "formik";

import anime from "animejs/lib/anime.es.js";

const SearchServices = () => {
  const services = [
    { img: Painter, title: "Painter" },
    { img: Electrican, title: "Electrican" },
    { img: Package, title: "Logistics " },
    { img: Cleaning, title: "Building Cleaning" },
    { img: Painter, title: "Painter" },
    { img: Electrican, title: "Electrican" },
    { img: Package, title: "Logistics " },
    { img: Cleaning, title: "Building Cleaning" },
  ];

  const allservices = [
    { title: "Academic Writing" },
    { title: "Advanced English Lessons" },
    { title: "Affordable Attorneys " },
    { title: "Alcohol Compliance Services" },
    { title: "App Developers" },
    { title: "Apple Repair" },
    { title: "Architects " },
    { title: "Accountant" },
    { title: "Ada Attorneys" },
    { title: "Advertising Consultants" },
    { title: "Android Developers" },
    { title: "Animators" },
    { title: "Art Services" },
    { title: "Book Editors" },
    { title: "Bail Bonds" },
    { title: "Book Publishers" },
    { title: "Branding Consultants" },
    { title: "Brand Management" },
  ];

  const navigate = useNavigate();

  const scrollContainer = useRef(null);
  const scrollText = useRef(null);

  useEffect(() => {
    // Mouse wheel
    const computedStyle = getComputedStyle(scrollContainer.current);
    const scrollWidth = parseInt(computedStyle.getPropertyValue("width"));
    scrollContainer.current.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      // if (scrollContainer.current.scrollLeft === 0) {
      //   window.scrollBy(0, -100);
      // }
      // if (scrollContainer.current.scrollLeft >= scrollWidth * 0.9) {
      //   window.scrollBy(0, 100);
      // }
      anime({
        targets: scrollText.current,
        scrollLeft: (scrollContainer.current.scrollLeft +=
          (evt.deltaY + evt.deltaX) * 5),

        easing: "linear",
        duration: 400,
      });
      anime({
        targets: scrollText.current,
        translateX: scrollContainer.current.scrollLeft * 0.6,
        easing: "linear",
        duration: 250,
      });
      // scrollText.current.style.left =
      //   scrollContainer.current.scrollLeft * 0.7 + 'px';
    });
  }, []);
  return (
    <>
      <Header />
      <section className="services-banner" id="service_banner_id">
        <Container>
          <Row className="bannerRow align-items-center justify-content-around">
            <div className="w-75">
              <Col md={10}>
                <div>
                  <h1 className={"fs-90 recoleta-font text-white fw-normal"}>
                    Find the best fit
                  </h1>
                </div>
              </Col>
              <Formik
                initialValues={{
                  searchKeyword: "",
                  searchLocation: "",
                }}
                onSubmit={async (values) => {
                  try {
                    // console.log(values);

                    setTimeout(() => {
                      navigate(
                        `/search-services-list/search=${values.searchKeyword}`
                      );
                    }, 500);
                  } catch (error) {
                    console.log("ERROR: ", error.message);
                  } finally {
                  }
                }}
              >
                {({ handleChange, handleBlur, values, setFieldValue }) => (
                  <Col md={8}>
                    <div className="search-bar">
                      <Form className="d-flex justify-content-between">
                        <div className="flex-grow-1">
                          <input
                            size="lg"
                            type="text"
                            className="fs-16 form-control"
                            style={{ border: "none" }}
                            placeholder="Search service,keyword,vendor"
                            name="searchKeyword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.searchKeyword}
                          />
                        </div>
                        <div>
                          <Button className="gen-btn btn-lg" type="submit">
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                )}
              </Formik>

              <Col md={10} className="mt-4">
                <p className="text-white">Popular Searches</p>
                <div className="buttons-group d-flex flex-wrap gap-2">
                  <Button
                    className="gen-btn btn-lg fs-14"
                    type="submit"
                    onClick={() =>
                      navigate(`/search-services-list/search=roofing`)
                    }
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Roofing
                  </Button>
                  <Button
                    className="gen-btn btn-lg fs-14"
                    type="submit"
                    onClick={() =>
                      navigate(`/search-services-list/search=painting`)
                    }
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Painting
                  </Button>
                  <Button
                    className="gen-btn btn-lg fs-14"
                    type="submit"
                    onClick={() =>
                      navigate(`/search-services-list/search=carpentry`)
                    }
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} /> Carpentry
                  </Button>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>

      <section className="FindVendor s-p">
        <div>
          <h2
            className="text-dark recoleta-font fs-90 mb-80 horizontal-scroll-text position-relative"
            ref={scrollText}
          >
            <span>Popular</span> <span>Services</span>
          </h2>
          <div className="horizontal-scroll-container" ref={scrollContainer}>
            {services.map((item, index) => {
              return (
                <div
                  key={index}
                  className="cursorPointer p-3 horizontal-scroll-item"
                  style={{
                    width: "400px",
                    flexBasis: "400px",
                    position: "relative",
                    flexShrink: 0,
                  }}
                  onClick={() =>
                    navigate(`/search-services-list/search=${item.title}`)
                  }
                >
                  <img src={item.img} className="img-fluid" />
                  <p className="text-dark secondary-font fst-normal fs-24 mt-2">
                    <FontAwesomeIcon icon={faCircle} /> {item.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="flush-provider beige-color s-p">
        <Container>
          <Row>
            <Col md={6}>
              <img alt="Provider" src={ProviderImg} className="img-fluid" />
            </Col>
            <Col md={6}>
              <h2 className="text-dark recoleta-font fs-140">
                <span>Flush </span> <span>Provides</span>
              </h2>
              <div className="svg-style">
                <p className="fs-24 border p-3 width-x radius-05 border-color">
                  <FontAwesomeIcon icon={faCircle} /> All in one place.
                </p>
                <p className="fs-24 border p-3 width-x radius-05 border-color">
                  <FontAwesomeIcon icon={faCircle} /> Largest Busniess
                  Community.
                </p>
                <p className="fs-24 border p-3 width-x radius-05 border-color">
                  <FontAwesomeIcon icon={faCircle} /> Biggest Professional
                  Market.
                </p>
                <p className="fs-24 border p-3 width-x radius-05 border-color">
                  <FontAwesomeIcon icon={faCircle} /> Local & Global Services.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="all-services s-p">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="text-dark recoleta-font fs-140">
                <span>All </span> <span>Services</span>
              </h2>
            </Col>
          </Row>
          <Row>
            {allservices.map((item, index) => {
              return (
                <Col md={4} className="mb-3">
                  <div key={index} className="svg-style">
                    <Link
                      to="#"
                      className="text-dark secondary-font fst-normal fs-24 mt-2"
                    >
                      <FontAwesomeIcon icon={faCircle} /> {item.title}
                    </Link>
                  </div>
                </Col>
              );
            })}

            <Col md={12} className="text-center mt-5">
              <Link to="#" className="secondary-color fs-24 ">
                Load More
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="home-footer">
        <Footer />
      </div>
    </>
  );
};

export default SearchServices;
