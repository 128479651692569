import { useEffect, useState } from "react";
import { view } from "../Services/AxiosConfig";

export default function usePostSearch(startPage, endPage, resetPost) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const fetchPost = async () => {
    setLoading(true);
    setError(false);
    const apiRsp = await view(
      `/user/get/post/${startPage}/${endPage}/?my_posts=false&post_tag=6e94ecb0-7cc8-4575-8f83-0d6e7912fa88`
    )
      .then((res) => {
        const uniqueIds = [];
        const arr = [...posts, ...res.data.posts_list];
        const unique = arr.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.post_id);

          if (!isDuplicate) {
            uniqueIds.push(element.post_id);
            return true;
          }
          return false;
        });
        setPosts([...unique]);
        setHasMore(res.data.total_posts);
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error ", e);
        setError(true);
      });
  };

  const fetchPostOnReset = async () => {
    if (resetPost) {
      setLoading(true);
      setError(false);

      const apiRsp = await view(
        `/user/get/post/0/5/?my_posts=false&post_tag=6e94ecb0-7cc8-4575-8f83-0d6e7912fa88`
      )
        .then((res) => {
          setPosts(res.data.posts_list);
          setHasMore(res.data.total_posts);
          setLoading(false);
        })
        .catch((e) => {
          console.log("Error ", e);
          setError(true);
        });
    }
  };

  useEffect(() => {
    fetchPost();
  }, [endPage]);

  useEffect(() => {
    fetchPostOnReset();
  }, [resetPost]);

  return { loading, error, posts, hasMore };
}
