import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./FindVendor.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import Cleaning from "src/Assets/images/home/image-01.jpg";
import Electrican from "src/Assets/images/home/image-02.jpg";
import Package from "src/Assets/images/home/image-03.jpg";
import Painter from "src/Assets/images/home/image-04.jpg";
import anime from "animejs/lib/anime.es.js";
const FindVendor = () => {
  const scrollContainer = useRef(null);
  const scrollText = useRef(null);
  const navigate = useNavigate();
  const services = [
    { img: Painter, title: "Painter" },
    { img: Electrican, title: "Electrican" },
    { img: Package, title: "Logistics " },
    { img: Cleaning, title: "Building Cleaning" },
    { img: Painter, title: "Painter" },
    { img: Electrican, title: "Electrican" },
    { img: Package, title: "Logistics " },
    { img: Cleaning, title: "Building Cleaning" },
  ];
  useEffect(() => {
    // Mouse wheel
    const computedStyle = getComputedStyle(scrollContainer.current);
    const scrollWidth = parseInt(computedStyle.getPropertyValue("width"));
    scrollContainer.current.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      // if (scrollContainer.current.scrollLeft === 0) {
      //   window.scrollBy(0, -100);
      // }
      // if (scrollContainer.current.scrollLeft >= scrollWidth * 0.9) {
      //   window.scrollBy(0, 100);
      // }
      anime({
        targets: scrollText.current,
        scrollLeft: (scrollContainer.current.scrollLeft +=
          (evt.deltaY + evt.deltaX) * 5),

        easing: "linear",
        duration: 400,
      });
      anime({
        targets: scrollText.current,
        translateX: scrollContainer.current.scrollLeft * 0.6,
        easing: "linear",
        duration: 250,
      });
      // scrollText.current.style.left =
      //   scrollContainer.current.scrollLeft * 0.7 + 'px';
    });
  }, []);
  return (
    <>
      <section className="FindVendor s-p">
        <div>
          <h2
            className="text-dark recoleta-font fs-90 mb-80 horizontal-scroll-text position-relative"
            ref={scrollText}
          >
            <span>Find</span> <span>Vendor</span>
          </h2>
          <div className="horizontal-scroll-container" ref={scrollContainer}>
            {services.map((item, index) => {
              return (
                <div
                  key={index}
                  className="cursorPointer p-3 horizontal-scroll-item"
                  style={{
                    width: "400px",
                    flexBasis: "400px",
                    position: "relative",
                    flexShrink: 0,
                  }}
                  onClick={() =>
                    navigate(`/search-services-list/search=${item.title}`)
                  }
                >
                  <img src={item.img} className="img-fluid" />
                  <p className="text-dark secondary-font fst-normal fs-24 mt-2">
                    <FontAwesomeIcon icon={faCircle} /> {item.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default FindVendor;
