import React from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import './Register.css'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

import CarouselComponent from './Components/CarouselComponent'
import Logo from 'src/Assets/images/register/logo.png'

import { Formik, Form } from 'formik'
import TextField from './Components/TextField'

import AxiosConfig from '../../Services/AxiosConfig'
import * as Yup from 'yup'
import FileUpload from 'src/Components/global/Dropzone/Dropzone'
const RegisterPortfolio = () => {
  const navigate = useNavigate()

  const portfolioSchema = Yup.object().shape({
    portfolio: Yup.array().of(
      Yup.object().shape({
        portfolio_label: Yup.string().required('This Field is Required'),
        description: Yup.string().required('This Field is Required'),
        portfolio_media_url: Yup.array().length(1, 'This Field is Required')
      })
    )
  })

  const onSubmit = async (values, setSubmitting) => {
    const temp = {
      ...values,
      belongs_to_business: ''
    }
    try {
      setSubmitting(true)
      const response = await AxiosConfig.post('portfolio/add/details/', [temp])
      // console.log(res);
      setSubmitting(false)
      if (response.data.success === true) {
        navigate('/register/skills')
      }
    } catch (error) {
      console.log(error)
      setSubmitting(false)
    }
  }

  return (
    <>
      <section className="register" id="register_id">
        <Container fluid>
          <Row>
            <div className="grid--col-02 px-0">
              <div className="align-items-center p-4 d-lg-block d-none">
                <div>
                  <img src={Logo} alt="logo" className="img-fluid logo-width" />
                </div>
                <div>
                  <CarouselComponent />
                </div>
              </div>

              <div className="bg-form-style ">
                <div className="d-flex min-vh-100 flex-column align-items-center justify-content-center">
                  <div className="w-75 mb-3">
                    <h2 className="text-start primary-font primary-color fs-59 fw-bold">
                      Portfolio
                    </h2>
                  </div>
                  <Formik
                    initialValues={{
                      portfolio_label: '',
                      portfolio_details: '',
                      portfolio_media_url: []
                    }}
                    validationSchema={portfolioSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      onSubmit(values, setSubmitting)
                      //   }
                    }}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <Form className="w-75">
                        <Row>
                          <Col md={12}>
                            <TextField
                              label="Title"
                              name="portfolio_label"
                              placeholder="Title"
                              type="text"
                            />
                          </Col>
                          <Col md={12}>
                            <TextField
                              label="Description"
                              name="portfolio_details"
                              placeholder="Description"
                              type="text"
                              as="textarea"
                              className="inputTransparent flex p-2"
                              style={{ border: '1px solid #D9D9D9' }}
                            />
                          </Col>

                          <label className="mb-1">Upload Media</label>
                          <div>
                            <FileUpload
                              value={values}
                              setvalue={setFieldValue}
                              name="portfolio_media_url"
                              onUpload={(data) => {
                                setFieldValue('portfolio_media_url', [
                                  ...values.portfolio_media_url,
                                  data
                                ])
                              }}
                              onRemove={(file) => {
                                setFieldValue(
                                  'portfolio_media_url',
                                  values.portfolio_media_url.filter(
                                    (item) => item.name !== file.name
                                  )
                                )
                              }}
                            />
                          </div>

                          <Col md={12} className="text-center mt-2 mb-4">
                            <Button
                              className="accent-btn btn-green2 border-0 py-2 px-4"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <span className="me-2">
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                </span>
                              )}
                              Save & Continue
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <Link
                    to={'/register/skills'}
                    style={{
                      float: 'right',
                      padding: '10px',
                      cursor: 'pointer'
                    }}
                  >
                    <p className="primary-color ">
                      <span className="me-2">Skip For Now</span>
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default RegisterPortfolio
