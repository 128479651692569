import React from "react";
import "./EventTrendingCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";

const EventTrendingCard = (props) => {
  return (
    <>
      <div className="main-event-trend">
        {props.listData.map((item, index) => (
          <div className="d-flex mt-4 mb-4">
            <div className="event-card shadow">
              <FontAwesomeIcon
                icon={faLocation}
                style={{ color: "#06c864", fontSize: "14px", padding: "12px" }}
              />
            </div>
            <div>
              <p className="event-tcard-title">{item.title}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EventTrendingCard;
