import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateEvent from "./components/CreateEvent";
import { add, view } from "src/Services/AxiosConfig";
import { useNavigate } from "react-router-dom";

const CreateEventContainer = () => {
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  const initialValues = {
    title: "",
    // location: "",
    datetime: "2022-10-11T09:36:57Z",
    is_individual: true,
    is_business: false,
    is_meeting: false,
    is_event: true,
    location: "ARM Arena",
    venue: "ARM LG-2 Bldg 3",
    category: "",
    business_profile: "",
    description: "",
  };
  const onSubmit = async (values) => {
    add(`meeting/event/service/`, values)
      .then((res) => {
        console.log(res);
        navigate("/event/upcoming");
      })
      .then((err) => {
        console.log(err);
      });
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Name is required"),
    // location: Yup.string().required("Location is required"),
    datetime: Yup.string().required("Date is required"),
    category: Yup.string().required("Category is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const getCategories = async () => {
    const { data } = await view(`pre/services/`);

    if (data.success) {
      let arr = data?.data?.services.map((item) => ({
        label: item.Service_name,
        value: item.PreService_id,
      }));
      setServices(arr);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      {/* {console.log(errors.title, "container")} */}
      <CreateEvent
        formik={formik}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        touched={touched}
        errors={errors}
        values={values}
        services={services}
        getCategories={getCategories}
      />
    </>
  );
};

export default CreateEventContainer;
