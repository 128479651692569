import React from 'react'
import { ErrorMessage, useField } from 'formik'
import { Form, InputGroup } from 'react-bootstrap'

import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const TextField = ({ label, type, setPasswordType, ...props }) => {
  const [field] = useField(props)
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1" htmlFor={field.name}>
          {label}
        </Form.Label>
        <InputGroup>
          <Form.Control size="lg" type={type} {...field} {...props} />

          {field.name === 'password' || field.name === 'confirmPassword'
            ? (
                type === 'password'
                  ? (
              <FontAwesomeIcon
                icon={faEyeSlash}
                className="eyeIcon position-absolute end-0 bottom-0 px-2 py-3"
                onClick={() => setPasswordType('text')}
              />
                    )
                  : (
              <FontAwesomeIcon
                icon={faEye}
                className="eyeIcon position-absolute end-0 bottom-0 px-2 py-3"
                onClick={() => setPasswordType('password')}
              />
                    )
              )
            : (
                ''
              )}
        </InputGroup>
        <span className="text-danger fs-14">
          <ErrorMessage name={field.name} />
        </span>
      </Form.Group>
    </>
  )
}

export default TextField
