import React, { useState, useRef, useEffect } from 'react';
import Layout from 'src/Components/global/Dashboard/Layouts/Layout';
import styles from './Chat.module.css';
function ChatUI({ chatId, chats, messages, sendMessage, setChatId }) {
  const [formValue, setFormValue] = useState('');
  const dummy = useRef();
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        if (formValue.length > 0) {
          sendMessage(formValue, setFormValue, dummy);
        }
      }
    });
  }, [formValue]);
  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.messaging}>
          <div className={styles.inbox_msg}>
            <div className={styles.inbox_people}>
              <div className={styles.headind_srch}>
                <div className={styles.recent_heading}>
                  <h4>Recent</h4>
                </div>
                <div className={styles.srch_bar}>
                  <div className='stylish-input-group'>
                    <input
                      type='text'
                      className='search-bar'
                      placeholder='Search'
                    />
                    <span className={styles.inputGroupAddon}>
                      <button type='button'>
                        {' '}
                        <i className='fa fa-search' aria-hidden='true'></i>{' '}
                      </button>
                    </span>{' '}
                  </div>
                </div>
              </div>
              <div className={styles.inbox_chat}>
                {chats.length > 0 &&
                  chats.map((chat, index) => (
                    <div
                      className={`${styles.chat_list} ${styles.active_chat}`}
                      key={index}
                      onClick={() => setChatId(chat.chatId)}
                    >
                      <div className={styles.chat_people}>
                        <div className={styles.chat_img}>
                          {' '}
                          <img
                            src='https://ptetutorials.com/images/user-profile.png'
                            alt='sunil'
                          />{' '}
                        </div>
                        <div className={styles.chat_ib}>
                          <h5>
                            Sunil Rajput{' '}
                            <span className='chat_date'>Dec 25</span>
                          </h5>
                          <p>{chat.messages.at(-1).text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {chatId && (
              <div className={styles.mesgs}>
                <div className={styles.msg_history}>
                  {messages.length > 0 &&
                    messages.map(({ outgoing, text, createdAt }, index) => {
                      if (outgoing) {
                        return (
                          <div className={styles.outgoing_msg} key={createdAt}>
                            <div className={styles.sent_msg}>
                              <p>{text}</p>
                              <span className={styles.time_date}>
                                11:01 AM | June 9
                              </span>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className={styles.incoming_msg} key={createdAt}>
                            <div className={styles.incoming_msg_img}>
                              <img
                                src='https://ptetutorials.com/images/user-profile.png'
                                alt='sunil'
                              />
                            </div>
                            <div className={styles.received_msg}>
                              <div className={styles.received_withd_msg}>
                                <p>{text}</p>
                                <span className={styles.time_date}>
                                  11:01 AM | June 9
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  <span id='dummy' ref={dummy}></span>
                </div>
                <div className={styles.type_msg}>
                  <div className={styles.input_msg_write}>
                    <input
                      value={formValue}
                      type='text'
                      className={styles.write_msg}
                      onChange={(e) => setFormValue(e.target.value)}
                      placeholder='Type a message'
                      ref={inputRef}
                    />
                    <button
                      className={styles.msg_send_btn}
                      type='button'
                      onClick={() =>
                        sendMessage(formValue, setFormValue, dummy)
                      }
                    >
                      <i className='fa fa-paper-plane-o' aria-hidden='true'></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ChatUI;
