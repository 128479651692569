import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import './Register.css'
import { Link, useNavigate } from 'react-router-dom'

import CarouselComponent from './Components/CarouselComponent'
import Logo from 'src/Assets/images/register/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

import { Formik, Form } from 'formik'
import TextField from './Components/TextField'

import { useSelector } from 'react-redux'
import {
  getUserProfile,
  updateUserProfile,
  getUserBio
} from 'src/Services/PersonalProfile/getProfileData'
import AxiosConfig from '../../Services/AxiosConfig'
import Autocomplete from 'react-google-autocomplete'
import styled from 'styled-components'
import DummyImage from '../../Assets/images/personal_profile/default_image.png'
import * as Yup from 'yup'

const RegisterBio = () => {
  const [ProfileData, setProfileData] = useState({})
  const [uploadImage, setuploadImage] = useState(null)
  const [apiData, setApiData] = useState()
  const [BioAlreadyExists, setBioExists] = useState(false)
  const userId = useSelector((state) => state.auth.userId)

  const navigate = useNavigate()

  useEffect(() => {
    (async function () {
      try {
        const profileData = await getUserProfile(userId)
        setProfileData(profileData.data)
        // console.log(profileData.data);
      } catch (error) {
        console.log(error)
      }
    })()
  }, [userId])

  useEffect(() => {
    (async function () {
      try {
        const bioData = await getUserBio(userId)
        // console.log("bioData", bioData.data);
        if (bioData?.data?.userbio_id) {
          setBioExists(true)
          setApiData(bioData.data)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [userId])

  const onSubmit = async (values, submitImage, setSubmitting) => {
    try {
      setSubmitting(true)
      // console.log(values.about);
      let imageLink
      if (!submitImage) {
        imageLink = values.profile_photo
      } else {
        const { data } = await AxiosConfig.post(
          process.env.REACT_APP_FILE_UPLOAD,
          submitImage
        )
        imageLink = data.data.media_file
      }
      const res = await updateUserProfile({
        ...ProfileData,
        user_number: values.phone,
        physical_address: values.address,
        gender: 'male',
        cover_photo: '',
        profile_photo: imageLink
      })
      if (res instanceof Error) throw new Error(res.message)

      if (BioAlreadyExists) {
        const response = await AxiosConfig.put('user/bio/create/', {
          about: values.about,
          phone: values.phone,
          address: values.address,
          profile_photo: imageLink,
          id: userId,
          type: 'personal',
          userbio_id: apiData.userbio_id
        })
        // console.log(response);
        if (response.data.success === true) {
          navigate('/register/work')
        }
      } else {
        const response = await AxiosConfig.post('user/bio/create/', {
          about: values.about,
          phone: values.phone,
          address: values.address,
          profile_photo: imageLink,
          id: userId,
          type: 'personal'
        })
        // console.log(response);
        if (response.data.success === true) {
          navigate('/register/work')
        }
      }

      setSubmitting(false)
      // resetForm();
    } catch (error) {
      console.log(error)
      setSubmitting(false)
    }
  }

  const [tempLink, setTempLink] = useState(ProfileData?.profile_photo)
  useEffect(() => {
    setTempLink(ProfileData?.profile_photo)
  }, [ProfileData])

  const onFileChange = async (e, setFieldValue) => {
    setuploadImage(e.target.files[0])
    const img_src = await URL.createObjectURL(e.target.files[0])
    // console.log(img_src);
    setTempLink(img_src)
    setFieldValue('profile_photo', img_src)
  }

  const bioSchema = Yup.object().shape({
    address: Yup.object().required('This Field is Required'),
    phone: Yup.string().required('This Field is Required')
  })

  return (
    <>
      <section className="register" id="register_id">
        <Container fluid>
          <Row>
            <div className="grid--col-02 px-0">
              <div className="align-items-center p-4 d-lg-block d-none">
                <div>
                  <img src={Logo} alt="logo" className="img-fluid logo-width" />
                </div>
                <div>
                  <CarouselComponent />
                </div>
              </div>

              <div className="bg-form-style ">
                <div className="d-flex min-vh-100 flex-column align-items-center justify-content-center">
                  <div className="w-75 mb-3">
                    <h2 className="text-start primary-font primary-color fs-59 fw-bold">
                      Bio
                    </h2>
                    <p className="fs-24">
                      {/* Start building relationships to grow your business. */}
                    </p>
                  </div>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      about: '',
                      phone: '',
                      address: '',
                      profile_photo: ProfileData?.profile_photo
                        ? ProfileData.profile_photo
                        : ''
                    }}
                    validationSchema={bioSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      if (!uploadImage) {
                        onSubmit(values, uploadImage, setSubmitting)
                      } else {
                        const submitImage = new FormData()
                        submitImage.append('media_file', uploadImage)
                        onSubmit(values, submitImage, setSubmitting)
                      }
                    }}
                  >
                    {({
                      values,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      setTouched,
                      setFieldValue
                    }) => (
                      <Form className="w-75">
                        <ProfileImage>
                          <img
                            style={{ objectFit: 'cover' }}
                            src={tempLink || DummyImage}
                            width={100}
                            height={100}
                            alt="ll"
                          />
                        </ProfileImage>

                        <ImageUploadBtn>
                          Add Image
                          <input
                            style={{ objectFit: 'cover' }}
                            className=""
                            type="file"
                            accept="image/*"
                            onChange={(e) => onFileChange(e, setFieldValue)}
                          />
                        </ImageUploadBtn>
                        <Row>
                          <Col md={6}>
                            <TextField
                              label="Phone *"
                              name="phone"
                              placeholder="Phone"
                              type="text"
                            />
                          </Col>
                          <Col md={6}>
                            <label className="pb-1" htmlFor="address">
                              Address *
                            </label>
                            <Autocomplete
                              className="inputTransparent flex p-2"
                              style={{ height: '50%', width: '100%' }}
                              defaultValue={values.address.formatted_address}
                              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                              onPlaceSelected={(place) => {
                                setFieldValue('address', place)
                              }}
                              options={{
                                types: ['geocode', 'establishment']
                              }}
                            />
                          </Col>
                          <Col md={12}>
                            <TextField
                              label="About"
                              name="about"
                              placeholder="Write Something about yourself"
                              // as="textarea"
                              type="textarea"
                              style={{ minHeight: '100px' }}
                            />
                          </Col>

                          <Col md={12} className="text-center mt-2 mb-4">
                            <Button
                              className="accent-btn btn-green2 border-0 py-2 px-4"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <span className="me-2">
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                </span>
                              )}
                              Save & Continue
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <Link
                    to={'/register/work'}
                    style={{
                      float: 'right',
                      padding: '10px',
                      cursor: 'pointer'
                    }}
                  >
                    <p className="primary-color ">
                      <span className="me-2">Skip For Now</span>
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  )
}

const ProfileImage = styled.div`
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 20px;
  overflow: hidden;
`
const ImageUploadBtn = styled.button`
  position: relative;
  background-color: #f4f1f0;
  color: #3f464e;
  border: none;
  padding: 10px 10px;
  border-radius: 10px;
  margin-left: 10px;
  top: -10px;
  cursor: pointer;
`

export default RegisterBio
