import React from 'react'
import ChatSidePanelCard from '../../Components/global/ChatSidePanelCard/ChatSidePanelCard'
import { Container } from 'react-bootstrap'
import ChatBox from '../../Components/global/ChatBox/ChatBox'
import Layout from 'src/Components/global/Dashboard/Layouts/Layout'
function ChatPage () {
  return (
    <>
    <Layout>
      <Container className="pymd-5">

          <div className="main-of-chat-page">
          <div className="side-of-chat">
          <ChatSidePanelCard />
          </div>
          <div className="main-box-of-chat">
          <ChatBox/>
          </div></div>

      </Container>
      </Layout>
    </>
  )
}

export default ChatPage
