import React from "react";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import "./AboutUs.css";
import ReactPlayer from "react-player";

function AboutUs() {
  return (
    <Layout type="plain">
      <div className="aboutus_banner">
        <span
          className="banner_decorator"
          style={{
            width: "150px",
            height: "10px",
            background: "white",
            position: "absolute",
            top: "20%",
            left: "0",
            borderRadius: "0 20px 20px 0",
          }}
        ></span>
        <h1 className="text-white recoleta-font fs-100 ">About Us</h1>
        <span
          className="banner_decorator"
          style={{
            width: "150px",
            height: "10px",
            background: "white",
            position: "absolute",
            bottom: "20%",
            right: "0",
            borderRadius: " 20px 0 0  20px ",
          }}
        ></span>
      </div>
      <div
        className="aboutus_text mx-auto px-4 "
        style={{ maxWidth: "1210px", textAlign: "center" }}
      >
        <span className="recoleta-font fs-32" style={{ color: "#707070" }}>
          Flush is revolutionizing the way we conduct business and interact with
          the Skilled Trade industry online. We are a one-stop platform for
          people looking to hire a tradesperson for their next project to those
          who are interested in looking for employment in trades and finding
          schooling for their next career. Flush is built by people with
          experience in the trades for those interested in the trades.
        </span>
      </div>
      <div className="d-flex justify-content-center">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=0DxFgmdU8Rk"
          width={window.innerWidth > 900 ? 640 : window.innerWidth}
        />
      </div>
      <div className="community__highlight">
        <h1 className="recoleta-font fs-90 text-center">
          “Where Community Meet Services”
        </h1>
      </div>
    </Layout>
  );
}

export default AboutUs;
