import React from "react";
import Pusher from "pusher-js";
import { useDispatch } from "react-redux";
import { setNotification } from "src/Redux/NotificationSlice";

export default function GetNotificationList(userId, setNotification, dispatch) {
  try {
    let pusher = new Pusher("800b40f3a313c369b404", {
      cluster: "ap2",
    });
    let channel = pusher.subscribe(userId);
    channel.bind("notification", (data) => {
      console.log("data", data);
      // dispatch(setNotification(data));
    });
  } catch (e) {
    console.log("Pusher Notifications Error: ", e);
  }
}
