import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.css";
import Flushlogo from "src/Assets/images/Logo.png";
import { FooterLinks } from "../../../Styles/Styles";
const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer">
        <Container>
          <div>
            <div className="d-sm-flex  justify-content-between align-items-center">
              <div>
                {" "}
                <img src={Flushlogo} />{" "}
              </div>
              <div>
                <FooterLinks to=""> About</FooterLinks>
                <FooterLinks to=""> Accessibility</FooterLinks>
                <FooterLinks to=""> User Agreement</FooterLinks>
                <FooterLinks to=""> Privacy Policy</FooterLinks>
                <FooterLinks to=""> Cookies Policy</FooterLinks>
                <FooterLinks to=""> Copyright</FooterLinks>
                <FooterLinks to=""> Brand Policy</FooterLinks>
                <FooterLinks to=""> Community Policy</FooterLinks>
              </div>
            </div>
          </div>
        </Container>
      </footer>
      <div className="powered d-flex flex-column justify-content-center align-items-center">
        <FooterLinks to="//pixarsart.com" target="_blank">
          Powered by Pixarsart
        </FooterLinks>
      </div>
    </>
  );
};

export default Footer;
