import axios from 'axios'
const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_RESOURSE_SERVER
})

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const value = localStorage.getItem('rT')

    if (config.url === 'signup/firebase/user/') {
      delete config.headers.Authorization
    } else {
      config.headers.Authorization = `Bearer ${value}`
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default axiosApiInstance

export function add (method, payload) {
  return axiosApiInstance.post(method, payload)
}
export function edit (method, payload) {
  return axiosApiInstance.put(method, payload)
}
export function view (method, cancel) {
  return axiosApiInstance.get(method, {
    cancelToken: new axios.CancelToken((c) => (cancel = c))
  })
}
export function remove (method) {
  return axiosApiInstance.delete(method)
}
