import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AxiosConfig from '../../../Services/AxiosConfig'
import WorkExperienceUI from '../../global/PersonalProfile/WorkExperience/WorkExperience'

function WorkExperience ({ navigateTo }) {
  const navigate = useNavigate()
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSucces] = useState(false)

  const [formData, setFormData] = useState({
    workHistory: [
      {
        end_date: '',
        start_date: '',
        title: '',
        employment_type: '',
        company_name: '',
        location: '',
        job_description: ''
      }
    ]
  })

  const onSubmit = async (values, setSubmitting) => {
    // console.log("values.workHistory[0]", values);
    try {
      setSubmitting(true)
      setIsError(false)
      setIsSucces(false)
      const response = await AxiosConfig.post('user/work/details/', {
        ...values.workHistory[0],
        is_expired: values.is_expired
      })
      // console.log(response);

      setSubmitting(false)
      setIsSucces(true)
      setIsError(false)
      navigate(navigateTo)
    } catch (error) {
      console.log(error)
      setIsError(true)
      setIsSucces(false)
      setSubmitting(false)
    }
  }
  return (
    <div id="wordExperience">
      <WorkExperienceUI
        status={'onboarding'}
        navigateTo={navigateTo}
        onSubmit={onSubmit}
        formData={formData}
      />
    </div>
  )
}

export default WorkExperience
