import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import { ViewPost } from "./Post/view/ViewPost";
import { SharedPost } from "./Post/view/SharedPost";
import { useNavigate, useParams } from "react-router-dom";
import axiosApiInstance from "src/Services/AxiosConfig";

const reloadPostListing = () => {
  console.log("reloadPostListing called");
};

const SinglePost = () => {
  const navigate = useNavigate();
  const { postId } = useParams();
  const [posts, setPosts] = useState([]);

  useEffect(async () => {
    const response = await axiosApiInstance.get(
      `/user/get/specific/post/?post_id=${postId}`
    );
    console.log(response);
    setPosts([response.data.data]);
  }, [postId]);

  return (
    <>
      <Layout type="leftsidebar">
        <Container style={{ maxWidth: "750px" }}>
          <div>
            <button
              className="btn-green2 text-white"
              onClick={() => navigate("/feed")}
            >
              <span>Back</span>
            </button>
          </div>
          {posts.map((post, index) => {
            if (posts.length === index + 1) {
              if (post?.shared_post_id != null) {
                return (
                  <div key={index}>
                    <SharedPost
                      postDetail={post}
                      reloadPosting={reloadPostListing}
                    />
                  </div>
                );
              }
              return (
                <div key={index}>
                  <ViewPost
                    postDetail={post}
                    reloadPosting={reloadPostListing}
                  />
                </div>
              );
            } else {
              if (post?.shared_post_id != null) {
                return (
                  <SharedPost
                    postDetail={post}
                    key={index}
                    reloadPosting={reloadPostListing}
                  />
                );
              }
              return (
                // <></>
                <ViewPost
                  key={index}
                  postDetail={post}
                  reloadPosting={reloadPostListing}
                />
              );
            }
          })}
        </Container>
      </Layout>
    </>
  );
};

export default SinglePost;
