import React from 'react'
import '../../Components/global/JobListingGridCard/JobListingGridCard.css'
import JobListingGridCard from '../../Components/global/JobListingGridCard/JobListingGridCard'
import { Container, Row, Col } from 'react-bootstrap'

const JobListingGrid = ({ dataAPI, getAPIData }) => {
  return (
    <>
      <Container className="pxy-5">
        <Row>
          <Col>
            <p className="grid-headline">
              Similar to a job you applied in last week
            </p>
            <div className="job-listing-grid">
              {dataAPI.map((item, index) => (
                <JobListingGridCard item={item} getAPIData={getAPIData} />
              ))}
            </div>
            {/* <div className="load-more-btn">
              <button className="button-4">
                <span>Load More Services</span>
              </button>
            </div> */}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default JobListingGrid
