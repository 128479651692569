import React from 'react'
import './Loading.scss'
export const Loading = () => {
  return (
    <section className="loadingContainer">
      {' '}
      <div className="loader">Loading...</div>
    </section>
  )
}
