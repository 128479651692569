import React from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";

import CarouselComponent from "./Components/CarouselComponent";
import Logo from "src/Assets/images/register/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, ErrorMessage, Field } from "formik";
import TextField from "./Components/TextField";
import Autocomplete from "react-google-autocomplete";

import { SelectField } from "../../../src/Components/global/SelectField/SelectField";
import * as Yup from "yup";
import AxiosConfig from "../../../src/Services/AxiosConfig.js";

const RegisterWork = () => {
  const navigate = useNavigate();

  const workExperenceSchema = Yup.object().shape({
    workHistory: Yup.array().of(
      Yup.object().shape({
        start_date: Yup.string().required("This Field is Required"),
        title: Yup.string().required("This Field is Required"),
        employment_type: Yup.string().required("This Field is Required"),
        company_name: Yup.string().required("This Field is Required"),
        location: Yup.object().required("This Field is Required"),
        job_description: Yup.string(),
      })
    ),
  });

  const onSubmit = async (values, setSubmitting) => {
    // console.log("values.workHistory[0]", values);
    try {
      setSubmitting(true);

      await AxiosConfig.post("user/work/details/", {
        ...values,
      });
      // console.log(response);

      setSubmitting(false);
      navigate("/register/education");
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <section className="register" id="register_id">
        <Container fluid>
          <Row>
            <div className="grid--col-02 px-0">
              <div className="align-items-center p-4 d-lg-block d-none">
                <div>
                  <img src={Logo} alt="logo" className="img-fluid logo-width" />
                </div>
                <div>
                  <CarouselComponent />
                </div>
              </div>

              <div className="bg-form-style ">
                <div className="d-flex min-vh-100 flex-column align-items-center justify-content-center">
                  <div className="w-75 mb-3">
                    <h2 className="text-start primary-font primary-color fs-59 fw-bold">
                      Work History
                    </h2>
                    <p className="fs-24">
                      {/* Start building relationships to grow your business. */}
                    </p>
                  </div>
                  <Formik
                    initialValues={{
                      end_date: "",
                      start_date: "",
                      title: "",
                      employment_type: "",
                      company_name: "",
                      location: "",
                      job_description: "",
                      // is_expired: true,
                    }}
                    validationSchema={workExperenceSchema}
                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                      // console.log("work experience", values);
                      onSubmit(values, setSubmitting);
                    }}
                  >
                    {({
                      handleChange,
                      isSubmitting,
                      values,
                      setFieldValue,
                    }) => (
                      <Form className="w-75">
                        <Row>
                          <Col md={6}>
                            <TextField
                              label="Title *"
                              name="title"
                              placeholder="Title"
                              type="text"
                            />
                          </Col>
                          <Col md={6}>
                            <label htmlFor={"employment_type"} className="mb-1">
                              Employment Type *
                            </label>

                            <Field
                              id={"employment_type"}
                              name={"employment_type"}
                              placeholder={"Select employment type"}
                              className="inputTransparent flex p-2 form-control form-control-lg"
                              style={{}}
                              component={SelectField}
                              options={[
                                { value: "full-time", label: "FullTime" },
                                { value: "part-time", label: "Part Time" },
                                { value: "internship", label: "Internship" },
                                { value: "contract", label: "Contract" },
                                { value: "freelance", label: "Freelance" },
                                { value: "other", label: "Other" },
                              ]}
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              label="Company Name *"
                              name="company_name"
                              placeholder="Company Name"
                              type="text"
                            />
                          </Col>
                          <Col md={6}>
                            <label className="pb-1" htmlFor="location">
                              Location *
                            </label>
                            <Autocomplete
                              className="inputTransparent flex p-2"
                              style={{ height: "50%", width: "100%" }}
                              defaultValue={values.location.formatted_address}
                              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                              onPlaceSelected={(place) => {
                                setFieldValue("location", place);
                              }}
                              options={{
                                types: ["geocode", "establishment"],
                              }}
                            />
                          </Col>
                          <Col md={12}>
                            <TextField
                              label="Description *"
                              name="job_description"
                              placeholder="Description"
                              as="textarea"
                              className="inputTransparent flex p-2"
                              style={{ border: "1px solid #D9D9D9" }}
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              type="date"
                              label="Start Date"
                              name="start_date"
                              id={"start_date"}
                            />
                          </Col>

                          {values.status === true ? (
                            <></>
                          ) : (
                            <Col md={6}>
                              <TextField
                                type="date"
                                label="End Date"
                                name="end_date"
                                id={"end_date"}
                                disabled={values.is_expired}
                              />

                              {({ field, form: { touched, errors } }) => (
                                <input
                                  className="inputTransparent flex p-2"
                                  {...field}
                                  type="date"
                                  disabled={values.status === true}
                                />
                              )}
                              {/* </TextField> */}
                            </Col>
                          )}
                          <Col md={12}>
                            <div>
                              <label className="checkmark-container me-2 d-inline-flex align-items-center">
                                <span className="secondary-font-medium fs-16">
                                  Currently Working Here?
                                </span>
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue("is_expired", true);
                                    } else {
                                      setFieldValue("is_expired", false);
                                    }
                                  }}
                                  // checked={values.status === "in_progress"}
                                  name="is_expired"
                                  // value="in_progress"
                                />
                                <span className="checkmark"></span>
                              </label>
                              {/* </div> */}
                              <p className="text-danger fs-12">
                                <ErrorMessage name="status" />
                              </p>
                            </div>
                          </Col>
                          <Col md={12} className="text-center mt-2 mb-4">
                            <Button
                              className="accent-btn btn-green2 border-0 py-2 px-4"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <span className="me-2">
                                  <Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                </span>
                              )}
                              Save & Continue
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <Link
                    to={"/register/education"}
                    style={{
                      float: "right",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <p className="primary-color ">
                      <span className="me-2">Skip For Now</span>
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default RegisterWork;
