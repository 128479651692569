import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import AddMembersCard from 'src/Components/global/AddMembersCard/AddMembersCard'
import Layout from 'src/Components/global/Dashboard/Layouts/Layout'
import data from './demoGridData.json'

function AddMembersPage ({ dataLimit = 12 }) {
  const pages = Math.round(data.length / dataLimit)
  const [currentPage, setCurrentPage] = useState(1)

  function goToNextPage () {
    // console.log("total page Number", pages)
    // console.log("current page number", currentPage+1)
    if (currentPage + 1 > pages) {

    } else {
      setCurrentPage((page) => page + 1)
    }
  }

  function goToPreviousPage () {
    // console.log("current page on previous", currentPage-1)
    if (currentPage - 1 == 0) {

    } else {
      setCurrentPage((page) => page - 1)
    }
  }

  function changePage (event) {
    const pageNumber = Number(event.target.text)
    // console.log("page Number", pageNumber)
    if (pageNumber) {
      setCurrentPage(pageNumber)
    } else {

    }
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit
    const endIndex = startIndex + dataLimit
    return data.slice(startIndex, endIndex)
  }

  return (
        <>
        <Layout type="leftsidebar">
        <Container>
            <AddMembersCard
                pages={pages}
                currentPage={currentPage}
                changePage={changePage}
                goToNextPage={goToNextPage}
                goToPreviousPage={goToPreviousPage}
                getData={getPaginatedData()}
            />
        </Container>
        </Layout>
        </>
  )
}

export default AddMembersPage
