import React, { useState, useRef, useCallback, useEffect } from "react";
import "./Feed.scss";
import PostUserImg from "src/Assets/images/postUser.png";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faImage,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { ViewPost } from "./Post/view/ViewPost";
import { SharedPost } from "./Post/view/SharedPost";
import CreatePost from "./Post/create/CreatePost";
import useGetPost from "../../Hook/useGetPost";
import { Loading } from "src/Components/global/Loading/Loading";
import { useSelector } from "react-redux";
import axiosApiInstance from "src/Services/AxiosConfig";

const Feed = () => {
  const [showCreateModel, setShowCreateModel] = useState(false);
  const createModelHandleClose = () => setShowCreateModel(false);

  // GET ALL INSTITUTES LIST WITH THIER NAME, DOMAINS AND LOCATION/ADDRESS THROUGH THIS API
  // useEffect(async()=>{
  //   const response = await axiosApiInstance.get('http://universities.hipolabs.com/')
  //   console.log(response)
  // }, [])

  const createModelHandleShow = () => {
    // console.log('i am  open ');
    setShowCreateModel(true);
  };
  const { authorizing, userDetail } = useSelector((state) => state.auth);
  // console.log(userDetail)

  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [resetPost, setResetPost] = useState(false);
  const { posts, hasMore, loading, error } = useGetPost(
    startPage,
    endPage,
    resetPost
  );
  const observer = useRef();
  const reloadPostListing = () => {
    setStartPage(0);
    setEndPage(5);
    setResetPost(true);
  };
  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !(endPage > hasMore)) {
          setStartPage(endPage);
          setEndPage((prevEndPage) => prevEndPage + 5);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  }

  if (authorizing) {
    return (
      // <Layout>
      <div className="d-flex justify-content-center vh-100 align-items-center">
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
      // </Layout>
    );
  }
  return (
    <>
      <Layout type="bothsidebar">
        <CreatePost
          userDetail={userDetail}
          show={showCreateModel}
          close={createModelHandleClose}
          reloadPosting={reloadPostListing}
        />

        <section className="feedMain">
          <div className="CreatePost db_color">
            <div className="d-flex align-items-center">
              <div className=" p-2">
                <img
                  className="rounded-circle"
                  src={
                    validateUrl(userDetail?.profile_photo)
                      ? userDetail?.profile_photo
                      : PostUserImg
                  }
                  width="50"
                  height="50"
                />
              </div>
              <div className="p-2 flex-fill">
                <div
                  className="db_color fs-18 t-c p-2  "
                  onClick={createModelHandleShow}
                >
                  Your thoughts with flush
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center px-4 py-2">
              <div className="d-flex align-items-center">
                <div>
                  <FontAwesomeIcon
                    icon={faImage}
                    className="fs-30"
                    style={{
                      color: "#70b5f9",
                    }}
                  />
                </div>
                <div className="px-3">Photo</div>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <FontAwesomeIcon
                    icon={faVideo}
                    className="fs-30"
                    style={{
                      color: "#7fc15e",
                    }}
                  />
                </div>
                <div className="px-3">Video</div>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="fs-30"
                    style={{
                      color: "#e7a33e",
                    }}
                  />
                </div>
                <div className="px-3">Event</div>
              </div>
            </div>
          </div>
          <div className="">
            {posts.map((post, index) => {
              if (posts.length === index + 1) {
                if (post?.shared_post_id != null) {
                  return (
                    <div ref={lastPostElementRef} key={index}>
                      <SharedPost
                        postDetail={post}
                        reloadPosting={reloadPostListing}
                      />
                    </div>
                  );
                }
                return (
                  <div ref={lastPostElementRef} key={index}>
                    <ViewPost
                      postDetail={post}
                      reloadPosting={reloadPostListing}
                    />
                  </div>
                );
              } else {
                if (post?.shared_post_id != null) {
                  return (
                    <SharedPost
                      postDetail={post}
                      key={index}
                      reloadPosting={reloadPostListing}
                    />
                  );
                }
                return (
                  // <></>
                  <ViewPost
                    key={index}
                    postDetail={post}
                    reloadPosting={reloadPostListing}
                  />
                );
              }
            })}
          </div>
          <div>{loading && <Loading />}</div>
          <div>{error && "Error"}</div>
        </section>
      </Layout>
    </>
  );
};
export default Feed;
