import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateEventContainer from "./add/CreateEventContainer";
import EventDetailContainer from "./detail/EventDetailContainer";
import EventListContainer from "./list/EventListContainer";
import UpcomingEventPage from "./upcoming/UpcomingEventPage";
import EventDetailOrganizerContainer from "./detailOrganizer/EventDetailOrganizerContainer";
import EventBookmarkContainer from "./bookmarkEvent/BookmarkEventContainer";
import RegisterEventFreeContainer from "./registerEventFree/RegisterEventFreeContainer";
import RegisterEventPaidContainer from "./registerEventPaid/RegisterEventPaidContainer";

const EventsRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/detail/:id" element={<EventDetailContainer />} />
        <Route path="/add" element={<CreateEventContainer />} />
        <Route path="/upcoming" element={<UpcomingEventPage />} />
        <Route path="/list" element={<EventListContainer />} />
        <Route path="/bookmark" element={<EventBookmarkContainer />} />
        <Route
          path="/register/free/:id"
          element={<RegisterEventFreeContainer />}
        />
        <Route
          path="/register/paid/:id"
          element={<RegisterEventPaidContainer />}
        />
        <Route
          path="/organizer/detail/:id"
          element={<EventDetailOrganizerContainer />}
        />
      </Routes>
    </>
  );
};
export default EventsRouter;
