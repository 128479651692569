import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";
import { parseFullName } from "parse-full-name";
import CarouselComponent from "./Components/CarouselComponent";
import Logo from "src/Assets/images/register/logo-green.png";
import Google from "src/Assets/images/google-icon.png";

import { Formik, Form, ErrorMessage } from "formik";
import TextField from "./Components/TextField";

import { useDispatch, useSelector } from "react-redux";
import { signup, socialSignup } from "../../Redux/AuthSlice";
import { toast } from "react-toastify";
import firebase from "../../Services/firebaseClient";
import Recaptcha from "react-recaptcha";

import * as Yup from "yup";
const Register = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [human, setHuman] = useState(true);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    toast.dismiss();
    if (auth.isSignUpProcessing) {
      toast.loading("Please wait...");
    }
    if (auth.isSignUpError) {
      if (auth.signUpErrorMessage.includes("wrong-password")) {
        toast.error("Wrong password. Please try again");
      } else if (auth.signUpErrorMessage.includes("user-not-found")) {
        toast.error("User not found. Please try again");
      } else if (auth.signUpErrorMessage.includes("network-request-failed")) {
        toast.error("Network connection failed. Please try again");
      } else {
        toast.error("Error: " + auth.signUpErrorMessage);
      }
    }
    if (auth.signupSuccess) {
      toast({
        render: "Success",
        type: "success",
      });
      navigate("/register/bio");
    }
  }, [auth, navigate]);
  const dispatch = useDispatch();

  const validate = Yup.object().shape({
    // accountType: Yup.string().required("This Field is Required"),
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("First Name is Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("Last Name is Required"),
    email: Yup.string()
      .email()
      .min(2, "Too Short!")
      .max(70, "Too Long!")
      .required("Email is Required"),
    // physicalAddress: Yup.object().required("Required"),
    // gender: Yup.string().required('Required'),
    agree: Yup.boolean().oneOf([true], "Required"),
    password: Yup.string()
      .required("Password is Required")
      .min(6, "Password is too short - should be 6 chars minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/,
        "Password must contains at least one Special character, one Uppercase letter, one Lowercase letter and one Number."
      ),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  });
  const loginWithGoogle = async () => {
    const data = await firebase.auth.googleSignin();
    // console.log(data);
    if (data.success === true) {
      const { first } = parseFullName(data.data.displayName);
      const res = dispatch(
        socialSignup({
          token: data.data.accessToken,
          firstName: first,
          type: "google",
        })
      );
      if (res.error) {
        console.log("res", res);
        toast.error("Something went wrong");
      }
    } else {
      console.log("error", data.error);
    }
  };
  // const loginWithFacebook = async () => {
  //   const data = await firebase.auth.facebookSignin();

  //   if (data.success === true) {
  //     const { first, last } = parseFullName(data.data.displayName);
  //     const res = await dispatch(
  //       socialSignup({
  //         token: data.data.accessToken,
  //         firstName: first,
  //         type: "facebook",
  //       })
  //     );
  //     if (res.error) {
  //       console.log("res", res);
  //       toast.error("Something went wrong");
  //     }
  //     // console.log("res", res);
  //   } else {
  //     console.log("error", data.error);
  //   }
  // };

  let recaptchaInstance;

  useEffect(() => {
    recaptchaInstance.reset();
  }, [recaptchaInstance]);

  const onloadCallback = () => {
    // console.log("captcha is loading...");
  };

  const verifyCallback = (Response) => {
    if (Response) {
      setHuman(true);
      // console.log("its human");
    }
  };

  return (
    <>
      <section className="register" id="register_id">
        <Container fluid>
          <Row>
            <div className="grid--col-02 px-0">
              <div className="d-flex align-items-center justify-content-center p-4 d-lg-block d-none">
                <div className="signup-left-padding">
                  <CarouselComponent />
                </div>
              </div>

              <div className="bg-form-style ">
                <div className="d-flex min-vh-100 flex-column align-items-center justify-content-center">
                  <div>
                    <img
                      src={Logo}
                      alt="logo"
                      className="img-fluid logo-width mb-3"
                    />
                  </div>
                  <div className="w-75 mb-3">
                    <h2 className="text-start primary-font primary-color fs-59 fw-bold">
                      Sign Up
                    </h2>
                    <p className="fs-24">
                      Start building relationships to grow your business.
                    </p>
                  </div>
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      password: "",
                      confirmPassowrd: "",
                      agree: false,
                    }}
                    validationSchema={validate}
                    onSubmit={(values, { setSubmitting }) => {
                      try {
                        if (human) {
                          dispatch(signup(values));
                        } else {
                          toast.error("Please verify that you are a human");
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    {({ handleChange, setFieldValue, handleBlur, values }) => (
                      <Form className="w-75">
                        <Row>
                          <Col md={6}>
                            <TextField
                              label="First Name"
                              name="firstName"
                              placeholder="First Name"
                              type="text"
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              label="Last Name"
                              name="lastName"
                              placeholder="Last Name"
                              type="text"
                            />
                          </Col>
                          <Col md={12}>
                            <TextField
                              label="Email"
                              name="email"
                              placeholder="Enter Email"
                              type="email"
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              label="Password"
                              name="password"
                              placeholder="Password"
                              type={passwordType}
                              setPasswordType={setPasswordType}
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              label="Confirm Password"
                              name="confirmPassword"
                              placeholder="Password"
                              type={confirmPasswordType}
                              setPasswordType={setConfirmPasswordType}
                            />
                          </Col>
                          <Col md={12}>
                            <div className="agreeStatementDiv">
                              <label className="checkmark-container my-2 d-inline-flex align-items-center">
                                <span className="secondary-font">
                                  I agree to all
                                  <span className="primary-color">
                                    {" Terms & Conditions "}
                                  </span>
                                  and
                                  <span className="primary-color">
                                    {" Privacy Policy. "}
                                  </span>
                                </span>
                                <OverlayTrigger
                                  placement={"bottom"}
                                  overlay={
                                    values.agree ? (
                                      <></>
                                    ) : (
                                      <Tooltip>
                                        Please check this box if you want to
                                        proceed.
                                      </Tooltip>
                                    )
                                  }
                                >
                                  <input
                                    name="agree"
                                    type="checkbox"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.agree}
                                    checked={values.agree}
                                  />
                                </OverlayTrigger>
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            {/* <div className="form-check">
                              <input
                                className="form-check-input mb-1"
                                type="checkbox"
                                name="agree"
                                value={values.agree}
                                checked={values.agree}
                                onChange={(value) => {
                                  setFieldValue("agree", value.target.checked);
                                }}
                                id="flexCheckDefault"
                              />
                              <label
                                className="form-check-label fs-16 "
                                for="flexCheckDefault"
                              >
                                I agree with the Terms of Service and Privacy
                                Policy.
                              </label>
                            </div> */}
                            <span className="text-danger fs-14">
                              <ErrorMessage name="agree" />
                            </span>
                          </Col>
                          <Col
                            md={12}
                            className="d-flex justify-content-center"
                          >
                            <div className="m-2">
                              <Recaptcha
                                sitekey="6Ld1ABAhAAAAAASuoyYMyjK-gdTXh7ZX_-SQf25t"
                                render="explicit"
                                ref={(e) => (recaptchaInstance = e)}
                                onloadCallback={onloadCallback}
                                verifyCallback={verifyCallback}
                              />
                            </div>
                          </Col>
                          <Col md={12} className="text-center mt-2 ">
                            <Button
                              className="accent-btn btn-green2 border-0 py-2 px-4"
                              type="submit"
                            >
                              Create Your Account
                            </Button>
                          </Col>
                          <Col md={12} className="text-center mt-2">
                            <div>
                              <span>Or Continue</span>
                            </div>
                            <div className="d-flex icon-group justify-content-center gap-2 my-2">
                              {/* <div>
                                <img
                                  src={Facebook}
                                  alt="Facebook"
                                  className="img-fluid"
                                  onClick={loginWithFacebook}
                                />
                              </div> */}
                              <div>
                                <img
                                  src={Google}
                                  alt="Google"
                                  className="img-fluid"
                                  onClick={loginWithGoogle}
                                />
                              </div>
                            </div>
                            <div>
                              <span className="primary-font">
                                Already have an account?
                              </span>
                              <Link
                                to="/login"
                                className="ms-2 primary-color"
                                type="submit"
                              >
                                Login
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Register;
