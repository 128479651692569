import React, { useState } from "react";
import RegisterEventFree from "./components/RegisterEventFree";
import RegisterEventFreeTwo from "./components/RegisterEventFreeTwo";
import { useFormik } from "formik";
import * as Yup from "yup";

const RegisterEventFreeContainer = () => {
  const [step, setStep] = useState("step1");
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
  };
  const onSubmit = (values) => console.log("submit form", values);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Name is required"),
    lastName: Yup.string().required("Location is required"),
    email: Yup.string().required("Date is required"),
    confirmEmail: Yup.string().required("License no is required"),
  });

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  return (
    <>
      {step === "step1" && <RegisterEventFree setStep={setStep} />}
      {step === "step2" && (
        <RegisterEventFreeTwo
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={errors}
        />
      )}
    </>
  );
};

export default RegisterEventFreeContainer;
