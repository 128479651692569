import { app } from './firebaseClient'
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from './AxiosConfig'
const messaging = getMessaging(app);
export async function getFCM() {
    const fcmToken = await getToken(messaging, { serviceWorkerRegistration: registeration, vapidKey: process.env.REACT_APP_VAPID_KEY });
    return fcmToken;
}
export async function subscribeToPush() {
    try {
        const permissions = await Notification.requestPermission()
        const swFile = process.env.NODE_ENV === 'production' ? "/sw.production.js" :
            process.env.NODE_ENV === 'staging' ? "/sw.production.js" : "/sw.development.js"
        const swRegistration = await navigator.serviceWorker.register(swFile, {
            scope: '/'
        });
        if (permissions === 'granted') {

            const fcmToken = await getToken(messaging, { serviceWorkerRegistration: swRegistration, vapidKey: process.env.REACT_APP_VAPID_KEY });
            // console.log('fcmToken', fcmToken);
            const response = await axios.post("add/fcm/token", {
                registration_id: fcmToken
            })
            // console.log("Response from Subscribing to Push NOtificaions", response)
        }

    } catch (error) {
        console.log("Error subscribing to Push notifications", error)
    }
}
export function listenToPush() {
    onMessage(messaging, (payload) => {
        console.log("Push Notification Received", payload)
    })
}