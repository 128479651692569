import React, { useState, useCallback, useEffect } from "react";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import data from "./demoGridData.json";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import CommunityCard from "src/Components/global/CommunityCard/CommunityCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import axios, { view } from "src/Services/AxiosConfig";
import { useNavigate } from "react-router-dom";
import CommunityCategoryCard from "src/Components/global/CommunityCategoryCard/CommunityCategoryCard";
import { categorydata } from "./demoCategoryData.js";

function CommunityListContainer({ dataLimit = 12 }) {
  const navigate = useNavigate();
  const [dataAPI, setDataAPI] = useState();
  const [totalData, setTotalData] = useState();
  const [searchdata, setSearchData] = useState();
  const pages = Math.ceil(totalData / dataLimit);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  let endValue;
  if (totalData <= dataLimit) {
    endValue = totalData;
  } else {
    endValue = dataLimit - 1;
  }
  const [endIndex, setEndIndex] = useState(endValue);

  const remainingIndex = totalData % dataLimit;

  const business_id = useSelector(
    (state) => state.auth?.userDetail?.business_list?.[0]?.business_profile_id
  );

  console.log(business_id, "business_id");
  const handleSearch = async (e) => {
    try {
      const { data } = await view(
        `/search/community/?keyword=${e.target.value}`
      );
      if (data.success) {
        setDataAPI(data.community_data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAPIData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `/joined/community/list/?index=${startIndex}&offset=${dataLimit}`
      );
      if (data.success) {
        setTotalData(data.total_communities);
        setDataAPI(data.communities_data);
        console.log(data, "hdhdhd");
      }
    } catch (error) {
      console.log(error);
    }
  }, [business_id, startIndex, endIndex]);
  useEffect(() => {
    // if (business_id !== undefined) {
    //   getAPIData();
    // }
    getAPIData();
    handleSearch();
  }, [business_id, getAPIData]);

  async function goToNextPage() {
    // console.log("currentPage number", currentPage);

    if (currentPage + 1 > pages) {
    } else if (currentPage + 1 === pages) {
      setStartIndex(totalData - remainingIndex);
      setEndIndex(totalData - 1);
    } else if (startIndex <= totalData) {
      setEndIndex(totalData);
    } else {
      setCurrentPage((page) => page + 1);

      setStartIndex(startIndex + dataLimit);
      setEndIndex(endIndex + dataLimit);
    }
  }

  async function goToPreviousPage() {
    // console.log("current page on previous", currentPage);
    if (currentPage === 0) {
    } else if (currentPage + 1 === pages) {
      setCurrentPage((page) => page - 1);
      setStartIndex(startIndex - dataLimit);
      setEndIndex(endIndex - remainingIndex);
    } else if (startIndex !== 0) {
      setCurrentPage((page) => page - 1);

      setStartIndex(startIndex - dataLimit);
      setEndIndex(endIndex - dataLimit);
    }
  }

  return (
    <>
      {/* {console.log(dataAPI, "dataapi")} */}
      <Layout type="leftsidebar">
        <Container className="py-3">
          <Row>
            <Col md={12}>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ color: "#06C864" }}
                  />
                </InputGroup.Text>
                <Form.Control
                  className="inp-bck"
                  placeholder="Search community"
                  size="lg"
                  onChange={handleSearch}
                />
              </InputGroup>
              <div className="d-flex justify-content-between mt-5">
                <div className="header-title marBot">
                  <span>Top Categories</span>
                </div>
                <div>
                  <button
                    onClick={() => navigate("/community/add")}
                    className="community-btn-add"
                  >
                    Create Community
                  </button>
                </div>
              </div>
              <hr />
            </Col>
          </Row>
          <Row>
            <CommunityCategoryCard getCategoryData={categorydata} />
          </Row>
        </Container>

        <Container>
          <div className="com-top">
            <div className="header-title marBot t-space mt-4">
              <span>Community List</span>
            </div>
            <div className="add-com mt-3">
              <Button className="cat-btn dropdown-toggle">Category</Button>
            </div>
          </div>
          <hr className="hr" />
          <CommunityCard getData={dataAPI} />
        </Container>
      </Layout>
    </>
  );
}

export default CommunityListContainer;
