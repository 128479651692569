import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import data from "./demoGridData.json";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import UpcomingEventCard from "src/Components/global/UpcomingEventCard/UpcomingEventCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCalendarDays,
  faLocation,
} from "@fortawesome/free-solid-svg-icons";
import event from "src/Assets/images/event-banner.png";
import "./UpcomingEvent.css";
import EventTrendingCard from "src/Components/global/EventTrendingCard/EventTrendingCard";
import griddata from "./demoData.json";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { view } from "src/Services/AxiosConfig";

function UpcomingEventPage() {
  const [eventlist, setEventList] = useState();
  const navigate = useNavigate();
  const business_id = useSelector(
    (state) => state.auth?.userDetail?.business_list?.[0]?.business_profile_id
  );
  const getEventList = useCallback(async () => {
    try {
      const { data } = await view(`fetch/event/list?my_events=true`);
      if (data.success) {
        console.log(data);
        setEventList(data.event_list);
      }
    } catch (error) {
      console.log(error);
    }
  }, [business_id]);
  useEffect(() => {
    if (business_id !== undefined) {
      getEventList();
    }
  }, [business_id, getEventList]);
  return (
    <>
      <Layout type="leftsidebar">
        <Container className="py-3">
          <div className="header-title marBot">
            <span>Find Events</span>
          </div>
          <Row>
            <Col md={10}>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ color: "#06C864" }}
                  />
                </InputGroup.Text>
                <Form.Control placeholder="Search Events" size="lg" />
              </InputGroup>
            </Col>
            <Col md={2}>
              <button
                onClick={() => navigate("/event/add")}
                className="create-btn-event text-white"
              >
                Create
              </button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="img-sect-event">
                <div className="main-div-img-sect-ev">
                  <div className="img-sect-txt margin">
                    <span style={{ marginRight: "10px" }}>
                      <FontAwesomeIcon
                        icon={faCalendarDays}
                        style={{ color: "#06c864" }}
                      />
                    </span>
                    <span className="txt-event">
                      Thursday, 13 January, 2022
                    </span>
                    <span style={{ marginRight: "10px", marginLeft: "10px" }}>
                      <FontAwesomeIcon
                        icon={faLocation}
                        style={{ color: "#06c864" }}
                      />
                    </span>
                    <span className="txt-event">
                      Amazon Fashion Studio-London
                    </span>
                  </div>
                  <div className="img-sect-txt2 margin">
                    <span style={{ marginRight: "10px" }}>
                      <FontAwesomeIcon
                        icon={faLocation}
                        style={{ color: "#06c864" }}
                      />
                    </span>
                    <span className="txt-event">
                      Amazon Fashion Studio-London
                    </span>
                  </div>
                  <div>
                    <h3
                      className="text-white margin"
                      style={{ fontFamily: "Circular Std", fontSize: "24px" }}
                    >
                      Ecommerce Growth meetup
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between para-btn">
                    <p
                      className="text-white margin"
                      style={{ fontSize: "14px" }}
                    >
                      Lorem ipsum dolor sit amet, consectetur adipisicing
                      adipisicing adipisicing{" "}
                      <span style={{ display: "block" }}>
                        {" "}
                        elit. Fuga culpa recusandae quae libero error ducimus?
                      </span>
                    </p>
                    <Button
                      onClick={() => navigate("/event/detail/:id")}
                      className="detail-btn-ev me-3"
                    >
                      View Details
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 mb-5">
            <Col md={12}>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-center align-items-center">
                  <h6 className="me-2">Filtered by:</h6>
                </div>
                <div style={{ width: "230px" }}>
                  <Select
                    classNamePrefix="react-select"
                    placeholder="Today"
                    name="category"
                  />
                </div>
                <div style={{ marginLeft: "5px", width: "230px" }}>
                  <Select
                    classNamePrefix="react-select"
                    placeholder="Category"
                    name="category"
                  />
                </div>
                <div style={{ marginLeft: "5px", width: "230px" }}>
                  <Select
                    classNamePrefix="react-select"
                    placeholder="Privacy"
                    name="category"
                  />
                </div>
                <div style={{ marginLeft: "3px", width: "230px" }}>
                  <Select
                    classNamePrefix="react-select"
                    placeholder="Location"
                    name="category"
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="trending-txt">
              <span>Checkout Trending Categories</span>
            </div>
            <EventTrendingCard listData={griddata} />
          </Row>
          <Row>
            <Col md={12}>
              <div className="trending-txt mb-4">
                <span>Join Upcoming Events</span>
              </div>
              <UpcomingEventCard
                getData={eventlist}
                gridClass="col-12 col-md-6"
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}

export default UpcomingEventPage;
