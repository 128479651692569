import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Button, Modal } from "react-bootstrap";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import { NavLink } from "react-router-dom";
import {
  faPlantWilt,
  faPaintRoller,
  faHome,
  faBroom,
  faUsers,
  faLocationDot,
  faUser,
  faCalendarDays,
  faDriversLicense,
  faCheck,
  faMagnifyingGlass,
  faEllipsisVertical,
  faStar as FillStar,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import EventsImg from "src/Assets/images/events.png";
import mainImg from "src/Assets/images/community/detail-main.png";
import subImg from "src/Assets/images/community/detail-sub.png";
import profileImg from "src/Assets/images/personal_profile/ProfileImage.jpg";
import "./community.css";
import Rating from "react-rating";
import styled from "styled-components";
import {
  faStar as EmptyStar,
  faHeart,
  faMessage,
} from "@fortawesome/free-regular-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import axios, { view, remove, add } from "src/Services/AxiosConfig";
import { ReactComponent as Logo } from "src/Assets/svg/logo.svg";
import { toast } from "react-toastify";

const CommunityDetail = () => {
  const navigate = useNavigate();
  const [invitedata, setInviteData] = useState();
  const { id } = useParams();
  const [communitydata, setCommunityData] = useState();
  const getCommunity = async () => {
    const { data } = await view(
      `https://api2.flushapp.app/api/v1/community/members/details/?community_id=${id}`
    );
    if (data.success) {
      console.log(data);
      setCommunityData(data.data);
      // console.log(communitydata[0].community_name);
    }
  };
  useEffect(() => {
    getCommunity();
  }, []);

  const invitation = async () => {
    try {
      const { data } = await add(`/community/generate/invitation/`, {
        community_id: id,
      });
      setInviteData(data.data);
      if (data.success) {
        handleShow();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const leaveCommunity = async () => {
    try {
      await axios.delete("/community/member/leave/", {
        data: { community: id },
      });
      if (data.success) {
        navigate("/community/list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FillStarContainer = styled.span`
    color: #febf00;
  `;
  const EmptyStarContainer = styled.span`
    color: gray;
  `;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {console.log(invitedata)}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Invitation Link for Joining the Community</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-center">
              <Logo />
            </div>
            <div>
              <h1 className="c2 primary-font oops my-2 text-center">
                Congratulations!!
              </h1>
            </div>
            <div>
              <p className="c3 secondary-font fs-24 mb-5 text-center">
                You have successfully generated the link for joining the
                community, your link is below
              </p>
            </div>
            <div
              onClick={() =>
                navigate(
                  `/community/invited/${invitedata.invite_link.slice(20, 55)}`
                )
              }
              className="text-center mt-0 mb-5"
            >
              <span className="c2 text-center">
                {invitedata && invitedata.invite_link}
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Layout type="plain">
        <Container>
          <Row className="pt-5 g-4">
            <Col xs="12" lg="9">
              <div className="com-top">
                <div className="header-title marBot t-space ps-0">
                  <span>Community Discussions</span>
                </div>
              </div>

              {/* Card  start*/}
              <div className="t-main">
                <div className="t-img">
                  <img src={mainImg} className="img-fluid" />
                </div>
                <div className="t-body pad">
                  <Row className="align-items-center g-3">
                    <Col xs="3" md="2">
                      <img src={subImg} className="img-fluid" />
                    </Col>
                    <Col xs="12" md="6">
                      <div className="t-title u-title pb-0">
                        <span>
                          {communitydata && communitydata[0]?.community_name}
                        </span>
                      </div>
                      <div className="secondary-font dst-c">
                        <ul className="d-flex flex-wrap ps-1 my-2">
                          <li className="mx-3">Painting</li>
                          <li className="mx-3">Renovation</li>
                          <li className="mx-3">Rebuild</li>
                        </ul>
                      </div>

                      <div className="d-flex align-items-center">
                        <div>
                          <span style={{ marginRight: "5px" }}>
                            <FontAwesomeIcon
                              icon={faLocationDot}
                              style={{ color: "#37d1aa" }}
                            />
                          </span>

                          <span> Syracuse 13202, NY</span>
                        </div>
                        <div className="ms-3">
                          <span style={{ marginRight: "5px" }}>
                            <FontAwesomeIcon
                              icon={faUsers}
                              style={{ color: "#37d1aa" }}
                            />
                          </span>

                          <span>
                            {" "}
                            {communitydata &&
                              communitydata[0]?.total_members}{" "}
                            Member
                          </span>
                        </div>
                      </div>

                      <div className="mt-2">
                        <FillStarContainer>
                          <FontAwesomeIcon icon={FillStar} size="sm" />
                          <FontAwesomeIcon icon={FillStar} size="sm" />
                          <FontAwesomeIcon icon={FillStar} size="sm" />
                          <FontAwesomeIcon icon={FillStar} size="sm" />
                          <FontAwesomeIcon icon={FillStar} size="sm" />
                        </FillStarContainer>
                        <span className="ms-2 text-small text-muted">5/5</span>
                      </div>
                    </Col>
                    <Col xs="12" md="4" className="align-self-end d-flex">
                      <button
                        onClick={() => leaveCommunity()}
                        className="custom-gradient-button-leave"
                      >
                        Leave
                      </button>
                      <button
                        onClick={invitation}
                        className="custom-gradient-button-invite"
                      >
                        <FontAwesomeIcon icon={faCheck} size={12} />
                        <span className="ms-2">Generate Invite</span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* Card  end*/}

              {/* Ask Question Code Start */}
              <div className="com-top pt-4">
                <div className="header-title marBot t-space ps-0">
                  <span>Ask Question</span>
                </div>
              </div>
              <div className="t-main p-3">
                <form>
                  <div className="d-flex align-items-center mb-5">
                    <img
                      src={profileImg}
                      className="img-fluid rounded-circle"
                      alt="profile-img"
                      width="60"
                    />
                    <div className="ps-2 h5 fw-bold mb-0">Jason Stewart</div>
                  </div>
                  <input
                    type="text"
                    className="form-control border-0 border-bottom"
                    placeholder="Ask a question..."
                  />
                  <div className="text-end mt-3">
                    <button className="custom-gradient-button" type="submit">
                      Post
                    </button>
                  </div>
                </form>
              </div>
              {/* Ask Question Code End */}
            </Col>
            <Col xs="12" lg="3">
              <div className="com-top">
                <div className="header-title marBot t-space ps-0">
                  <span>You may like to explore</span>
                </div>
              </div>

              {/* Community Categories Start */}
              <div className="db_color mt-0">
                <div className="text-uppercase fs-16 primary-font p-3 lsh-color">
                  Top categories
                </div>
                <div className="px-3 py-2">
                  <div className="d-flex justify-content-start align-items-center pb-3 pt-3">
                    <div className="iconContainer">
                      <FontAwesomeIcon
                        icon={faPlantWilt}
                        color="rgb(55, 209, 170)"
                      />
                    </div>
                    <div>
                      <div className="fs-16 secondary-font dt-c ps-3">
                        Plumbing
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <div className="iconContainer">
                      <FontAwesomeIcon
                        icon={faPaintRoller}
                        color="rgb(55, 209, 170)"
                      />
                    </div>
                    <div>
                      <div className="fs-16 secondary-font dt-c ps-3">
                        Painting
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <div className="iconContainer">
                      <FontAwesomeIcon
                        icon={faHome}
                        color="rgb(55, 209, 170)"
                      />
                    </div>
                    <div>
                      <div className="fs-16 secondary-font dt-c ps-3">
                        Roffing
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center pb-3">
                    <div className="iconContainer">
                      <FontAwesomeIcon
                        icon={faBroom}
                        color="rgb(55, 209, 170)"
                      />
                    </div>
                    <div>
                      <div className="fs-16 secondary-font dt-c ps-3">
                        Cleaning
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Community Categories End */}

              <div className="db_color mt-4">
                <div className="text-uppercase fs-16 primary-font p-3 lsh-color">
                  MOre Communities
                </div>
                <div className="px-3 pt-2">
                  <div className="d-flex justify-content-start align-items-center py-2">
                    <div className=" ">
                      <img
                        src={EventsImg}
                        // className="img-fluid"
                        width="70"
                        height="70"
                      />
                    </div>
                    <div className="px-2">
                      <div className="fs-14 secondary-font dst-c  px-2">
                        <FontAwesomeIcon
                          icon={faUsers}
                          color="rgb(55, 209, 170)"
                          size={12}
                        />
                        <span className="ms-1">26k Members</span>
                      </div>
                      <div className="fs-16 secondary-font dt-c px-2">
                        Fast Delivery Services
                      </div>
                      <div className="fs-14 secondary-font dst-c">
                        <ul className="d-flex flex-wrap ps-2">
                          <li className="mx-3">Painting</li>
                          <li className="mx-3">Renovation</li>
                          <li className="mx-3">Rebuild</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center py-2">
                    <div className=" ">
                      <img
                        src={EventsImg}
                        // className="img-fluid"
                        width="70"
                        height="70"
                      />
                    </div>
                    <div className="px-2">
                      <div className="fs-14 secondary-font dst-c  px-2">
                        <FontAwesomeIcon
                          icon={faUsers}
                          color="rgb(55, 209, 170)"
                          size={12}
                        />
                        <span className="ms-1">26k Members</span>
                      </div>
                      <div className="fs-16 secondary-font dt-c px-2">
                        Fast Delivery Services
                      </div>
                      <div className="fs-14 secondary-font dst-c">
                        <ul className="d-flex flex-wrap ps-2">
                          <li className="mx-3">Painting</li>
                          <li className="mx-3">Renovation</li>
                          <li className="mx-3">Rebuild</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center py-2">
                    <div className=" ">
                      <img
                        src={EventsImg}
                        // className="img-fluid"
                        width="70"
                        height="70"
                      />
                    </div>
                    <div className="px-2">
                      <div className="fs-14 secondary-font dst-c  px-2">
                        <FontAwesomeIcon
                          icon={faUsers}
                          color="rgb(55, 209, 170)"
                          size={12}
                        />
                        <span className="ms-1">26k Members</span>
                      </div>
                      <div className="fs-16 secondary-font dt-c px-2">
                        Fast Delivery Services
                      </div>
                      <div className="fs-14 secondary-font dst-c">
                        <ul className="d-flex flex-wrap ps-2">
                          <li className="mx-3">Painting</li>
                          <li className="mx-3">Renovation</li>
                          <li className="mx-3">Rebuild</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center py-2">
                    <div className=" ">
                      <img
                        src={EventsImg}
                        // className="img-fluid"
                        width="70"
                        height="70"
                      />
                    </div>
                    <div className="px-2">
                      <div className="fs-14 secondary-font dst-c  px-2">
                        <FontAwesomeIcon
                          icon={faUsers}
                          color="rgb(55, 209, 170)"
                          size={12}
                        />
                        <span className="ms-1">26k Members</span>
                      </div>
                      <div className="fs-16 secondary-font dt-c px-2">
                        Fast Delivery Services
                      </div>
                      <div className="fs-14 secondary-font dst-c">
                        <ul className="d-flex flex-wrap ps-2">
                          <li className="mx-3">Painting</li>
                          <li className="mx-3">Renovation</li>
                          <li className="mx-3">Rebuild</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-3 more_community_card_footer text-center">
                  <NavLink to="/community" className=" custom-light-green">
                    See More Communities
                  </NavLink>
                </div>
              </div>
            </Col>
          </Row>

          <div>
            <div className="com-top pt-4">
              <div className="header-title marBot t-space ps-0">
                <span>Search Questions & Answers</span>
              </div>
            </div>
            <div className="bg-light px-2 py-1 rounded-4">
              <Form className="d-flex justify-content-between">
                <div className="flex-grow-1">
                  <input
                    size="lg"
                    type="text"
                    className="fs-16 form-control h-100 bg-transparent custom-active-input"
                    style={{ border: "none" }}
                    placeholder="Type Questions or answers"
                  />
                </div>
                <div>
                  <Button
                    className="btn bg-transparent border-0 btn-lg custom-light-green"
                    type="submit"
                  >
                    <FontAwesomeIcon icon={faMagnifyingGlass} size={12} />
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          {/* List Start */}
          <div className="py-5">
            <div
              onClick={() => navigate("/community/answer")}
              className="t-main p-3 h-auto"
            >
              <Row className="g-3 align-items-center">
                <Col md="1" lg="1">
                  <img
                    src={profileImg}
                    className="img-fluid rounded-circle"
                    alt="profile-img"
                  />
                </Col>
                <Col md="6" lg="6">
                  <div className="pt-4">
                    <div className="h5 fw-bolder">
                      <span className="custom-text-dots">
                        How to find jobs on this portal after creating the new
                        user profile
                      </span>
                    </div>
                    <div className="fw-light">How to sell your services</div>
                    <div className="t-subTitle u-subTitle mt-2">
                      <div className="d-flex align-items-center">
                        <div
                          className="border-end"
                          style={{
                            marginRight: "15px",
                            paddingRight: "15px",
                          }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            <FontAwesomeIcon
                              icon={faUser}
                              style={{ color: "#37d1aa" }}
                            />
                          </span>
                          <span>Zahir Mays</span>
                        </div>
                        <span style={{ marginRight: "10px" }}>
                          <FontAwesomeIcon
                            icon={faCalendarDays}
                            style={{ color: "#37d1aa" }}
                          />
                        </span>
                        <span>13-01-2022</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="5" lg="5">
                  <div className="d-flex justify-content-end align-items-center h-100">
                    <div className="iconContainer custom-bg-light w-auto h-auto">
                      <FontAwesomeIcon
                        icon={faHeart}
                        size="xl"
                        className="p-2"
                      />
                    </div>
                    <div className="iconContainer custom-bg-light w-auto h-auto mx-2">
                      <FontAwesomeIcon
                        icon={faMessage}
                        size="xl"
                        className="p-2"
                      />
                    </div>
                    <div className="iconContainer custom-bg-light w-auto h-auto">
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        size="xl"
                        className="p-2 px-3"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="t-main p-3 h-auto">
              <Row className="g-3 align-items-center">
                <Col md="1" lg="1">
                  <img
                    src={profileImg}
                    className="img-fluid rounded-circle"
                    alt="profile-img"
                  />
                </Col>
                <Col md="6" lg="6">
                  <div className="pt-4">
                    <div className="h5 fw-bolder">
                      <span className="custom-text-dots">
                        How to find jobs on this portal after creating the new
                        user profile
                      </span>
                    </div>
                    <div className="fw-light">How to sell your services</div>
                    <div className="t-subTitle u-subTitle mt-2">
                      <div className="d-flex align-items-center">
                        <div
                          className="border-end"
                          style={{
                            marginRight: "15px",
                            paddingRight: "15px",
                          }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            <FontAwesomeIcon
                              icon={faUser}
                              style={{ color: "#37d1aa" }}
                            />
                          </span>
                          <span>Zahir Mays</span>
                        </div>
                        <span style={{ marginRight: "10px" }}>
                          <FontAwesomeIcon
                            icon={faCalendarDays}
                            style={{ color: "#37d1aa" }}
                          />
                        </span>
                        <span>13-01-2022</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="5" lg="5">
                  <div className="d-flex justify-content-end align-items-center h-100">
                    <div className="iconContainer custom-bg-light w-auto h-auto">
                      <FontAwesomeIcon
                        icon={faHeart}
                        size="xl"
                        className="p-2"
                      />
                    </div>
                    <div className="iconContainer custom-bg-light w-auto h-auto mx-2">
                      <FontAwesomeIcon
                        icon={faMessage}
                        size="xl"
                        className="p-2"
                      />
                    </div>
                    <div className="iconContainer custom-bg-light w-auto h-auto">
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        size="xl"
                        className="p-2 px-3"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* List End */}

          {/* Rating code start */}
          <div className="text-center mb-5">
            <div className="header-title marBot t-space ps-0 w-100 mb-0 ">
              <span>Rate This Community</span>
            </div>
            <div className="text-muted my-3">
              Please rate us, to make our community bettter
            </div>
            <Rating
              fullSymbol={
                <FillStarContainer>
                  <FontAwesomeIcon icon={FillStar} size="xl" />
                </FillStarContainer>
              }
              emptySymbol={
                <EmptyStarContainer>
                  <FontAwesomeIcon icon={EmptyStar} size="xl" />
                </EmptyStarContainer>
              }
              initialValue={0}
              // onChange={(value) => console.log(value)}
            />
          </div>

          {/* Rating code end */}
        </Container>
      </Layout>
    </>
  );
};

export default CommunityDetail;
