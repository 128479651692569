import { useState } from "react";
import Layout from "src/Components/global/Dashboard/Layouts/Layout";
import { Container, Row, Col } from "react-bootstrap";
import closeup from "src/Assets/images/close-up-worker.png";
import Form from "react-bootstrap/Form";
import "./RegisterEventPaid.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import paypal from "src/Assets/images/icons/paypal.png";
import RegisterEventPaidFour from "./RegisterEventPaidFour";

const RegisterEventPaidTwo = ({
  handleSubmit,
  handleChange,
  values,
  touched,
  errors,
  setPaidstep,
}) => {
  const [step, setStep] = useState("");
  return (
    <>
      {console.log(step)}
      <Layout>
        <div className="p-5">
          <Container className="main-card-reg shadow">
            <Row>
              <Col lg={9} md={9}>
                <div className="mt-3">
                  <span
                    style={{
                      fontSize: "26px",
                      lineHeight: "40px",
                      fontFamily: "Circular Std",
                      padding: "15px",
                    }}
                  >
                    Register Summary
                  </span>
                  <hr />
                </div>
                <div className="d-flex justify-content-between">
                  <span
                    style={{
                      fontSize: "22px",
                      lineHeight: "40px",
                      fontFamily: "Circular Std",
                      padding: "15px",
                    }}
                  >
                    Contact Information
                  </span>
                </div>
                <div>
                  <span style={{ color: "#06c864", padding: "15px" }}>
                    Login{" "}
                    <span style={{ color: "#666666" }}>
                      a for fast experience
                    </span>
                  </span>
                </div>
                <div className="mt-5" style={{ padding: "15px" }}>
                  <Row>
                    <Col md={6}>
                      <label className="label">First Name</label>

                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Ecommerce Conference"
                        onChange={handleChange}
                        value={values.title}
                        size="lg"
                      />
                    </Col>
                    <Col md={6}>
                      <label className="label">Last Name</label>

                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Ecommerce Conference"
                        onChange={handleChange}
                        value={values.title}
                        size="lg"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label className="label">Email</label>
                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Email"
                        onChange={handleChange}
                        value={values.title}
                        size="lg"
                      />
                    </Col>
                    <Col md={6}>
                      <label className="label">Confirm Email</label>

                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Ecommerce Conference"
                        onChange={handleChange}
                        value={values.title}
                        size="lg"
                      />
                    </Col>
                  </Row>
                </div>
                <hr />
                <div>
                  <div
                    className="d-flex flex-column"
                    style={{ padding: "15px" }}
                  >
                    <div className="d-flex">
                      <Form.Check type="checkbox" />
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#3F464E",
                          fontFamily: "Roboto",
                          margin: "4px",
                        }}
                      >
                        Keep me updated on more events and news from this event
                        organizer.
                      </span>
                    </div>
                    <div className="d-flex mt-1">
                      <Form.Check type="checkbox" />
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#3F464E",
                          fontFamily: "Roboto",
                          margin: "4px",
                        }}
                      >
                        I <span style={{ color: "#06c864" }}>agree</span> to the
                        terms & conditions
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <span
                    style={{
                      fontSize: "22px",
                      lineHeight: "40px",
                      fontFamily: "Circular Std",
                      padding: "15px",
                    }}
                  >
                    Payment Method
                  </span>
                </div>
                <div>
                  <Row>
                    <Col md={12}>
                      <Container>
                        <div className="pay-card mt-5">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex" style={{ padding: "15px" }}>
                              <span>
                                <Form.Check
                                  type="radio"
                                  onChange={() => setStep("thirdpaidstep")}
                                />
                              </span>
                              <span className="ms-4" style={{ margin: "3px" }}>
                                Credit or debit card
                              </span>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                style={{
                                  padding: "15px",
                                  width: "60px",
                                  height: "40px",
                                }}
                                icon={faCreditCard}
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex justify-content-between">
                            <div className="d-flex" style={{ padding: "15px" }}>
                              <span>
                                <Form.Check
                                  type="radio"
                                  onChange={() => setStep("fourthpaidstep")}
                                />
                              </span>
                              <span className="ms-4" style={{ margin: "3px" }}>
                                Paypal
                              </span>
                            </div>
                            <div className="paypal-icon">
                              <img
                                style={{ position: "relative", bottom: "130%" }}
                                src={paypal}
                                alt="paypal"
                              />
                            </div>
                          </div>
                          <div className="ms-5 mb-4">
                            {step === "fourthpaidstep" && (
                              <RegisterEventPaidFour />
                            )}
                          </div>
                        </div>
                        <div className="d-flex mt-3">
                          <Form.Check type="checkbox" />
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#3F464E",
                              fontFamily: "Roboto",
                              margin: "4px",
                            }}
                          >
                            I <span style={{ color: "#06c864" }}>accept</span>{" "}
                            the Terms of Service, Community Guidelines, and
                            Privacy Policy.{" "}
                            <span style={{ color: "#06c864" }}>(Required)</span>
                          </span>
                        </div>
                      </Container>
                    </Col>
                  </Row>
                </div>
                <div>
                  <button
                    onClick={() => setPaidstep(step)}
                    className="reg-free-btnn"
                  >
                    Register
                  </button>
                </div>
              </Col>
              <Col lg={3} md={3} className="reg-ev-col">
                <div className="img-sec-reg">
                  <img
                    src={closeup}
                    alt="dd"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
                <div>
                  <div>
                    <span
                      style={{
                        fontSize: "22px",
                        lineHeight: "40px",
                        fontFamily: "Circular Std",
                        padding: "15px",
                      }}
                      className="mt-4"
                    >
                      Order Summary
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-1">
                    <span style={{ fontSize: "12px", padding: "15px" }}>
                      1x Annual Business Conference
                    </span>
                    <span style={{ fontSize: "12px", padding: "15px" }}>
                      $10
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-4">
                    <span
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        padding: "15px",
                      }}
                    >
                      Total:
                    </span>
                    <span
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                        padding: "15px",
                      }}
                    >
                      $10
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default RegisterEventPaidTwo;
